import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  useDisclosure,
} from '@chakra-ui/react'
import { PlayIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  openAddTagsModal,
  closeAddTagsModal,
} from '../redux/AddTagsModal.js'
import { updateCurrentOrganization } from '../redux/OrganizationSlice.js'

function AddTags() {
  const dispatch = useDispatch()
  const isModalOpen = useSelector((state) => state.addTagsModal.isModalOpen)
  const organization = useSelector((state) => state.organization.data)

  const [newTag, setNewTag] = useState('')

  const handleCloseModal = () => {
    dispatch(closeAddTagsModal())
    setNewTag('') // Reset the input field
  }

  const handleAddTag = () => {
    if (newTag.trim()) {
      // Add the new tag to the organization's tags array
      const updatedTags = [...organization.tags, newTag.trim()]

      // Dispatch an action to update the organization's tags
      dispatch(updateCurrentOrganization({ tags: updatedTags }))

      handleCloseModal() // Close the modal after adding the tag
    }
  }

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Tag</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
            <Input
              type="text"
              placeholder="Tag name"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              required
            />
          </ModalBody>

          <ModalFooter className="mt-8">
            <Button
              rightIcon={<PlayIcon className="w-4 h-4 ml-2" />}
              colorScheme="gray"
              mr={3}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              rightIcon={<UserGroupIcon className="w-4 h-4 ml-2" />}
              colorScheme="blue"
              onClick={handleAddTag}
            >
              Add Tag
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddTags
