import React, { useRef, useEffect, useState } from 'react'
import $ from 'jquery'

require('formBuilder/dist/form-render.min.js')

const FormRender = ({ formData, formResponses, setFormResponses }) => {
  const formContainerRef = useRef(null)

  useEffect(() => {
    // Ensure formRender plugin is loaded
    if ($.fn.formRender) {
      $(formContainerRef.current).formRender({ formData })

      // Initialize state with default values and required fields
      const formInputs = formContainerRef.current.querySelectorAll(
        'input, select, textarea',
      )
      formInputs.forEach((input) => {
        const name = input.name || input.id.split('-input')[0] // Fallback to id if name is not present
        setFormResponses((prevResponses) => ({
          ...prevResponses,
          forms: {
            ...prevResponses.forms,
            [name]: {
              value: input.type === 'checkbox' ? input.checked : input.value,
              required: input.required,
            },
          },
        }))

        // Add change event listener
        input.addEventListener('change', handleChange)
      })

      return () => {
        // Clean up event listeners when component unmounts
        formInputs.forEach((input) => {
          input.removeEventListener('change', handleChange)
        })
      }
    } else {
      console.error('formRender plugin not loaded properly')
    }
  }, [formData])

  const handleChange = (event) => {
    const { name, id, value, type, checked, required } = event.target
    const fieldName = name || id.split('-input')[0] // Fallback to id if name is not present
    console.log('Field changed:', fieldName, event.target) // Debugging log
    setFormResponses((prevResponses) => ({
      ...prevResponses,
      forms: {
        ...prevResponses.forms,
        [fieldName]: { value: type === 'checkbox' ? checked : value, required },
      },
    }))
  }

  return (
    <div className="form-wrapper">
      <div
        id="form-container"
        className="bg-gray-50 rounded-lg p-4 w-full"
        ref={formContainerRef}
      ></div>
    </div>
  )
}

export default FormRender
