import Cookies from 'js-cookie'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const launchTemplate = createAsyncThunk(
  'tracker/launchTemplate',
  async (templateData) => {
    console.log(
      { ...templateData, templateId: templateData._id, _id: null },
      'bhai ye data jara h check kr',
    )
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/launch`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
        body: JSON.stringify({
          ...templateData,
          templateId: templateData._id,
          _id: null,
        }),
      },
    )

    if (!res.ok) {
      const error = await res.json()
      throw new Error(error.message || 'Failed to launch procedure')
    }

    return res.json()
  },
)

export const getLaunchedTemplate = createAsyncThunk(
  'tracker/getLaunchedTemplate',
  async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/launch`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      },
    )

    if (!res.ok) {
      const error = await res.json()
      throw new Error(error.message || 'Failed to fetch templates')
    }

    return res.json()
  },
)

export const updateLaunchTemplate = createAsyncThunk(
  'tracker/updateLaunchTemplate',
  async (templateData) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/launch/${templateData._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
        body: JSON.stringify(templateData),
      },
    )

    if (!res.ok) {
      const error = await res.json()
      throw new Error(error.message || 'Failed to update template')
    }

    return res.json()
  },
)

export const updateLaunchTemplateWithoutPull = createAsyncThunk(
  'tracker/updateLaunchTemplateWithoutPull',
  async (templateData) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/launch/${templateData._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
        body: JSON.stringify(templateData),
      },
    )

    if (!res.ok) {
      const error = await res.json()
      throw new Error(error.message || 'Failed to update template')
    }

    return res.json()
  },
)

export const deleteLaunchTemplate = createAsyncThunk(
  'templates/deleteLaunchTemplate',
  async (templateData) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/launch/${templateData._id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      },
    )

    if (!res.ok) {
      const error = await res.json()
      throw new Error(error.message || 'Failed to fetch launched templates')
    }

    return res.json()
  },
)

export const addStep = createAsyncThunk(
  'tracker/addStep',
  async (stepData, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/launch/step`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
          body: JSON.stringify(stepData),
        },
      )

      if (!res.ok) {
        const error = await res.json()
        return rejectWithValue(error.message || 'Failed to add step')
      }

      const data = await res.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to add step')
    }
  },
)

export const updateSteps = createAsyncThunk(
  'tracker/updateSteps',
  async (steps, { rejectWithValue }) => {
    try {
      const updatedSteps = []
      console.log('Steps' + steps)
      // Iterate over each step and send a PUT request
      for (const step of steps) {
        console.log('Step ' + step)

        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/steps/${step._id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Cookies.get('token')}`,
            },
            body: JSON.stringify(step),
          },
        )

        if (!res.ok) {
          const error = await res.json()
          return rejectWithValue(error.message || 'Failed to update step')
        }

        const updatedStep = await res.json()
        updatedSteps.push(updatedStep)
      }

      return { steps: updatedSteps }
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update steps')
    }
  },
)

export const addComment = createAsyncThunk(
  'tracker/addComment',
  async (data) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/steps/${data.stepId}/comments`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
        body: JSON.stringify({ text: data.text }),
      },
    )
    return res.json()
  },
)

const launchedTemplateSlice = createSlice({
  name: 'launchedTemplates',
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    errorMessage: '',
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLaunchedTemplate.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(getLaunchedTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = action.payload
      })
      .addCase(getLaunchedTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(launchTemplate.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(launchTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.data.push(action.payload.procedure) // Add the new task to the state
      })
      .addCase(launchTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(updateLaunchTemplate.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(updateLaunchTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        const updatedLaunchTemplateIndex = state.data.findIndex(
          (template) => template._id === action.payload._id,
        )
        if (updatedLaunchTemplateIndex != -1) {
          state.data[updatedLaunchTemplateIndex] = action.payload // Update the task in the state
        }
      })
      .addCase(updateLaunchTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(updateLaunchTemplateWithoutPull.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(updateLaunchTemplateWithoutPull.fulfilled, (state, action) => {
        state.isLoading = false
        const updatedTemplateIndex = state.data.findIndex(
          (template) => template._id === action.payload._id,
        )
        console.log(
          'updatedTemplateIndex',
          updatedTemplateIndex,
          action.payload,
        )
      })
      .addCase(updateLaunchTemplateWithoutPull.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(deleteLaunchTemplate.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(deleteLaunchTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        console.log(action.payload, 'action.payload deletTemplate')
        const deleteLaunchTemplateIndex = state.data.findIndex(
          (template) => template._id === action.payload._id,
        )
        console.log(deleteLaunchTemplateIndex, action.payload._id)

        if (deleteLaunchTemplateIndex != -1) {
          state.data.splice(deleteLaunchTemplateIndex, 1)
        }
      })
      .addCase(deleteLaunchTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(addStep.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(addStep.fulfilled, (state, action) => {
        state.isLoading = false
        console.log(action.payload, 'action.payload')
        const updatedStepIndex = state.data.findIndex(
          (template) => template._id === action.payload.templateId,
        )
        if (updatedStepIndex !== -1) {
          console.log(action.payload.step)
          state.data[updatedStepIndex].steps.push(action.payload.step) // Insert comment at the beginning of the array
        }
      })
      .addCase(addStep.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
    builder.addCase(addComment.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(addComment.fulfilled, (state, action) => {
      state.isLoading = false
      const templateIndex = state.data.findIndex(
        (template) => template._id === action.payload.step.templateId,
      )
      if (templateIndex !== -1) {
        const stepIndex = state.data[templateIndex].steps.findIndex(
          (step) => step._id === action.payload.step._id,
        )
        if (stepIndex !== -1) {
          state.data[templateIndex].steps[stepIndex] = action.payload.step
        }
      }
    })
    builder.addCase(addComment.rejected, (state) => {
      state.isError = true
    })
    builder.addCase(updateSteps.pending, (state) => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = ''
    })
    builder.addCase(updateSteps.fulfilled, (state, action) => {
      state.isLoading = false
      const { steps } = action.payload
      const templateIndex = state.data.findIndex(
        (template) => template._id === steps.templateId,
      )
      if (templateIndex !== -1) {
        state.data[templateIndex].steps = steps // Update the steps in the state
      }
    })
    builder.addCase(updateSteps.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
  },
})

export default launchedTemplateSlice.reducer
