// modalSlice.js
import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'groupsModal',
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    openCreateGroupModal: (state) => {
      state.isModalOpen = true
    },
    closeCreateGroupModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const {
  openCreateGroupModal,
  closeCreateGroupModal,
} = modalSlice.actions
export default modalSlice.reducer
