import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, Avatar, Button, Input, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import Header from '../../../components/Header';
import SettingsSidebar from '../../../components/SettingsSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, updateCurrentUser } from '../../../redux/UserSlice';

function Profile() {
  const dispatch = useDispatch();
  const toast = useToast();
  const [currentUser, setCurrentUser] = useState({});
  const [avatarFile, setAvatarFile] = useState(null);
  const { isLoading, data, isError, errorMessage, successMessage } = useSelector((state) => state.currentUser);

  useEffect(() => {
    if (data.length === 0) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, data.length]);

  useEffect(() => {
    if (data.length > 0) {
      setCurrentUser(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  
    if (successMessage) {
      toast({
        title: "Success",
        description: successMessage,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      dispatch({ type: 'currentUser/clearSuccessMessage' });
    }
  }, [isError, errorMessage, successMessage, toast, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setAvatarFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser.fName || !currentUser.lName) {
      toast({
        title: "Validation Error",
        description: "First name and last name are required.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('fName', currentUser.fName);
    formData.append('lName', currentUser.lName);
    if(currentUser.currentPassword && currentUser.newPassword && currentUser.currentPassword != currentUser.newPassword && currentUser.newPassword.length>5){
      formData.append('currentPassword', currentUser.currentPassword);
      formData.append('newPassword', currentUser.newPassword);
    }

    
    if (avatarFile) {
      formData.append('avatar', avatarFile);
    }
    
    try {
      dispatch(updateCurrentUser(formData)); // Update user in Redux
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while updating the profile.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="overflow-hidden max-h-screen">
      <Header />
      <Flex ml={[2, 2, 36]} my={[8, 4, 2]} mr={2}>
        <SettingsSidebar />
        <Box w="full" h="90vh" overflowY="scroll" mx="auto" pb={20}>
          <Box bg="white" rounded="xl" shadow="md" p={[4, 7]}>
            <Box mb={8}>
              <Heading as="h2" size="lg" color="gray.800">
                Profile
              </Heading>
              <Text fontSize="sm" color="gray.600">
                Manage your name, password, and account settings.
              </Text>
            </Box>
            <form onSubmit={handleSubmit}>
              <Flex direction="column" gap={[2, 6]}>
                <Flex alignItems="center" gap={5}>
                  <Flex gap={2} flex="1">
                    <Avatar size="xl" src={avatarFile ? URL.createObjectURL(avatarFile) : currentUser.avatar} alt="Profile Picture" />
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="gray"
                      border="1px solid"
                      borderColor="gray.200"
                      bg="white"
                      className="my-auto ml-2"
                      _hover={{ bg: 'gray.50' }}
                      onClick={() => document.getElementById('fileInput').click()}
                    >
                      Upload photo
                    </Button>
                    <Input 
                      id="fileInput"
                      type="file" 
                      accept="image/*" 
                      onChange={handleFileChange} 
                      display="none" 
                    />
                  </Flex>
                </Flex>

                <Flex direction="column">
                  <FormControl>
                    <FormLabel htmlFor="af-account-full-name" fontSize="sm" color="gray.800" mt={2.5}>
                      Full name
                    </FormLabel>
                    <Flex gap={2}>
                      <Input 
                        id="af-account-full-name" 
                        name="fName"
                        placeholder="Maria" 
                        size="sm" 
                        focusBorderColor="blue.500" 
                        variant="outline" 
                        value={currentUser?.fName || ''}
                        onChange={handleInputChange}
                      />
                      <Input 
                        name="lName"
                        placeholder="Boone" 
                        size="sm" 
                        focusBorderColor="blue.500" 
                        variant="outline" 
                        value={currentUser?.lName || ''}
                        onChange={handleInputChange}
                      />
                    </Flex>
                  </FormControl>
                </Flex>

                <Flex direction="column">
                  <FormControl>
                    <FormLabel htmlFor="af-account-email" fontSize="sm" color="gray.800" mt={2.5}>
                      Email
                    </FormLabel>
                    <Input 
                      id="af-account-email" 
                      type="email" 
                      placeholder="10489" 
                      size="sm" 
                      focusBorderColor="blue.500" 
                      variant="outline" 
                      isDisabled 
                      value={currentUser?.email || ''}
                    />
                  </FormControl>
                </Flex>

                <Flex direction="column">
                  <FormControl>
                    <FormLabel htmlFor="af-account-password" fontSize="sm" color="gray.800" mt={2.5}>
                      Password
                    </FormLabel>
                    <Flex direction="column" gap={2}>
                      <Input 
                        id="af-account-password" 
                        name="currentPassword"
                        type="password" 
                        placeholder="Enter current password" 
                        size="sm" 
                        focusBorderColor="blue.500" 
                        variant="outline" 
                        onChange={handleInputChange}
                      />
                      <Input 
                        name="newPassword"
                        type="password" 
                        placeholder="Enter new password (Min. 6 Char)" 
                        size="sm" 
                        focusBorderColor="blue.500" 
                        variant="outline" 
                        onChange={handleInputChange}
                      />
                    </Flex>
                  </FormControl>
                </Flex>

              </Flex>
              <Flex mt={5} justifyContent="flex-end" gap={2}>
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="gray"
                  border="1px solid"
                  borderColor="gray.200"
                  bg="white"
                  _hover={{ bg: 'gray.50' }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  colorScheme="blue"
                  type="submit"
                  isLoading={isLoading}
                >
                  Save changes
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default Profile;
