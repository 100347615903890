import Cookies from 'js-cookie'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchMembers = createAsyncThunk('fetchMembers', async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/getMembers`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
  })
  return res?.json()
})

const membersSlice = createSlice({
  name: 'members',
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMembers.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchMembers.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchMembers.rejected, (state, action) => {
      state.isError = true
    })
  },
})

export default membersSlice.reducer
