import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Heading,
  Text,
  Button,
  Avatar,
  Modal,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import {
  CheckCircleIcon,
  PlusCircleIcon,
  RectangleStackIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/solid'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Progress } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../../redux/InviteMemberModal'
import {
  openCreateTaskModal,
  closeCreateTaskModal,
} from '../../redux/CreateTaskModal'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Box,
} from '@chakra-ui/react'
import {
  openCreateTemplateModal,
  closeCreateTemplateModal,
} from '../../redux/CreateTemplateModal'
import CreateTemplate from '../../components/CreateTemplate'
import { useNavigate } from 'react-router-dom'
import { fetchTasks } from '../../redux/TaskSlice'
import Cookies from 'js-cookie'
import moment from 'moment-timezone';
import { format } from 'date-fns';
import { fetchTemplates } from '../../redux/TemplateSlice'
import { getLaunchedTemplate } from '../../redux/LaunchedTemplateSlice'

function getFormattedDate (utcDate) {
  if(!utcDate) return 
  const date = moment.tz(utcDate, Cookies.get('timeZone'));
  return format(new Date(date.format()), Cookies.get('dateFormat'));
};

function Home2() {
  const dispatch = useDispatch()
  const router = useNavigate()

  const [isLoading, setIsLoading] = useState(true)

  const state = useSelector((state) => state)

  useEffect(() => {
    dispatch(fetchTasks())
    dispatch(fetchTemplates())
    dispatch(getLaunchedTemplate())

    setIsLoading(false)

    console.log('state', state)
  }, [dispatch])

  let allActivities = []
  useEffect(() => {
    if (
      state &&
      state.tasks &&
      state.tasks.data &&
      state.tasks.data.length > 0
    ) {
      state?.tasks?.data?.forEach((task) => {
        task?.activity?.forEach((activity) => {
          allActivities.push({ ...activity, taskName: task.name })
        })
      })
    }
    // Sort activities based on createdAt
    allActivities.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

    console.log(allActivities)
  }, [state?.tasks?.data])

  // Function to group activities by date
  function groupActivitiesByDate(activities) {
    const groupedActivities = activities.reduce((groups, activity) => {
      const date = new Date(activity?.createdAt?.split('T')).toLocaleDateString(
        'en-US',
      )
      if (!groups[date]) {
        groups[date] = { date, activities: [] }
      }
      groups[date].activities.push(activity)
      return groups
    }, {})
    return Object.values(groupedActivities)
  }

  const renderText = (text, data) => {
    // Regular expression to find double curly braces
    const regex = /{{\s*(\w+)\s*}}/g

    // Replace placeholders with actual data values
    const result = text?.replace(regex, (_, key) => {
      // Access the nested 'value' field dynamically
      return data[key]?.value || key
    })

    return result
  }

  return (
    <div className="overflow-x-hidden min-h-screen">
      <Modal isCentered isOpen={isLoading}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
          className="items-center flex justify-center"
        >
          <Spinner size="xl" />
        </ModalOverlay>
      </Modal>{' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 space-y-4 py-10">
        <div>
          <div className="p-4 rounded-lg bg-gray-100 border flex flex-col lg:flex-row space-y-4">
            <div className=" flex flex-row items-center space-x-4 flex-grow flex-1 hidden lg:flex">
              <div>
                <Avatar size="lg" />
              </div>
              <div className="flex flex-col">
                {' '}
                <h1 className="font-bold text-xl">
                  {Cookies.get('fName') + ' ' + Cookies.get('lName')}
                </h1>
              </div>
            </div>

            <div className="gap-x-2 flex flex-col gap-y-2 lg:flex-row">
              <div className="flex flex-row bg-gray-200 border rounded-lg p-2">
                <div className="">
                  <h1 className="font-bold text-2xl">
                    {state?.members?.data?.members?.length || 0}
                  </h1>
                  <p>Members</p>
                </div>
                <div className="flex items-center">
                  {/* <Button
                    variant={'ghost'}
                    leftIcon={<PlusCircleIcon className="w-4 h-4" />}
                    className="flex items-center"
                    onClick={() => dispatch(openInviteMemberModal())}
                  >
                    Invite
                  </Button> */}
                </div>
              </div>
              <div className="flex flex-row bg-gray-200 border rounded-lg p-2">
                <div className="">
                  <h1 className="font-bold text-2xl">
                    {state?.tasks?.data?.length || 0}
                  </h1>
                  <p>Tasks</p>
                </div>
              </div>
              <div className="flex flex-row bg-gray-200 border rounded-lg p-2">
                <div className="">
                  <h1 className="font-bold text-2xl">0</h1>
                  <p>Unread Notifications</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="p-4">
            <div className=" bg-black text-white rounded-t-md p-4">
              <Heading size="md" className="flex">
                {' '}
                <div className="flex flex-grow flex-1">
                  <CheckCircleIcon className="w-6 h-6 text-blue-500 mr-2" />{' '}
                  Your tasks
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    dispatch(openCreateTaskModal())
                  }}
                  className="cursor-pointer flex text-sm"
                >
                  <PlusCircleIcon className="w-6 h-6 text-blue-500 mr-2" />{' '}
                  Create
                </div>
              </Heading>
            </div>
            <div className="bg-gray-100 ">
              <Tabs>
                <TabList className="flex">
                  <Tab className="w-full">Your Tasks</Tab>
                  {/* <Tab className="w-full">Activity</Tab> */}
                </TabList>

                <TabPanels className="h-[25rem] overflow-y-scroll [&::-webkit-scrollbar]:hidden">
                  <TabPanel>
                    <div className="flex flex-col space-y-4">
                      {state?.tasks?.data?.map((el, i) => (
                        <div
                          key={i}
                          onClick={() => router('/dashboard/tasks')}
                          className="border p-4 rounded-md bg-white cursor-pointer"
                        >
                          <h1 className="font-bold">
                            {renderText(
                              el.name,
                              el?.templateId?.kickOffForm?.forms,
                            )}
                          </h1>
                          <p>{getFormattedDate(el?.deadline?.date)}</p>
                        </div>
                      ))}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <>
                      {/* Timeline */}
                      <div>
                        {/* Group activities by date */}
                        {groupActivitiesByDate(allActivities).map(
                          (group, index) => (
                            <div key={index}>
                              {/* Heading */}
                              <div className="ps-2 my-2 first:mt-0">
                                <h3 className="text-xs font-medium uppercase text-gray-500">
                                  {/* Format group date */}
                                  {new Date(group.date).toLocaleDateString(
                                    'en-US',
                                    {
                                      weekday: 'long',
                                      year: 'numeric',
                                      month: 'long',
                                      day: 'numeric',
                                    },
                                  )}
                                </h3>
                              </div>
                              {/* End Heading */}
                              {/* Render activities for the current date */}
                              {group.activities.map((activity, index) => (
                                <div key={index} className="flex gap-x-3">
                                  {/* Icon */}
                                  <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                                    <div className="relative z-10 size-7 flex justify-center items-center">
                                      <div className="size-2 rounded-full bg-gray-400" />
                                    </div>
                                  </div>
                                  {/* End Icon */}
                                  {/* Right Content */}
                                  <div className="grow pt-0.5 pb-8">
                                    <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                                      {/* Render activity message */}
                                      {activity.message}
                                    </h3>
                                    {/* Render additional details */}
                                    {activity.linkedTask && (
                                      <p className="mt-1 text-sm text-gray-600">
                                        Linked Task: {activity.taskName}
                                      </p>
                                    )}
                                    {/* Render user */}
                                    <button
                                      type="button"
                                      className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                                    >
                                      <Avatar size="xs" alt="User Avatar" />
                                      {activity.createdBy}{' '}
                                      {/* Assuming name is present in createdBy object */}
                                    </button>
                                  </div>
                                  {/* End Right Content */}
                                </div>
                              ))}
                            </div>
                          ),
                        )}
                      </div>
                      {/* End Timeline */}
                    </>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </div>
          <div className="p-4">
            <div className=" bg-black text-white rounded-t-md p-4">
              <Heading size="md" className="flex">
                {' '}
                <div className="flex flex-grow flex-1">
                  <Square3Stack3DIcon className="w-6 h-6 text-blue-500 mr-2" />{' '}
                  Your Processes
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    router('/dashboard/templates')
                  }}
                  className="flex text-sm"
                >
                  <PlusCircleIcon className="w-6 h-6 text-blue-500 mr-2" />{' '}
                  Launch
                </div>
              </Heading>
            </div>
            <div className="bg-gray-100 ">
              <Tabs>
                <TabList className="flex">
                  <Tab className="w-full">Your Processes</Tab>
                  {/* <Tab className="w-full">Activity</Tab> */}
                </TabList>

                <TabPanels className="h-[25rem] overflow-y-scroll [&::-webkit-scrollbar]:hidden">
                  <TabPanel>
                    <div className="flex flex-col space-y-4">
                      {state?.launchedTemplates?.data?.map((el, i) => (
                        <div
                          key={i}
                          onClick={() => router(`/dashboard/tracker/${el._id}`)}
                          className="border p-4 rounded-md bg-white cursor-pointer"
                        >
                          <h1 className="font-bold">
                            {renderText(
                              el?.kickOffForm?.name,
                              el?.kickOffForm?.forms,
                            )}{' '}
                            - {el?.name}
                          </h1>
                        </div>
                      ))}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <>
                      {/* Timeline */}
                      <div>
                        {/* Heading */}
                        <div className="ps-2 my-2 first:mt-0">
                          <h3 className="text-xs font-medium uppercase text-gray-500">
                            1 Aug, 2023
                          </h3>
                        </div>
                        {/* End Heading */}
                        {/* Item */}
                        <div className="flex gap-x-3">
                          {/* Icon */}
                          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                            <div className="relative z-10 size-7 flex justify-center items-center">
                              <div className="size-2 rounded-full bg-gray-400" />
                            </div>
                          </div>
                          {/* End Icon */}
                          {/* Right Content */}
                          <div className="grow pt-0.5 pb-8">
                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                              <svg
                                className="flex-shrink-0 size-4 mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                                <polyline points="14 2 14 8 20 8" />
                                <line x1={16} x2={8} y1={13} y2={13} />
                                <line x1={16} x2={8} y1={17} y2={17} />
                                <line x1={10} x2={8} y1={9} y2={9} />
                              </svg>
                              Created "Preline in React" task
                            </h3>
                            <p className="mt-1 text-sm text-gray-600">
                              Find more detailed insctructions here.
                            </p>
                            <button
                              type="button"
                              className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                            >
                              <img
                                className="flex-shrink-0 size-4 rounded-full"
                                src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                                alt="Image Description"
                              />
                              James Collins
                            </button>
                          </div>
                          {/* End Right Content */}
                        </div>
                        {/* End Item */}
                        {/* Item */}
                        <div className="flex gap-x-3">
                          {/* Icon */}
                          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                            <div className="relative z-10 size-7 flex justify-center items-center">
                              <div className="size-2 rounded-full bg-gray-400 " />
                            </div>
                          </div>
                          {/* End Icon */}
                          {/* Right Content */}
                          <div className="grow pt-0.5 pb-8">
                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                              Release v5.2.0 quick bug fix 🐞
                            </h3>
                            <button
                              type="button"
                              className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                            >
                              <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full ">
                                A
                              </span>
                              Alex Gregarov
                            </button>
                          </div>
                          {/* End Right Content */}
                        </div>
                        {/* End Item */}
                        {/* Item */}
                        <div className="flex gap-x-3">
                          {/* Icon */}
                          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                            <div className="relative z-10 size-7 flex justify-center items-center">
                              <div className="size-2 rounded-full bg-gray-400 " />
                            </div>
                          </div>
                          {/* End Icon */}
                          {/* Right Content */}
                          <div className="grow pt-0.5 pb-8">
                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                              Marked "Install Charts" completed
                            </h3>
                            <p className="mt-1 text-sm text-gray-600 ">
                              Finally! You can check it out here.
                            </p>
                            <button
                              type="button"
                              className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                            >
                              <img
                                className="flex-shrink-0 size-4 rounded-full"
                                src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                                alt="Image Description"
                              />
                              James Collins
                            </button>
                          </div>
                          {/* End Right Content */}
                        </div>
                        {/* End Item */}
                        {/* Heading */}
                        <div className="ps-2 my-2">
                          <h3 className="text-xs font-medium uppercase text-gray-500 ">
                            31 Jul, 2023
                          </h3>
                        </div>
                        {/* End Heading */}
                        {/* Item */}
                        <div className="flex gap-x-3">
                          {/* Icon */}
                          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                            <div className="relative z-10 size-7 flex justify-center items-center">
                              <div className="size-2 rounded-full bg-gray-400 " />
                            </div>
                          </div>
                          {/* End Icon */}
                          {/* Right Content */}
                          <div className="grow pt-0.5 pb-8">
                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                              Take a break ⛳️
                            </h3>
                            <p className="mt-1 text-sm text-gray-600 ">
                              Just chill for now... 😉
                            </p>
                          </div>
                          {/* End Right Content */}
                        </div>
                        {/* End Item */}
                        {/* Collapse */}
                        <div
                          id="hs-timeline-collapse"
                          className="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
                          aria-labelledby="hs-timeline-collapse-content"
                        >
                          {/* Heading */}
                          <div className="ps-2 my-2 first:mt-0">
                            <h3 className="text-xs font-medium uppercase text-gray-500 ">
                              30 Jul, 2023
                            </h3>
                          </div>
                          {/* End Heading */}
                          {/* Item */}
                          <div className="flex gap-x-3">
                            {/* Icon */}
                            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                              <div className="relative z-10 size-7 flex justify-center items-center">
                                <div className="size-2 rounded-full bg-gray-400 " />
                              </div>
                            </div>
                            {/* End Icon */}
                            {/* Right Content */}
                            <div className="grow pt-0.5 pb-8">
                              <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                                Final touch ups
                              </h3>
                              <p className="mt-1 text-sm text-gray-600 ">
                                Double check everything and make sure we're
                                ready to go.
                              </p>
                            </div>
                            {/* End Right Content */}
                          </div>
                          {/* End Item */}
                        </div>
                      </div>
                      {/* End Timeline */}
                    </>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </div>
          <div className="p-4">
            <div className=" bg-black text-white rounded-t-md p-4">
              <Heading size="md" className="flex">
                {' '}
                <div className="flex flex-grow flex-1">
                  <RectangleStackIcon className="w-6 h-6 text-blue-500 mr-2" />{' '}
                  Templates
                </div>
                <Popover>
                  <PopoverTrigger>
                    <div hidden={Cookies.get('role') == "member"} className="flex text-sm">
                      <PlusCircleIcon className="w-6 h-6 text-blue-500 mr-2" />{' '}
                      Create
                    </div>
                  </PopoverTrigger>
                  <Portal>
                    <Box
                      zIndex="popover"
                      w="full"
                      h="full"
                      position={'relative'}
                    >
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody className="space-y-4">
                          <div
                            className="cursor-pointer"
                            onClick={() => dispatch(openCreateTemplateModal())}
                          >
                            <h1 className="font-bold">Create Template</h1>
                            <p>Like a recipe.</p>
                          </div>

                          {/* <div onClick={() => dispatch(openCreateTaskModal())}>
                            <h1 className="font-bold">Request a Template</h1>
                            <p>From someone else.</p>
                          </div> */}
                        </PopoverBody>
                      </PopoverContent>
                    </Box>
                  </Portal>
                </Popover>
              </Heading>
            </div>
            <div className="bg-gray-100">
              <Tabs>
                <TabList className="flex">
                  <Tab className="w-full">Templates</Tab>
                  {/* <Tab className="w-full">Activity</Tab> */}
                </TabList>

                <TabPanels className="h-[25rem] overflow-y-scroll [&::-webkit-scrollbar]:hidden">
                  <TabPanel>
                    <div className="flex flex-col space-y-4">
                      {state?.templates?.data?.map((el, i) => (
                        <div
                          key={i}
                          onClick={() =>
                            router(
                              `/dashboard/templates/create/procedure/${el._id}`,
                            )
                          }
                          className="border p-4 rounded-md bg-white cursor-pointer"
                        >
                          <h1 className="font-bold">
                            {el.kickOffForm.name} - {el.name}
                          </h1>
                        </div>
                      ))}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <>
                      {/* Timeline */}
                      <div>
                        {/* Heading */}
                        <div className="ps-2 my-2 first:mt-0">
                          <h3 className="text-xs font-medium uppercase text-gray-500">
                            1 Aug, 2023
                          </h3>
                        </div>
                        {/* End Heading */}
                        {/* Item */}
                        <div className="flex gap-x-3">
                          {/* Icon */}
                          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                            <div className="relative z-10 size-7 flex justify-center items-center">
                              <div className="size-2 rounded-full bg-gray-400" />
                            </div>
                          </div>
                          {/* End Icon */}
                          {/* Right Content */}
                          <div className="grow pt-0.5 pb-8">
                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                              <svg
                                className="flex-shrink-0 size-4 mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                                <polyline points="14 2 14 8 20 8" />
                                <line x1={16} x2={8} y1={13} y2={13} />
                                <line x1={16} x2={8} y1={17} y2={17} />
                                <line x1={10} x2={8} y1={9} y2={9} />
                              </svg>
                              Created "Preline in React" task
                            </h3>
                            <p className="mt-1 text-sm text-gray-600">
                              Find more detailed insctructions here.
                            </p>
                            <button
                              type="button"
                              className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                            >
                              <img
                                className="flex-shrink-0 size-4 rounded-full"
                                src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                                alt="Image Description"
                              />
                              James Collins
                            </button>
                          </div>
                          {/* End Right Content */}
                        </div>
                        {/* End Item */}
                        {/* Item */}
                        <div className="flex gap-x-3">
                          {/* Icon */}
                          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                            <div className="relative z-10 size-7 flex justify-center items-center">
                              <div className="size-2 rounded-full bg-gray-400 " />
                            </div>
                          </div>
                          {/* End Icon */}
                          {/* Right Content */}
                          <div className="grow pt-0.5 pb-8">
                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                              Release v5.2.0 quick bug fix 🐞
                            </h3>
                            <button
                              type="button"
                              className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                            >
                              <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full ">
                                A
                              </span>
                              Alex Gregarov
                            </button>
                          </div>
                          {/* End Right Content */}
                        </div>
                        {/* End Item */}
                        {/* Item */}
                        <div className="flex gap-x-3">
                          {/* Icon */}
                          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                            <div className="relative z-10 size-7 flex justify-center items-center">
                              <div className="size-2 rounded-full bg-gray-400 " />
                            </div>
                          </div>
                          {/* End Icon */}
                          {/* Right Content */}
                          <div className="grow pt-0.5 pb-8">
                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                              Marked "Install Charts" completed
                            </h3>
                            <p className="mt-1 text-sm text-gray-600 ">
                              Finally! You can check it out here.
                            </p>
                            <button
                              type="button"
                              className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                            >
                              <img
                                className="flex-shrink-0 size-4 rounded-full"
                                src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                                alt="Image Description"
                              />
                              James Collins
                            </button>
                          </div>
                          {/* End Right Content */}
                        </div>
                        {/* End Item */}
                        {/* Heading */}
                        <div className="ps-2 my-2">
                          <h3 className="text-xs font-medium uppercase text-gray-500 ">
                            31 Jul, 2023
                          </h3>
                        </div>
                        {/* End Heading */}
                        {/* Item */}
                        <div className="flex gap-x-3">
                          {/* Icon */}
                          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                            <div className="relative z-10 size-7 flex justify-center items-center">
                              <div className="size-2 rounded-full bg-gray-400 " />
                            </div>
                          </div>
                          {/* End Icon */}
                          {/* Right Content */}
                          <div className="grow pt-0.5 pb-8">
                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                              Take a break ⛳️
                            </h3>
                            <p className="mt-1 text-sm text-gray-600 ">
                              Just chill for now... 😉
                            </p>
                          </div>
                          {/* End Right Content */}
                        </div>
                        {/* End Item */}
                        {/* Collapse */}
                        <div
                          id="hs-timeline-collapse"
                          className="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
                          aria-labelledby="hs-timeline-collapse-content"
                        >
                          {/* Heading */}
                          <div className="ps-2 my-2 first:mt-0">
                            <h3 className="text-xs font-medium uppercase text-gray-500 ">
                              30 Jul, 2023
                            </h3>
                          </div>
                          {/* End Heading */}
                          {/* Item */}
                          <div className="flex gap-x-3">
                            {/* Icon */}
                            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                              <div className="relative z-10 size-7 flex justify-center items-center">
                                <div className="size-2 rounded-full bg-gray-400 " />
                              </div>
                            </div>
                            {/* End Icon */}
                            {/* Right Content */}
                            <div className="grow pt-0.5 pb-8">
                              <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                                Final touch ups
                              </h3>
                              <p className="mt-1 text-sm text-gray-600 ">
                                Double check everything and make sure we're
                                ready to go.
                              </p>
                            </div>
                            {/* End Right Content */}
                          </div>
                          {/* End Item */}
                        </div>
                      </div>
                      {/* End Timeline */}
                    </>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <CreateTemplate />
    </div>
  )
}

export default Home2
