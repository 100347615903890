// modalSlice.js
import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'addSnippetsModal',
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    openAddSnippetsModal: (state) => {
      state.isModalOpen = true
    },
    closeAddSnippetsModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const {
  openAddSnippetsModal,
  closeAddSnippetsModal,
} = modalSlice.actions
export default modalSlice.reducer
