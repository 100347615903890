// store.js
import { configureStore } from '@reduxjs/toolkit'
import modalReducer from './InviteMemberModal'
import modalReducer3 from './CreateGuestsModal'
import modalReducer2 from './CreateTaskModal'
import modalReducer4 from './CreateGroupsModal'
import modalReducer5 from './CreateJobsModal'
import modalReducer6 from './AddTagsModal'
import modalReducer7 from './AddSnippetsModal'
import modalReducer8 from './CreateTemplateModal'
import modalReducer9 from './TemplateLaunchModal'
import membersReducer from './MemberSlice'
import tasksReducer from './TaskSlice'
import templatesReducer from './TemplateSlice'
import launchedTemplatesReducer from './LaunchedTemplateSlice' 
import currentUserSlice from './UserSlice'
import organizationSlice from './OrganizationSlice'

const store = configureStore({
  reducer: {
    modal: modalReducer,
    guestsModal: modalReducer3,
    createTaskModal: modalReducer2,
    groupsModal: modalReducer4,
    jobsModal: modalReducer5,
    addTagsModal: modalReducer6,
    addSnippetsModal: modalReducer7,
    createTemplate: modalReducer8,
    templateLaunch: modalReducer9,
    members: membersReducer,
    tasks: tasksReducer,
    templates: templatesReducer,
    launchedTemplates: launchedTemplatesReducer,
    currentUser: currentUserSlice,
    organization: organizationSlice
    // Add other reducers here if needed
  },
})

export default store
