import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import {
  ArrowsPointingInIcon,
  CheckIcon,
  PlayIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
  SpeakerWaveIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid'
import CreateTask from './Shortcuts/CreateTask'
import ShareUpdate from './Shortcuts/ShareUpdate'
import RequestApproval from './Shortcuts/RequestApproval'
import InviteMember from './InviteMemberModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../redux/InviteMemberModal'
import {
  openCreateTaskModal,
  closeCreateTaskModal,
} from '../redux/CreateTaskModal'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Box,
} from '@chakra-ui/react'
import {
  openCreateTemplateModal,
  closeCreateTemplateModal,
} from '../redux/CreateTemplateModal'
import CreateTemplate from '../components/CreateTemplate'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

function CreateModal({
  createModalIsOpen,
  createModalOnOpen,
  createModalOnClose,
  shareUpdateIsOpen,
  shareUpdateOnClose,
  shareUpdateOnOpen,
  requestApprovalIsOpen,
  requestApprovalOnClose,
  requestApprovalOnOpen,
}) {
  const dispatch = useDispatch()

  const router = useNavigate()

  return (
    <>
      <Modal isOpen={createModalIsOpen} onClose={createModalOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Shortcuts</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col mx-auto justify-center items-center space-y-4">
            <Button
              leftIcon={<CheckIcon className="w-4 h-4" />}
              colorScheme="gray"
              className="w-[15rem]"
              onClick={(e) => {
                e.preventDefault()
                createModalOnClose()
                dispatch(openCreateTaskModal())
              }}
            >
              Create Task
            </Button>
            {/* <Button
              leftIcon={<SpeakerWaveIcon className="w-4 h-4" />}
              colorScheme="gray"
              className="w-[15rem]"
              onClick={(e) => {
                e.preventDefault()
                createModalOnClose()
                shareUpdateOnOpen()
              }}
            >
              Share an Update
            </Button>
            <Button
              leftIcon={<ArrowsPointingInIcon className="w-4 h-4" />}
              colorScheme="gray"
              className="w-[15rem]"
              onClick={(e) => {
                e.preventDefault()
                createModalOnClose()
                requestApprovalOnOpen()
              }}
            >
              Request an Approval
            </Button> */}
            <Button
              leftIcon={<RocketLaunchIcon className="w-4 h-4" />}
              colorScheme="gray"
              className="w-[15rem]"
              onClick={(e) => {
                e.preventDefault()
                createModalOnClose()
                router('/dashboard/templates')
              }}
            >
              Launch a process
            </Button>

            <Popover>
              <PopoverTrigger>
                <Button
                  leftIcon={<RectangleStackIcon className="w-4 h-4" />}
                  colorScheme="gray"
                  className="w-[15rem]"
                  hidden={Cookies.get('role') == "member"}
                >
                  Create a template
                </Button>
              </PopoverTrigger>
              <Portal>
                <Box zIndex="popover" w="full" h="full" position={'relative'}>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody className="space-y-4">
                      <div
                        className="cursor-pointer"
                        onClick={() => dispatch(openCreateTemplateModal())}
                      >
                        <h1 className="font-bold">Create Template</h1>
                        <p>Like a recipe.</p>
                      </div>

                      {/* <div onClick={() => dispatch(openCreateTaskModal())}>
                        <h1 className="font-bold">Request a Template</h1>
                        <p>From someone else.</p>
                      </div> */}
                    </PopoverBody>
                  </PopoverContent>
                </Box>
              </Portal>
            </Popover>
          </ModalBody>

          <ModalFooter className="mt-8">
            {/* <Button
              rightIcon={<PlayIcon className="w-4 h-4 ml-2" />}
              colorScheme="blue"
              mr={3}
            >
              Watch Intro
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ShareUpdate
        shareUpdateIsOpen={shareUpdateIsOpen}
        shareUpdateOnClose={shareUpdateOnClose}
        shareUpdateOnOpen={shareUpdateOnOpen}
      />
      <RequestApproval
        requestApprovalIsOpen={requestApprovalIsOpen}
        requestApprovalOnClose={requestApprovalOnClose}
        requestApprovalOnOpen={requestApprovalOnOpen}
      />
      <InviteMember />
      <CreateTask />
      <CreateTemplate />
    </>
  )
}

export default CreateModal
