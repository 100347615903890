import React, { useState, useRef, useEffect } from 'react'
import Header from '../../../components/Header'
import SettingsSidebar from '../../../components/SettingsSidebar'
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

const data = {
  Privacy: [
    {
      template: 'DPA (EU)',
      description: 'Request a DPA for any country.',
    },
    {
      template: 'IDTA (UK)',
      description: 'We can sign an IDTA contract.',
    },
  ],
  Enterprise: [
    {
      template: 'Localized Data Hosting',
      description: 'Provision geo-specific data storage.',
    },
    {
      template: 'AICPA SOC 2',
      description: 'Learn more about our SOC 2 compliance.',
    },
    {
      template: 'API Logs',
      description:
        'Infospray BPM can make the last 28 days of API activity logs available for audit.',
    },
    {
      template: 'Data Validation',
      description:
        'Empower your developers to write serverless functions to validate form fields.',
    },
    {
      template: 'Immutable Audit Trails',
      description: 'Stream activity feeds to a blockchain-enabled endpoint.',
    },
    {
      template: '21 CFR Part 11',
      description: 'Available for life sciences.',
    },
    {
      template: 'VPAT',
      description: 'Web Accessibility Report.',
    },
    {
      template: 'BIMI Compliance',
      description: 'Infospray BPM is fully BIMI-compliant.',
    },
  ],
  Insurance: [
    {
      template: 'SLA - API',
      description: 'Monitor API uptime via a third-party.',
    },
    {
      template: 'SLA - Client',
      description: 'Monitor UI uptime via a third-party.',
    },
  ],
}

function Compliance() {
  const scrollingAllowedDivRef = useRef(null)

  const scrollToSection = (heading) => {
    const element = document.getElementById(heading)
    if (element && scrollingAllowedDivRef.current) {
      scrollingAllowedDivRef.current.scrollTop = element.offsetTop - 100
    }
  }

  const TemplatePage = () => {
    return (
      <Flex className="flex flex-row space-x-4">
        {/* Sidebar */}
        <Box className="w-full lg:w-1/5 hidden lg:block" overflowY="auto">
          {Object.keys(data).map((heading) => (
            <Text
              key={heading}
              fontSize="lg"
              mb="2"
              cursor="pointer"
              onClick={() => scrollToSection(heading)}
              className="normal-case"
            >
              {heading}
            </Text>
          ))}
        </Box>

        {/* Content */}
        <Box
          ref={scrollingAllowedDivRef}
          className="lg:ml-1/5 w-full lg:w-4/5 h-[85vh] max-h-screen overflow-y-scroll mx-auto lg:px-8 py-4"
        >
          {Object.entries(data).map(([heading, templates]) => (
            <Box key={heading} mb="8" id={heading}>
              <Heading fontSize="xl" mb="4">
                {heading}
              </Heading>

              <Flex
                flexWrap="wrap"
                gap="4"
                className="gap-2 grid grid-cols-1 lg:grid-cols-3"
              >
                {templates.map((template) => (
                  <Box
                    key={template}
                    p="4"
                    border="1px solid #ccc"
                    borderRadius="md"
                  >
                    <Text>{template.template}</Text>
                    <Text mt="2" mb="4" color="gray.600">
                      {template.description}
                    </Text>
                    <Button colorScheme="blue">Contact Us</Button>
                  </Box>
                ))}
              </Flex>
            </Box>
          ))}
        </Box>
      </Flex>
    )
  }

  const router = useNavigate()

  useEffect(() => {
    if (Cookies.get('role') == 'owner' || Cookies.get('role') == 'member') {
      return router('/signin')
    }
  }, [])


  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row  py-12 lg:py-0 ">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <TemplatePage />
        </div>
      </div>
    </div>
  )
}

export default Compliance
