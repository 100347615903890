import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header'
import SettingsSidebar from '../../../components/SettingsSidebar'
import {
  FormControl,
  FormLabel,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

function Domains() {

  const router = useNavigate()

  useEffect(() => {
    if (Cookies.get('role') == 'owner' || Cookies.get('role') == 'member') {
      return router('/signin')
    }
  }, [])

  
  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row  py-12 lg:py-0 ">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="my-8 space-y-4">
            <div>
              {' '}
              <p className="font-bold">Domain names owned</p>
              <ul className="px-4 pb-8">
                <li>infospray.dk</li>
              </ul>
              <Button>Update My Domains</Button>
            </div>
          </div>
          <div className="my-8 space-y-4">
            <div className="mx-2 flex flex-row">
              <FormControl className="flex flex-col" alignItems="left">
                <div className="flex justify-between">
                  <FormLabel htmlFor="1" mb="0">
                    <p className="font-bold text-lg">Auto Join</p>
                    <p>
                      Auto-join any new signup whose email matches company
                      domains to this org?
                    </p>
                  </FormLabel>
                  <Switch id="1" />
                </div>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Domains
