// modalSlice.js
import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'createTemplate',
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    openCreateTemplateModal: (state) => {
      state.isModalOpen = true
    },
    closeCreateTemplateModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const {
  openCreateTemplateModal,
  closeCreateTemplateModal,
} = modalSlice.actions
export default modalSlice.reducer
