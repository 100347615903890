import React, { useState, useEffect } from 'react'
import {
  ArrowDownTrayIcon,
  BellAlertIcon,
  ChatBubbleLeftIcon,
  CheckIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  HomeIcon,
  IdentificationIcon,
  InboxStackIcon,
  NewspaperIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  SquaresPlusIcon,
  UserGroupIcon,
  UserPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  useDisclosure,
  Avatar,
  Modal,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom'

import { Text } from '@chakra-ui/react'
import CreateModal from './CreateModal'

import { useDispatch, useSelector } from 'react-redux'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../redux/InviteMemberModal'
import Cookies from 'js-cookie'
import { fetchTasks } from '../redux/TaskSlice'
import moment from 'moment-timezone';
import { format } from 'date-fns';

function Header() {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const router = useNavigate()
  const location = useLocation()
  const [brandLogo, setBrandLogo] = useState('');
  const [buttonColor, setButtonColor] = useState('');

  useEffect(() => {
    if (!Cookies.get('token') || !Cookies.get('email')) {
      return router('/signin')
    }
  }, [])

  const {
    isOpen: createModalIsOpen,
    onOpen: createModalOnOpen,
    onClose: createModalOnClose,
  } = useDisclosure()

  const {
    isOpen: shareUpdateIsOpen,
    onOpen: shareUpdateOnOpen,
    onClose: shareUpdateOnClose,
  } = useDisclosure()
  const {
    isOpen: requestApprovalIsOpen,
    onOpen: requestApprovalOnOpen,
    onClose: requestApprovalOnClose,
  } = useDisclosure()

  const dispatch = useDispatch()

  const state = useSelector((state) => state)

  useEffect(() => {
    dispatch(fetchTasks())

    setIsLoading(false)

    console.log('state', state)
  }, [dispatch])

  const [notifications, setNotifcations] = useState([])

  useEffect(() => {
    // Function to group activities by date
    function groupActivitiesByDate(activities) {
      const groupedActivities = activities.reduce((groups, activity) => {
        const date = new Date(
          activity?.createdAt?.split('T')[0],
        ).toLocaleDateString('en-US')
        if (!groups[date]) {
          groups[date] = { date, activities: [] }
        }
        groups[date].activities.push(activity)
        return groups
      }, {})
      return Object.values(groupedActivities)
    }

    // Extract activities from tasks and combine them into a single array
    let allActivities = []
    if (
      state &&
      state.tasks &&
      state.tasks.data &&
      state.tasks.data.length > 0
    ) {
      state?.tasks?.data?.forEach((task) => {
        task?.activity?.forEach((activity) => {
          allActivities.push({ ...activity, taskName: task.name })
        })
      })
    }

    // Sort activities based on createdAt
    allActivities.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

    // Group activities by date
    const groupedActivities = groupActivitiesByDate(allActivities)

    // Merge grouped activities with notifications
    const updatedNotifications = groupedActivities.map((group) => ({
      date: group.date,
      activities: group.activities.map((activity) => ({
        ...activity,
        taskName: activity.taskName,
      })),
    }))

    console.log(updatedNotifications, 'updatedNotifications')

    // Update state with updated notifications
    setNotifcations(updatedNotifications)
  }, [state?.tasks?.data]) // Execute when state.tasks.data changes

    // Function to get formatted date and time
  const getFormattedDate = (utcDate) => {
    if(!utcDate) return 
    const date = moment.tz(utcDate, Cookies.get('timeZone'));
    return format(new Date(date.format()), Cookies.get('dateFormat'));
  };

  useEffect(()=>{
    setBrandLogo(decodeURIComponent(Cookies.get('brandLogo')))
    setButtonColor(Cookies.get('buttonColor'))
    console.log(Cookies.get("buttonColor"))
  },[])


  return (
    <>
      <Modal isCentered isOpen={isLoading}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
          className="items-center flex justify-center"
        >
          <Spinner size="xl" />
        </ModalOverlay>
      </Modal>{' '}
      <body className=" max-h-[10vh]">
        <header   style={{ backgroundColor: buttonColor }} className={`sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-[48] w-full border-b text-sm py-2.5 sm:py-4 lg:ps-64 `}>
          <nav
            className="flex basis-full items-center w-full mx-auto px-4 sm:px-6 md:px-8"
            aria-label="Global"
          >
          <div className="me-5 lg:me-0 lg:hidden">
            {brandLogo ? (
              <a className="flex" href="#">
  <img
    src={brandLogo}
    alt="Infospray BPM"
    className="w-10 max-w-full"
  />
</a>

            ) : (
              <a
                className="flex-none text-xl font-semibold dark:text-white"
                href="#"
              >
                Brand
              </a>
            )}
          </div>

            <div className="w-full flex items-center justify-end ms-auto">
              {/* <div className="sm:hidden">
                <button
                  type="button"
                  className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <path d="m21 21-4.3-4.3" />
                  </svg>
                </button>
              </div> */}

              {/* <div className="hidden sm:block">
                <label for="icon" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                    <svg
                      className="flex-shrink-0 size-4 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <path d="m21 21-4.3-4.3" />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="icon"
                    name="icon"
                    className="py-2 px-4 ps-11 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                    placeholder="Search"
                  />
                </div>
              </div> */}

              <div className="flex flex-row items-center justify-end space-x-4">
                {/* <Menu>
                  <MenuButton>
                    <button
                      type="button"
                      className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
                        <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
                      </svg>
                    </button>
                  </MenuButton>
                  <Portal>
                    <MenuList
                      style={{ zIndex: 1000 }}
                      className="text-sm w-[5rem] absolute mt-1" // Adjust width and positioning
                    >
                      <MenuItem>
                        <div className="flex flex-col gap-y-4">
                          <div>
                            All third-party developers can integrate our
                            functionalities into their own applications through
                            APIs.
                          </div>
                          <div>
                            <Button className="rounded-md" colorScheme="green">
                              Coming soon!
                            </Button>
                          </div>
                        </div>
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu> */}
{/* 
                <Menu>
                  <MenuButton
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <BellAlertIcon className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" />
                    {notifications?.some(
                      (notification) => !notification.isRead,
                    ) && (
                      <span className="text-xs text-white" id="red-dot">
                        {
                          notifications?.filter(
                            (notification) => !notification.isRead,
                          ).length
                        }
                      </span>
                    )}
                  </MenuButton>
                  <MenuList className="w-[40rem] max-w-[60vw] h-[20rem] overflow-y-scroll">
                    {notifications?.map((notificationGroup) => (
                      <div key={notificationGroup.date}>
                        <MenuItem
                          style={{ textAlign: 'left' }}
                          className={`flex flex-col text-left p-0 m-0 `}
                        >
                          <div className={`text-left p-2 border w-full`}>
                            <Text fontWeight="bold">{`Activities on ${getFormattedDate(notificationGroup.date)}`}</Text>
                          </div>
                        </MenuItem>
                        {notificationGroup.activities.map((activity, index) => (
                          <MenuItem
                            style={{ textAlign: 'left' }}
                            className={`flex flex-col text-left p-0 m-0 `}
                            key={activity._id}
                          >
                            <div
                              className={`text-left p-2 border w-full ${
                                !activity.isRead ? 'bg-green-200' : ''
                              }`}
                            >
                              <Text fontWeight="bold">{activity.message}</Text>
                              <Text>{activity.taskName}</Text>
                              <div className="flex flex-row text-sm mt-4 py-4">
                                <div className="flex flex-col">
                                  <Text>{activity.createdAt}</Text>
                                </div>
                                {!activity.isRead && (
                                  <Button
                                    size="sm"
                                    colorScheme="red"
                                    className="flex ml-auto justify-end"
                                  >
                                    Mark as Read
                                  </Button>
                                )} 
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                      </div>
                    ))}
                  </MenuList>
                </Menu> */}
                {/* <button
                  type="button"
                  className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  data-hs-offcanvas="#hs-offcanvas-right"
                >
                  <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                  </svg>
                </button> */}

                <div className="hs-dropdown relative inline-flex [--placement:bottom-right]">
                  {/* <button
                    id="hs-dropdown-with-header"
                    type="button"
                    className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  ></button> */}

                  <Menu>
                    <MenuButton rightIcon={<ChevronDownIcon />}>
                      <div className='className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"'>
                        <Avatar size="xs" />
                      </div>
                    </MenuButton>
                    <MenuList>
                      <MenuItem>
                        <p className="text-sm text-gray-500 mr-2">
                          Signed in as
                        </p>
                        <p className="text-sm font-medium text-gray-800 ">
                          {Cookies.get('email')}
                        </p>
                      </MenuItem>
                      <MenuItem onClick={() => router('/dashboard/settings/profile')}>Profile Settings</MenuItem>
                      <MenuItem onClick={() => router('/dashboard/settings/details')}>Account Settings</MenuItem>
                      <MenuItem
                        onClick={() => {
                          // Get all cookies
                          const cookies = Cookies.get();

                          // Remove each cookie
                          Object.keys(cookies).forEach(cookieName => {
                            Cookies.remove(cookieName);
                          });
                          router('/signin')
                        }}
                      >
                        Sign Out
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <div className={`sticky top-0 inset-x-0 z-20 bg border-y px-4 sm:px-6 md:px-8 lg:hidden`}   style={{ backgroundColor: buttonColor }}>
          <div className="flex items-center py-4">
            <button
              type="button"
              className="text-gray-500 hover:text-gray-600"
              data-hs-overlay="#application-sidebar"
              aria-controls="application-sidebar"
              aria-label="Toggle navigation"
              onClick={() => setOpen(!open)}
            >
              <span className="sr-only">Toggle Navigation</span>
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="3" x2="21" y1="6" y2="6" />
                <line x1="3" x2="21" y1="12" y2="12" />
                <line x1="3" x2="21" y1="18" y2="18" />
              </svg>
            </button>
            <ol
              className="ms-3 flex items-center whitespace-nowrap"
              aria-label="Breadcrumb"
            >
              <li className="flex items-center text-sm text-gray-800 dark:text-gray-400">
                Application Layout
                <svg
                  className="flex-shrink-0 mx-3 overflow-visible size-2.5 text-gray-400 dark:text-gray-600"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </li>
              <li
                className="text-sm font-semibold text-gray-800 truncate dark:text-gray-400"
                aria-current="page"
              >
                Dashboard
              </li>
            </ol>
          </div>
        </div>

        <div
          //   id="application-sidebar"
          onClick={() => setOpen(false)}
          style={{ backgroundColor: buttonColor }}
          className={` ${
            open ? '' : '-translate-x-full transition-all'
          } h-[100vh] duration-300 transform fixed top-0 start-0 bottom-0 z-[60] w-26 border-e border-gray-200 pt-7 pb-10 overflow-y-auto  lg:translate-x-0 lg:end-auto lg:bottom-0 `}
        >
          <div className="px-6 flex flex-row">
            <a
              className="flex-none text-xl font-semibold dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              href="#"
              aria-label="Brand"
            >
            {brandLogo ? (
              <a className="flex" href="#">
  <img
    src={brandLogo}
    alt="Infospray BPM"
    className="w-[5rem] h-full"
  />
</a>

            ) : (
              "Brand"
            )}
            </a>
            <div className="fixed top-2 right-2 flex lg:hidden">
              <XMarkIcon
                onClick={() => setOpen(false)}
                className="h-4 w-4 text-gray-400"
              />
            </div>
          </div>

          <nav
            className="hs-accordion-group p-6 w-full flex flex-col flex-wrap "
            data-hs-accordion-always-open
          >
            <ul className="space-y-1.5">
              <li>
                <button
                  type="button"
                  className={`${
                    location.pathname.includes('/create')
                      ? 'dark:bg-gray-900'
                      : ''
                  } hs-accordion-toggle w-full text-start flex flex-col items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                  onClick={(e) => {
                    e.preventDefault()
                    createModalOnOpen()
                  }}
                >
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                  Create
                </button>
              </li>

              <li className="hs-accordion" id="users-accordion">
                <button
                  type="button"
                  onClick={() => router('/dashboard/home')}
                  className={`${
                    location.pathname.includes('/home')
                      ? 'dark:bg-gray-900'
                      : ''
                  } hs-accordion-toggle w-full text-start flex flex-col items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                >
                  <HomeIcon className="h-4 w-4 text-blue-500" />
                  Home
                </button>
              </li>

              <li>
                <button
                  type="button"
                  onClick={() => router('/dashboard/tasks')}
                  className={`${
                    location.pathname.includes('/tasks')
                      ? 'dark:bg-gray-900'
                      : ''
                  } hs-accordion-toggle w-full text-start flex flex-col items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                >
                  <CheckIcon className="h-4 w-4 text-blue-500" />
                  Tasks
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => router('/dashboard/tracker')}
                  className={`${
                    location.pathname.includes('/tracker')
                      ? 'dark:bg-gray-900'
                      : ''
                  } hs-accordion-toggle w-full text-start flex flex-col items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                >
                  <SquaresPlusIcon className="h-4 w-4 text-blue-500" />
                  Tracker
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => router('/dashboard/templates')}
                  className={`${
                    location.pathname.includes('/templates')
                      ? 'dark:bg-gray-900'
                      : ''
                  } hs-accordion-toggle w-full text-start flex flex-col items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                >
                  <InboxStackIcon className="h-4 w-4 text-blue-500" />
                  Templates
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => router('/dashboard/admin')}
                  className={`${
                    location.pathname.includes('/dashboard/admin')
                      ? 'dark:bg-gray-900'
                      : ''
                  } hs-accordion-toggle w-full text-start flex flex-col items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                >
                  <IdentificationIcon className="h-4 w-4 text-blue-500" />
                  People
                </button>
              </li>
            </ul>
          </nav>
          {/* Place the below div at the end */}
          <div
            className="fixed bottom-0 p-6 w-full flex flex-col flex-wrap"
            data-hs-accordion-always-open
          >
            <ul className="space-y-1.5">
              <li>
              </li>
              <li>
                <button
                  type="button"
                  className={`${
                    location.pathname.includes('/help')
                      ? 'dark:bg-gray-900'
                      : ''
                  } hs-accordion-toggle w-full text-start flex flex-col items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                >
                  <QuestionMarkCircleIcon className="h-4 w-4 text-blue-500" />
                  Help
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => router('/dashboard/settings')}
                  className={`${
                    location.pathname.includes('/settings')
                      ? 'dark:bg-gray-900'
                      : ''
                  } hs-accordion-toggle w-full text-start flex flex-col items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                >
                  <Cog6ToothIcon className="h-4 w-4 text-blue-500" />
                  Settings
                </button>
              </li>
              {/* <li>
                <button
                  type="button"
                  className={`${
                    location.pathname.includes('/chat')
                      ? 'dark:bg-gray-900'
                      : ''
                  } hs-accordion-toggle w-full text-start flex flex-col items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                >
                  <ChatBubbleLeftIcon className="h-4 w-4 text-blue-500" />
                  Live Chat
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      </body>
      <CreateModal
        createModalIsOpen={createModalIsOpen}
        createModalOnOpen={createModalOnOpen}
        createModalOnClose={createModalOnClose}
        shareUpdateIsOpen={shareUpdateIsOpen}
        shareUpdateOnClose={shareUpdateOnClose}
        shareUpdateOnOpen={shareUpdateOnOpen}
        requestApprovalIsOpen={requestApprovalIsOpen}
        requestApprovalOnClose={requestApprovalOnClose}
        requestApprovalOnOpen={requestApprovalOnOpen}
      />
    </>
  )
}

export default Header
