import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { PlayIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../redux/InviteMemberModal'
import Cookies from 'js-cookie'

function InviteMember() {
  const dispatch = useDispatch()
  const isModalOpen = useSelector((state) => state.modal.isModalOpen)
  const toast = useToast()
  const [data, setData] = useState({
    fName: '',
    lName: '',
    email: '',
    privilege: '',
    message: '',
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (
      data.fName.length > 0 &&
      data.lName.length > 0 &&
      data.privilege.length > 0 &&
      data.message.length > 0 &&
      data.email.includes('.') &&
      data.email.includes('@')
    ) {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/members/invite`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
          body: JSON.stringify(data),
        },
      )
      const res2 = await res.json()
      if (res2.message) {
        toast({
          title: res2.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      } else {
        toast({
          title: res2.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      handleCloseModal()
    } else {
      toast({
        title: 'Something went wrong!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOpenModal = () => {
    dispatch(openInviteMemberModal())
    // Additional logic, if needed
    onOpen()
  }

  const handleCloseModal = () => {
    dispatch(closeInviteMemberModal())
    // Additional logic, if needed
    onClose()
  }
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite New Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
            <Input
              type="text"
              placeholder="First name"
              required
              value={data.fName}
              onChange={(e) => setData({ ...data, fName: e.target.value })}
            />
            <Input
              type="text"
              placeholder="Last name"
              required
              value={data.lName}
              onChange={(e) => setData({ ...data, lName: e.target.value })}
            />
            <Input
              type="email"
              placeholder="Email"
              required
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <Select
              value={data.privilege}
              onChange={(e) => setData({ ...data, privilege: e.target.value })}
              placeholder="Privilege"
              className="w-full"
            >
              <option value="admin">Admin</option>
              <option value="standard">Standard</option>
              <option value="light">Light</option>
            </Select>
            <Textarea
              type="text"
              placeholder="Message"
              required
              value={data.message}
              onChange={(e) => setData({ ...data, message: e.target.value })}
            />
          </ModalBody>

          <ModalFooter className="mt-8">
            <Button
              rightIcon={<UserGroupIcon className="w-4 h-4 ml-2" />}
              colorScheme="blue"
              onClick={(e) => handleSubmit(e)} // You can dispatch further actions here
            >
              Invite Member
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InviteMember
