// modalSlice.js
import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'addTagsModal',
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    openAddTagsModal: (state) => {
      state.isModalOpen = true
    },
    closeAddTagsModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const {
  openAddTagsModal,
  closeAddTagsModal,
} = modalSlice.actions
export default modalSlice.reducer
