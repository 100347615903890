// RichTextEditor.js
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { fetchMembers } from '../../redux/MemberSlice'
import { useDispatch, useSelector } from 'react-redux'

// Convert HTML to EditorState
const htmlToEditorState = (html) => {
  if(!html) return ""
  const contentState = convertFromHTML(html);
  const contentStateFromHTML = ContentState.createFromBlockArray(contentState.contentBlocks, contentState.entityMap);
  return EditorState.createWithContent(contentStateFromHTML);
};

// Convert EditorState to HTML
const editorStateToHTML = (editorState) => {
  const contentState = editorState.getCurrentContent();
  return draftToHtml(convertToRaw(contentState));
};

const RichTextEditor = ({ value, onChange }) => {
  const [editorState, setEditorState] = React.useState(() => htmlToEditorState(value));
  const [mentionSuggestions, setMentionSuggestions] = useState([]);

  const handleEditorStateChange = (state) => {
    setEditorState(state);
    onChange(editorStateToHTML(state)); // Send the updated HTML back to the parent component
  };

  const members = useSelector((state) => state.members?.data?.members || []);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchMembers())
    console.log(members)
  }, [])

  // Update mention suggestions whenever members data changes
  useEffect(() => {
    if (members.length) {
      const suggestions = members.map((member) => ({
        text: `${member.fName} ${member.lName}`,
        value: member.email,
        url: `mailto:${member.email}`, // Optionally link to member's email
      }));
      setMentionSuggestions(suggestions);
    }
  }, [members]);

  return (
    <Editor
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor h-[300px] border border-2 p-2"  // Tailwind CSS height utility
      editorState={editorState}
      onEditorStateChange={handleEditorStateChange}
      mention={{
        separator: ' ',
        trigger: '@',
        suggestions: mentionSuggestions, // Populate with member suggestions
      }}
    />
  );
};

export default RichTextEditor;
