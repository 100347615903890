// index.js
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
// 1. import `ChakraProvider` component
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import store from './redux/store' // Import the Redux store
import { MultiSelectTheme } from 'chakra-multiselect'
import { GoogleOAuthProvider } from '@react-oauth/google'

const theme = extendTheme({
  components: {
    MultiSelect: MultiSelectTheme,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </ChakraProvider>
    </Provider>
  </BrowserRouter>,
)
