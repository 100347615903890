import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header'
import SettingsSidebar from '../../../components/SettingsSidebar'
import {
  FormControl,
  FormLabel,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Text,
  Textarea,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Box,
  AccordionPanel,
} from '@chakra-ui/react'
import {
  Cog6ToothIcon,
  CurrencyDollarIcon,
  FaceSmileIcon,
} from '@heroicons/react/24/solid'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'


function Billing() {
  const router = useNavigate()

  useEffect(() => {
    if (Cookies.get('role') == 'owner' || Cookies.get('role') == 'member') {
      return router('/signin')
    }
  }, [])

  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row py-12 lg:py-0">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="mx-auto my-8">
            <div className="mb-6">
              <h2 className="text-lg font-semibold mb-2">
                Would you like someone else to pay?
              </h2>
              <p className="mb-4">
                Ask them to add a payment card at this link then email
                support@tallyfy.com
              </p>
              <div className="flex items-center">
                <Input
                  className="mr-2"
                  readOnly
                  type="text"
                  value="https://tallyfy-v2.recurly.com/account/billing_info/edit?ht=..."
                />
                <Button>Copy Link</Button>
              </div>
            </div>
            <div className="mb-6 space-x-4">
              <Button>Edit Payment Card</Button>
              <Button>Billing History</Button>
            </div>
            <Button className="my-4" colorScheme="red">
              Delete Account
            </Button>
            <p className="mb-6">You're on 'AppSumo' with 2 members.</p>
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-4">Discount Options</h3>
              <div className="mb-4 flex flex-row items-center">
                <CurrencyDollarIcon className="w-10 h-10 text-green-500 mr-2" />
                <div>
                  <span>You are on a monthly plan</span>
                  <p className="text-sm text-gray-600">
                    Go annual for 2 months free!
                  </p>
                </div>
              </div>
              <div className="mb-4 flex flex-row items-center">
                <Cog6ToothIcon className="w-10 h-10 text-blue-500 mr-2" />
                <div>
                  <span>Purchase credit in bulk</span>
                  <p className="text-sm text-gray-600">
                    Learn more{' '}
                    <Link className="text-blue-500" href="#">
                      here
                    </Link>
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <Select
                  onChange={(e) => console.log(e.target.value)}
                  placeholder="amount"
                  className="w-[full]"
                  value="$5000"
                >
                  <option value="5000">$5000</option>
                  <option value="10000">$10000</option>
                </Select>{' '}
                <Button className="ml-2">Request Quote</Button>
              </div>
            </div>

            <Accordion className="" allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <div className="mb-6">
                        <h3 className="text-lg font-semibold mb-4">
                          Recommend Infospray BPM to someone else
                        </h3>
                        <div className="flex items-center mb-4 items-center">
                          <FaceSmileIcon className="w-10 h-10 mr-2" />
                          <div>
                            <span>Happy with Infospray BPM?</span>
                            <p className="text-sm text-gray-600">
                              Share the love!
                            </p>
                          </div>
                        </div>
                      </div>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel py={4}>
                  <form>
                    <div className="grid gap-4">
                      <Input placeholder="First Name *" type="text" />
                      <Input placeholder="Last Name *" type="text" />
                      <Input placeholder="Email *" type="email" />
                      <Textarea placeholder="Message *" />
                    </div>
                  </form>
                  <div className="flex justify-end my-4">
                    <Button>SEND</Button>
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Billing
