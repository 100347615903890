// modalSlice.js
import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    openInviteMemberModal: (state) => {
      state.isModalOpen = true
    },
    closeInviteMemberModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const {
  openInviteMemberModal,
  closeInviteMemberModal,
} = modalSlice.actions
export default modalSlice.reducer
