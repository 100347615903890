import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Textarea,
  useDisclosure,
  Box,
  Avatar
} from '@chakra-ui/react'
import { PlayIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  openCreateGroupModal,
  closeCreateGroupModal,
} from '../redux/CreateGroupsModal'
import { MultiSelect } from 'chakra-multiselect'

function CreateGroups() {
  const dispatch = useDispatch()
  const isModalOpen = useSelector((state) => state.groupsModal.isModalOpen)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [value, setValue] = useState([])
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // Add more options as needed
  ]

  const handleOpenModal = () => {
    dispatch(openCreateGroupModal())
    // Additional logic, if needed
    onOpen()
  }

  const handleCloseModal = () => {
    dispatch(closeCreateGroupModal())
    // Additional logic, if needed
    onClose()
  }

  const [avatar, setAvatar] = useState(null)

  const handleFileChange = (event) => {
    const file = event.target.files[0]

    if (file) {
      // You can perform additional checks here (e.g., file type, size)
      const reader = new FileReader()

      reader.onloadend = () => {
        setAvatar(reader.result)
      }

      reader.readAsDataURL(file)
    }
  }

  const handleClearAvatar = () => {
    setAvatar(null)
  }

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
            <Input type="text" placeholder="First name" required />
            <Input type="text" placeholder="Last name" required />
            <Textarea type="text" placeholder="Description" required />
            {/* Assign Members */}
            <MultiSelect
              options={options}
              value={value}
              label="Assign Members"
              onChange={setValue}
            />
            {/* Assign Guests */}
            <MultiSelect
              options={options}
              value={value}
              label="Assign guests"
              onChange={setValue}
            />

            <Box>
              <Box mb={4}>
                <Avatar src={avatar} className='justify-center center m-auto align-center text-center' size="2xl" mb={4} />
              </Box>
              <Input type="file" onChange={handleFileChange} mb={4} />
            </Box>
          </ModalBody>

          <ModalFooter className="mt-8">
            <Button
              rightIcon={<PlayIcon className="w-4 h-4 ml-2" />}
              colorScheme="fray"
              mr={3}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              rightIcon={<UserGroupIcon className="w-4 h-4 ml-2" />}
              colorScheme="blue"
              onClick={handleCloseModal} // You can dispatch further actions here
            >
              Create Group
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateGroups
