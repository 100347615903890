import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header'
import SettingsSidebar from '../../../components/SettingsSidebar'
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { DataGrid2 } from '../../../components/utils/DataGrid.tsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  openAddSnippetsModal,
  closeAddSnippetsModal,
} from '../../../redux/AddSnippetsModal'
import AddSnippets from '../../../components/AddSnippets'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

function Snippets() {
  const dispatch = useDispatch()
  const rows = [
    {
      id: 1,
      Title: 'Process 1',
      createdAt: '2022-03-10T12:30:00',
      updatedAt: '2022-03-12T08:45:00',
      Actions: 'Edit',
    },
    {
      id: 2,
      Title: 'Process 2',
      createdAt: '2022-03-11T09:15:00',
      updatedAt: '2022-03-13T14:20:00',
      Actions: 'Edit',
    },
    // Add more rows as needed
  ]

  const columns = [
    { field: 'Title', headerName: 'Title', width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 200 },
    { field: 'updatedAt', headerName: 'Updated At', width: 200 },
    { field: 'Actions', headerName: 'Actions', width: 150 },
  ]

  const router = useNavigate()

  useEffect(() => {
    if (Cookies.get('role') == 'owner' || Cookies.get('role') == 'member') {
      return router('/signin')
    }
  }, [])


  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row  py-12 lg:py-0 ">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="flex flex-col space-y-2 items-start pb-4">
            <div className="flex justify-between">
              Snippets are static and re-usable blocks of content that you
              maintain in one place and re-use everywhere. Perfect for popular
              content blocks that change frequently like - List of Support
              Contacts, Office Wi-Fi Access, Our Top 10 Clients, Our Newest Team
              Members and so on.
            </div>
            <Button
              variant="ghost"
              onClick={() => dispatch(openAddSnippetsModal())}
            >
              + Create new snippets
            </Button>
          </div>

          <div
            style={{
              maxWidth: '100vw',
              display: 'flex',
              justifyItems: 'center',
            }}
          >
            <DataGrid2 rows={rows} columns={columns} />
          </div>
        </div>
      </div>
      <AddSnippets />
    </div>
  )
}

export default Snippets
