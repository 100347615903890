import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header.jsx'
import SettingsSidebar from '../../../components/SettingsSidebar.jsx'
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { DataGrid2 } from '../../../components/utils/DataGrid.tsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  openCreateGroupModal,
  closeCreateGroupModal,
} from '../../../redux/CreateGroupsModal.js'
import CreateGroups from '../../../components/CreateGroups.jsx'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

function Groups() {
  const dispatch = useDispatch()
  const rows = [
    {
      id: 1,
      Name: 'Project A',
      Description: 'A sample project',
      Members: 5,
      Action: 'View Details',
    },
    {
      id: 2,
      Name: 'Project B',
      Description: 'Another project description',
      Members: 8,
      Action: 'Edit Project',
    },
    {
      id: 3,
      Name: 'Project C',
      Description: 'Project C description',
      Members: 3,
      Action: 'Delete Project',
    },
    // Add more rows as needed
  ]

  // Columns definition
  const columns = [
    { field: 'Name', headerName: 'Name', width: 200 },
    { field: 'Description', headerName: 'Description', width: 300 },
    { field: 'Members', headerName: 'Members', width: 100 },
    { field: 'Action', headerName: 'Action', width: 150 },
  ]

  const router = useNavigate()

  useEffect(() => {
    if (Cookies.get('role') == 'owner' || Cookies.get('role') == 'member') {
      return router('/signin')
    }
  }, [])


  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row  py-12 lg:py-0 ">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <Tabs>
            <TabList>
              <Tab>Table</Tab>
              <Tab>Activity</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <div className="flex flex-col space-y-2 items-start">
                  <Button
                    variant="ghost"
                    onClick={() => dispatch(openCreateGroupModal())}
                  >
                    + Create a New Group
                  </Button>
                </div>

                <div
                  style={{
                    maxWidth: '100vw',
                    display: 'flex',
                    justifyItems: 'center',
                  }}
                >
                  <DataGrid2 rows={rows} columns={columns} />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  {/* Heading */}
                  <div className="ps-2 my-2 first:mt-0">
                    <h3 className="text-xs font-medium uppercase text-gray-500">
                      1 Aug, 2023
                    </h3>
                  </div>
                  {/* End Heading */}
                  {/* Item */}
                  <div className="flex gap-x-3">
                    {/* Icon */}
                    <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                      <div className="relative z-10 size-7 flex justify-center items-center">
                        <div className="size-2 rounded-full bg-gray-400" />
                      </div>
                    </div>
                    {/* End Icon */}
                    {/* Right Content */}
                    <div className="grow pt-0.5 pb-8">
                      <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                        <svg
                          className="flex-shrink-0 size-4 mt-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                          <polyline points="14 2 14 8 20 8" />
                          <line x1={16} x2={8} y1={13} y2={13} />
                          <line x1={16} x2={8} y1={17} y2={17} />
                          <line x1={10} x2={8} y1={9} y2={9} />
                        </svg>
                        Created "Preline in React" task
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        Find more detailed insctructions here.
                      </p>
                      <button
                        type="button"
                        className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                      >
                        <img
                          className="flex-shrink-0 size-4 rounded-full"
                          src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                          alt="Image Description"
                        />
                        James Collins
                      </button>
                    </div>
                    {/* End Right Content */}
                  </div>
                  {/* End Item */}
                  {/* Item */}
                  <div className="flex gap-x-3">
                    {/* Icon */}
                    <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                      <div className="relative z-10 size-7 flex justify-center items-center">
                        <div className="size-2 rounded-full bg-gray-400 " />
                      </div>
                    </div>
                    {/* End Icon */}
                    {/* Right Content */}
                    <div className="grow pt-0.5 pb-8">
                      <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                        Release v5.2.0 quick bug fix 🐞
                      </h3>
                      <button
                        type="button"
                        className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                      >
                        <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full ">
                          A
                        </span>
                        Alex Gregarov
                      </button>
                    </div>
                    {/* End Right Content */}
                  </div>
                  {/* End Item */}
                  {/* Item */}
                  <div className="flex gap-x-3">
                    {/* Icon */}
                    <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                      <div className="relative z-10 size-7 flex justify-center items-center">
                        <div className="size-2 rounded-full bg-gray-400 " />
                      </div>
                    </div>
                    {/* End Icon */}
                    {/* Right Content */}
                    <div className="grow pt-0.5 pb-8">
                      <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                        Marked "Install Charts" completed
                      </h3>
                      <p className="mt-1 text-sm text-gray-600 ">
                        Finally! You can check it out here.
                      </p>
                      <button
                        type="button"
                        className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                      >
                        <img
                          className="flex-shrink-0 size-4 rounded-full"
                          src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                          alt="Image Description"
                        />
                        James Collins
                      </button>
                    </div>
                    {/* End Right Content */}
                  </div>
                  {/* End Item */}
                  {/* Heading */}
                  <div className="ps-2 my-2">
                    <h3 className="text-xs font-medium uppercase text-gray-500 ">
                      31 Jul, 2023
                    </h3>
                  </div>
                  {/* End Heading */}
                  {/* Item */}
                  <div className="flex gap-x-3">
                    {/* Icon */}
                    <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                      <div className="relative z-10 size-7 flex justify-center items-center">
                        <div className="size-2 rounded-full bg-gray-400 " />
                      </div>
                    </div>
                    {/* End Icon */}
                    {/* Right Content */}
                    <div className="grow pt-0.5 pb-8">
                      <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                        Take a break ⛳️
                      </h3>
                      <p className="mt-1 text-sm text-gray-600 ">
                        Just chill for now... 😉
                      </p>
                    </div>
                    {/* End Right Content */}
                  </div>
                  {/* End Item */}
                  {/* Collapse */}
                  <div
                    id="hs-timeline-collapse"
                    className="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
                    aria-labelledby="hs-timeline-collapse-content"
                  >
                    {/* Heading */}
                    <div className="ps-2 my-2 first:mt-0">
                      <h3 className="text-xs font-medium uppercase text-gray-500 ">
                        30 Jul, 2023
                      </h3>
                    </div>
                    {/* End Heading */}
                    {/* Item */}
                    <div className="flex gap-x-3">
                      {/* Icon */}
                      <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                        <div className="relative z-10 size-7 flex justify-center items-center">
                          <div className="size-2 rounded-full bg-gray-400 " />
                        </div>
                      </div>
                      {/* End Icon */}
                      {/* Right Content */}
                      <div className="grow pt-0.5 pb-8">
                        <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                          Final touch ups
                        </h3>
                        <p className="mt-1 text-sm text-gray-600 ">
                          Double check everything and make sure we're ready to
                          go.
                        </p>
                      </div>
                      {/* End Right Content */}
                    </div>
                    {/* End Item */}
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
      <CreateGroups />
    </div>
  )
}

export default Groups
