import React from 'react'
import Header from '../../components/Header'
import SettingsSidebar from '../../components/SettingsSidebar'

function Settings() {
  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 space-y-4 flex flex-row gap-x-4 py-10 lg:py-0">
        <SettingsSidebar />
      </div>
    </div>
  )
}

export default Settings
