import React, { useState } from 'react'
import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
} from '@chakra-ui/react'
import { Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { Cog6ToothIcon } from '@heroicons/react/24/solid'

function AddAutomations() {
  const [addedAutomationsCount, setAddedAutomationsCount] = useState([])

  const [addedActionsCount, setAddedActionsCount] = useState([1])

  const [radioValue, setRadioValue] = React.useState('1')

  const [value, setValue] = useState([])
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // Add more options as needed
  ]

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <div className="w-full flex flex-row pb-10">
      <div className="hidden lg:block w-1/4 bg-gray-100 p-2 rounded-lg lg:p-4">
        <h1 className="font-bold p-2">Automations</h1>
        <div className="p-2">
          <ul className="space-y-2">
            <li>
              <Input value="Automation 1" />
            </li>
            <li>
              <Input value="Automation 2" />
            </li>
          </ul>
        </div>
        <Button> + Add Automation</Button>
      </div>
      <div className="flex flex-col w-full lg:w-3/4 ">
        <Button className="flex lg:hidden w-[4rem]" onClick={onOpen}>
          <Cog6ToothIcon className="w-4 h-4" />
        </Button>
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody className="my-8">
              <h1 className="font-bold p-2">Automations</h1>
              <div className="p-2">
                <ul className="space-y-2">
                  <li>
                    <Input value="Automation 1" />
                  </li>
                  <li>
                    <Input value="Automation 2" />
                  </li>
                </ul>
              </div>
              <Button> + Add Automation</Button>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue">Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        <div className="py-4 lg:px-4 space-y-4">
          <div className="flex flex-row space-x-2 items-center">
            <Button>IF</Button>
            <Select placeholder="This" className="w-full">
              <option value="utc">Step 1</option>
            </Select>
            <Select placeholder="Select" className="w-full">
              <option value="utc">is completed</option>
            </Select>
          </div>
          {addedAutomationsCount.map((count, index) => (
            <div key={index} className="pl-4">
              <div className="flex flex-row space-x-2 items-center">
                <Select placeholder="Choose" className="w-full">
                  <option value="and">And</option>
                  <option value="or">OR</option>
                </Select>
                <Select placeholder="This" className="w-full">
                  <option value={`step${index + 1}`}>{`Step ${
                    index + 1
                  }`}</option>
                </Select>
                <Select placeholder="Select" className="w-full">
                  <option value={`completed${index + 1}`}>{`is completed ${
                    index + 1
                  }`}</option>
                </Select>
              </div>
            </div>
          ))}
          <div className="flex ml-auto align-right justify-end space-x-2">
            <Button
              onClick={() =>
                setAddedAutomationsCount([
                  ...addedAutomationsCount,
                  addedAutomationsCount.length + 1,
                ])
              }
            >
              + ADD
            </Button>
            {addedAutomationsCount.length > 0 && (
              <Button
                onClick={() => {
                  const temp = [...addedAutomationsCount]
                  temp.pop()
                  setAddedAutomationsCount(temp)
                }}
              >
                - Remove
              </Button>
            )}
          </div>

          {addedActionsCount.map((count, index) => (
            <div className="space-y-2">
              <div className="flex flex-row space-x-2 items-center">
                <Button>THEN</Button>
                <RadioGroup onChange={setRadioValue} value={radioValue}>
                  <Stack direction={{ base: 'column', lg: 'row' }}>
                    <Radio className="p-2 rounded-lg border" value="1">
                      <h1 className="font-bold">Assignment Action</h1>
                      <p className="text-sm">Assign or Unassign</p>
                    </Radio>
                    <Radio className="p-2 rounded-lg border" value="2">
                      <h1 className="font-bold">Deadline Action</h1>
                      <p className="text-sm">Shift a deadline</p>
                    </Radio>
                    <Radio className="p-2 rounded-lg border" value="3">
                      <h1 className="font-bold">Status Action</h1>
                      <p className="text-sm">Re-open a step</p>
                    </Radio>
                    <Radio className="p-2 rounded-lg border" value="4">
                      <h1 className="font-bold">Visibility Action</h1>
                      <p className="text-sm">Show or Hide a step</p>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </div>
              <div
                hidden={radioValue != 1}
                className="space-y-2 p-4 bg-gray-100 rounded-lg"
              >
                <InputGroup>
                  <InputLeftAddon className="w-[6rem] mx-auto justify-center flex">
                    Apply to
                  </InputLeftAddon>
                  <Select
                    placeholder="Select a step"
                    className="w-full rounded-l-none"
                  >
                    <option value="utc">Step 1</option>
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon className="w-[6rem] mx-auto justify-center flex">
                    Action
                  </InputLeftAddon>
                  <Select
                    placeholder="Select action type"
                    className="w-full rounded-l-none"
                  >
                    <option value="assign">Assign</option>
                    <option value="assignOnly">Assign Only</option>
                  </Select>
                </InputGroup>

                <MultiSelect
                  options={options}
                  value={value}
                  label="Fallback Assignees"
                  onChange={setValue}
                />
              </div>
              <div
                hidden={radioValue != 2}
                className="space-y-2 p-4 bg-gray-100 rounded-lg"
              >
                <InputGroup>
                  <InputLeftAddon className="w-[6rem] mx-auto justify-center flex">
                    Apply to
                  </InputLeftAddon>
                  <Select
                    placeholder="Select a step"
                    className="w-full rounded-l-none"
                  >
                    <option value="utc">Step 1</option>
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon className="w-[6rem] mx-auto justify-center flex">
                    Action
                  </InputLeftAddon>
                  <Select
                    placeholder="Select action type"
                    className="w-full rounded-l-none"
                  >
                    <option value="assign">Shift Deadline</option>
                  </Select>
                </InputGroup>
                <div className="flex flex-row space-x-2">
                  {' '}
                  <Select placeholder="Select action type" className="w-full ">
                    <option value="assign">+ Plus</option>
                    <option value="assign">- Minus</option>
                  </Select>
                  <Input type="number" value="1" />
                  <Select placeholder="Select" className="w-full">
                    <option value="assign">Minutes</option>
                    <option value="assign">Hours</option>
                    <option value="assign">Days</option>
                    <option value="assign">Weeks</option>
                    <option value="assign">Months</option>
                  </Select>
                </div>
              </div>
              <div
                hidden={radioValue != 3}
                className="space-y-2 p-4 bg-gray-100 rounded-lg"
              >
                <InputGroup>
                  <InputLeftAddon className="w-[6rem] mx-auto justify-center flex">
                    Apply to
                  </InputLeftAddon>
                  <Select
                    placeholder="Select a step"
                    className="w-full rounded-l-none"
                  >
                    <option value="utc">Step 1</option>
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon className="w-[6rem] mx-auto justify-center flex">
                    Action
                  </InputLeftAddon>
                  <Select
                    placeholder="Select action type"
                    className="w-full rounded-l-none"
                  >
                    <option value="assign">Re-open</option>
                  </Select>
                </InputGroup>
              </div>
              <div
                hidden={radioValue != 4}
                className="space-y-2 p-4 bg-gray-100 rounded-lg"
              >
                <InputGroup>
                  <InputLeftAddon className="w-[6rem] mx-auto justify-center flex">
                    Apply to
                  </InputLeftAddon>
                  <Select
                    placeholder="Select a step"
                    className="w-full rounded-l-none"
                  >
                    <option value="utc">Step 1</option>
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon className="w-[6rem] mx-auto justify-center flex">
                    Action
                  </InputLeftAddon>
                  <Select
                    placeholder="Select action type"
                    className="w-full rounded-l-none"
                  >
                    <option value="assign">Show</option>
                    <option value="assignOnly">Hide</option>
                  </Select>
                </InputGroup>
              </div>
            </div>
          ))}
          <div className="flex ml-auto align-right justify-end space-x-2">
            <Button
              onClick={() =>
                setAddedActionsCount([
                  ...addedActionsCount,
                  addedActionsCount.length + 1,
                ])
              }
            >
              + ADD
            </Button>
            {addedActionsCount.length > 0 && (
              <Button
                onClick={() => {
                  const temp = [...addedActionsCount]
                  temp.pop()
                  setAddedActionsCount(temp)
                }}
              >
                - Remove
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAutomations
