import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Checkbox,
  useDisclosure,
} from '@chakra-ui/react';
import { PlayIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { openAddSnippetsModal, closeAddSnippetsModal } from '../redux/AddSnippetsModal.js';
import RichTextEditor from './utils/RichTextEditor'; // Import the new editor component

function AddSnippets() {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.addSnippetsModal.isModalOpen);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [htmlContent, setHtmlContent] = useState('<p>Initial <b>HTML</b></p>');
  const [title, setTitle] = useState('');
  const [isPublic, setIsPublic] = useState(false);

  useEffect(() => {
    // You can set initial HTML content here if needed
    setHtmlContent('<p>Initial <b>HTML</b></p>');
  }, []);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setIsPublic(e.target.checked);
  };

  const handleOpenModal = () => {
    dispatch(openAddSnippetsModal());
    onOpen();
  };

  const handleCloseModal = () => {
    dispatch(closeAddSnippetsModal());
    onClose();
  };

  const handleAddSnippets = () => {
    // Use the HTML content from the RichTextEditor
    console.log('HTML Content:', htmlContent);
    // Perform the action to add snippets with `htmlContent`
    handleCloseModal();
  };

  return (
    <>
      <Modal size="2xl" isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Snippets Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
            <Input
              type="text"
              placeholder="Title"
              value={title}
              onChange={handleTitleChange}
              required
            />
            <RichTextEditor
              value={htmlContent}
              onChange={(html) => setHtmlContent(html)} // Update state with the new HTML content
            />
            <Checkbox
              isChecked={isPublic}
              onChange={handleCheckboxChange}
            >
              Visible in Public Templates?
            </Checkbox>
          </ModalBody>

          <ModalFooter className="mt-8">
            <Button
              rightIcon={<PlayIcon className="w-4 h-4 ml-2" />}
              colorScheme="fray"
              mr={3}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              rightIcon={<UserGroupIcon className="w-4 h-4 ml-2" />}
              colorScheme="blue"
              onClick={handleAddSnippets}
            >
              Add Snippets
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddSnippets;
