// modalSlice.js
import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'guestsModal',
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    openCreateGuestModal: (state) => {
      state.isModalOpen = true
    },
    closeCreateGuestModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const {
  openCreateGuestModal,
  closeCreateGuestModal,
} = modalSlice.actions
export default modalSlice.reducer
