import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Textarea,
  useDisclosure,
  Box,
} from '@chakra-ui/react'
import { PlayIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  openCreateJobsModal,
  closeCreateJobsModal,
} from '../redux/CreateJobsModal'


function CreateJobs() {
  const dispatch = useDispatch()
  const isModalOpen = useSelector((state) => state.jobsModal.isModalOpen)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [value, setValue] = useState([])


  const handleOpenModal = () => {
    dispatch(openCreateJobsModal())
    // Additional logic, if needed
    onOpen()
  }

  const handleCloseModal = () => {
    dispatch(closeCreateJobsModal())
    // Additional logic, if needed
    onClose()
  }


  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Job Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
            <Input type="text" placeholder="Job name" required />
          </ModalBody>

          <ModalFooter className="mt-8">
            <Button
              rightIcon={<PlayIcon className="w-4 h-4 ml-2" />}
              colorScheme="fray"
              mr={3}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              rightIcon={<UserGroupIcon className="w-4 h-4 ml-2" />}
              colorScheme="blue"
              onClick={handleCloseModal} // You can dispatch further actions here
            >
              Create Job
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateJobs
