import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Header';
import SettingsSidebar from '../../../components/SettingsSidebar';
import {
  Button,
  Input,
  Avatar,
  Text,
  useToast
} from '@chakra-ui/react';
import { HexAlphaColorPicker } from "react-colorful";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { getCurrentOrganization, updateCurrentOrganization } from '../../../redux/OrganizationSlice';

function Branding() {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  
  const organization = useSelector((state) => state.organization.data);
  const isLoading = useSelector((state) => state.organization.isLoading);
  const successMessage = useSelector((state) => state.organization.successMessage);
  const errorMessage = useSelector((state) => state.organization.errorMessage);

  const [logo, setLogo] = useState('');
  const [buttonColor, setButtonColor] = useState('#000000');
  const [logoPreview, setLogoPreview] = useState('');

  useEffect(() => {
    if (!Cookies.get('role') || Cookies.get('role') === 'member' || Cookies.get('role') === 'admin') {
      navigate('/signin');
    } else {
      dispatch(getCurrentOrganization());
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (organization) {
      setButtonColor(organization.buttonColor || '#000000');
      if (organization.logo) {
        setLogoPreview(organization.logo);
      }
    }
  }, [organization]);

  useEffect(() => {
    if (successMessage) {
      toast({
        title: 'Success',
        description: successMessage,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }

    if (errorMessage) {
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successMessage, errorMessage, toast]);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file); // Store the file object
      setLogoPreview(URL.createObjectURL(file)); // Create a preview URL for the file
    }
  };
  
  const handleSaveChanges = () => {
    const formData = new FormData();
    formData.append('logo', logo);  // Attach the logo file
    formData.append('buttonColor', buttonColor);
  
    Cookies.set('buttonColor', buttonColor);
    dispatch(updateCurrentOrganization(formData))
    .then(() => {
      // Optional: Add a success message or perform other actions
      window.location.reload(); // Reload after the update action is fulfilled
    })
    .catch((error) => {
      // Optional: Handle errors here
      console.error('Error updating organization:', error);
    });
  };
  

  return (
    <div className="overflow-hidden max-h-screen">
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row py-12 lg:py-0">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="flex flex-col space-y-2 items-start pb-4">
            <div className="mx-auto">
              <div className="mb-6">
                <h2 className="text-lg font-semibold">Customize Logo</h2>
                <div className="flex items-center justify-between p-4 border rounded-md">
                  <div className="flex items-center space-x-2">
                    <TextIcon className="text-blue-500" />
                    <div>
                      <Button className="text-sm" variant="secondary">
                        CHANGE LOGO
                      </Button>
                      <Input type="file" onChange={handleLogoChange} className="border-none" />
                      <p className="text-xs text-gray-500">
                        We recommend jpg format - 154px by 56px
                      </p>
                    </div>
                  </div>
                  <Avatar src={logoPreview} />
                </div>
              </div>
              <div className="mb-6">
                <h2 className="text-lg font-semibold">
                  Customize Header & Sidebar Color
                </h2>
                <div className="flex items-center justify-between p-4 border rounded-md">
                  <HexAlphaColorPicker className="mx-auto" color={buttonColor} onChange={setButtonColor} />
                </div>
              </div>
              <div className="flex justify-between mb-6">
                <Button
                  className="bg-green-500 text-white"
                  onClick={handleSaveChanges}
                  isLoading={isLoading}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Branding;

function TextIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 6.1H3" />
      <path d="M21 12.1H3" />
      <path d="M15.1 18H3" />
    </svg>
  );
}
