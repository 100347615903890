// modalSlice.js
import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'createTaskModal',
  initialState: {
    isModalOpen: false,
    searchTask: ""
  },
  reducers: {
    openCreateTaskModal: (state, action) => {
      state.isModalOpen = true
      state.searchTask = action.payload
    },
    closeCreateTaskModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const {
  openCreateTaskModal,
  closeCreateTaskModal,
} = modalSlice.actions
export default modalSlice.reducer
