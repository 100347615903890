import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  InputGroup,
  Text,
} from '@chakra-ui/react'
import { Select } from '@chakra-ui/react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'

function CreatePreset({
  createPresetIsOpen,
  createPresetOnOpen,
  createPresetOnClose,
}) {
  const [taskType, setTaskType] = useState('all')

  const [value, setValue] = useState([])
  const [totalCriterias, setTotalCriterias] = useState([1])

  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // Add more options as needed
  ]
  return (
    <>
      <Modal
        size={'2xl'}
        isOpen={createPresetIsOpen}
        onClose={createPresetOnClose}
      >
        <ModalOverlay />
        <ModalContent className="w-full">
          <ModalHeader>Create Preset</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            zIndex={10}
            className="flex flex-col mx-auto justify-center items-center space-y-4 w-full"
          >
            <InputGroup>
              <Input type="text" placeholder="Name this view" />
            </InputGroup>
            <InputGroup className="flex">
              <Text>Show processes and forms that match </Text>
              <div className="w-[5rem] mx-2">
                <Select
                  onChange={(e) => setTaskType(e.target.value)}
                  placeholder="Search for active processes"
                  className="w-[full]"
                >
                  <option value="all">All</option>

                  <option value="any">Any</option>
                </Select>{' '}
              </div>
              <Text>of the criteria below.</Text>
            </InputGroup>
            {totalCriterias.map((el, i) => (
              <InputGroup className="space-x-4  bg-gray-100 p-4 rounded-lg">
                <div className="w-1/3 space-x-4  space-y-4">
                  {' '}
                  <Select
                    onChange={(e) => setTaskType(e.target.value)}
                    placeholder="Filter"
                  >
                    <option value="all">Assigned</option>
                    <option value="tags">Tags</option>
                    <option value="templates">Templates</option>
                    <option value="processState">Process State</option>
                    <option value="type">Type</option>
                  </Select>{' '}
                </div>

                <div className="w-2/3 space-y-4">
                  <div className="flex flex-row items-center">
                    to{' '}
                    <Popover>
                      <PopoverTrigger>
                        <Button className="ml-4 text-xs lg:text-md">
                          Select Co-Workers/Groups
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Tabs>
                            <TabList>
                              <Tab className="w-full">Members</Tab>
                              <Tab className="w-full">Groups</Tab>
                            </TabList>

                            <TabPanels>
                              <TabPanel>
                                <MultiSelect
                                  options={options}
                                  value={value}
                                  label="Choose members"
                                  onChange={setValue}
                                />
                              </TabPanel>
                              <TabPanel>
                                <MultiSelect
                                  options={options}
                                  value={value}
                                  label="Choose groups"
                                  onChange={setValue}
                                />
                              </TabPanel>
                            </TabPanels>
                          </Tabs>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </div>
                  <div className="flex flex-row items-center ">
                    And
                    <Select
                      onChange={(e) => setTaskType(e.target.value)}
                      placeholder="Filter"
                      className="ml-2"
                    >
                      <option value="all">All</option>
                      <option value="completed">Completed</option>
                      <option value="active">Active</option>
                    </Select>{' '}
                  </div>
                </div>
              </InputGroup>
            ))}
            <div className="space-x-2 flex flex-row">
              {' '}
              <div
                onClick={() => setTotalCriterias([...totalCriterias, 1])}
                className="border p-2 rounded-md"
              >
                Add Criteria +
              </div>{' '}
              <div
                hidden={totalCriterias <= 1}
                onClick={() => setTotalCriterias([...totalCriterias.slice(0, totalCriterias.length-1)])}
                className="border p-2 rounded-md"
              >
                Remove Criteria -
              </div>
            </div>
          </ModalBody>

          <ModalFooter zIndex={1} className="mt-8">
            <Button colorScheme="blue" mr={3}>
              Create Preset
            </Button>
            <Button onClick={createPresetOnClose} colorScheme="gray">Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreatePreset
