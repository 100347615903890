import React, { useState, useRef, useEffect } from 'react'
import Header from '../../../components/Header'
import SettingsSidebar from '../../../components/SettingsSidebar'
import { Box, Button, Code, Heading, Text, useToast } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '../../../redux/UserSlice'



function Integrations() {
  const dispatch = useDispatch();
  const toast = useToast();
  const [currentUser, setCurrentUser] = useState({});
  const { isLoading, data, isError, errorMessage, successMessage } = useSelector((state) => state.currentUser);

  useEffect(() => {
    if (data.length === 0) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, data.length]);

  useEffect(() => {
    if (data.length > 0) {
      setCurrentUser(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  
    if (successMessage) {
      toast({
        title: "Success",
        description: successMessage,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      dispatch({ type: 'currentUser/clearSuccessMessage' });
    }
  }, [isError, errorMessage, successMessage, toast, dispatch]);

  const TemplatePage = () => {
    return (
<Box p="6" maxWidth="900px" mx="auto">
  <Heading as="h1" mb="6">API Documentation</Heading>

  <Heading as="h2" size="md" mb="4">1. Authentication</Heading>
  <Text>
    To authenticate your requests, you need to include your API key in the request headers.
  </Text>
  <Code p="2" mt="2" display="block">
    api-key: {currentUser?._id}
  </Code>

  <Heading as="h2" size="md" mt="6" mb="4">2. Endpoint</Heading>
  <Text>The API endpoint to launch a procedure is a POST request:</Text>
  <Code p="2" mt="2" display="block">
    POST https://tallyfy-backend.vercel.app/integration/templates/procedures/launch
  </Code>

  <Heading as="h2" size="md" mt="6" mb="4">3. Request Headers</Heading>
  <Text>In addition to the API key, include the following headers:</Text>
  <Code p="2" mt="2" display="block">
    Content-Type: application/json
  </Code>

  <Heading as="h2" size="md" mt="6" mb="4">4. Request Body</Heading>
  <Text>The request body should be in JSON format. Example:</Text>
  <Code p="2" mt="2" display="block" whiteSpace="pre">
    {`{
  "templateId": "template123",
  "name": "Template name",
  "kickOffForm": {
    "forms": {
      "assignSomeoneElse": true,
      "assignTo": ["email1@example.com", "email2@example.com"]
    }
  }
}`}
  </Code>
  <Text mt="2">
    <Code>assignSomeoneElse</Code> should mandatorily include <Code>assignTo</Code> values. 
    If <Code>assignSomeoneElse</Code> is set to <Code>true</Code> but <Code>assignTo</Code> is not provided, 
    it should be set to <Code>false</Code>, and pre-defined template assignees will be allotted.
  </Text>
  <Text mt="2">
    Note: If <Code>autoNameProcess</Code> is false or not set, the procedure name will be required.
  </Text>

  <Heading as="h2" size="md" mt="6" mb="4">5. Example cURL Request</Heading>
  <Text>Example of how to make the request using cURL:</Text>
  <Code p="2" mt="2" display="block" whiteSpace="pre">
    {`curl -X POST https://tallyfy-backend.vercel.app/integration/templates/procedures/launch \\
  -H "Content-Type: application/json" \\
  -H "api-key: your_api_key_here" \\
  -d '{
    "templateId": "template123",
    "name": "LAUNCHED",
    "kickOffForm": {
      "forms": {
        "assignSomeoneElse": true,
        "assignTo": ["email1@example.com", "email2@example.com"]
      }
    }
  }'`}
  </Code>

  <Heading as="h2" size="md" mt="6" mb="4">6. Response</Heading>
  <Text>A successful response will return a 201 status code with the following message:</Text>
  <Code p="2" mt="2" display="block">
    {`{
  "message": "Procedure launched successfully"
}`}
  </Code>

  <Button mt="8" colorScheme="blue">Contact Support</Button>
</Box>

      
    )
  }

  const router = useNavigate()

  useEffect(() => {
    if (Cookies.get('role') == 'owner' || Cookies.get('role') == 'member') {
      return router('/signin')
    }
  }, [])


  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row  py-12 lg:py-0 ">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <TemplatePage />
        </div>
      </div>
    </div>
  )
}

export default Integrations
