import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import 'aos/dist/aos.css'

import AOS from 'aos'

import Home from './pages/Home'
import PageNotFound from './pages/PageNotFound'
import Signin from './pages/Signin'
import Signup from './pages/Signup'
import ForgotPassword from './pages/ForgotPassword'
import Dashboard from './pages/Dashboard/Dashboard'
import Tracker from './pages/Dashboard/Tracker'
import Tasks from './pages/Dashboard/Tasks'
import Settings from './pages/Dashboard/Settings'
import Profile from './pages/Dashboard/Settings/Profile'
import Personalization from './pages/Dashboard/Settings/Personalization'
import EmailNotifications from './pages/Dashboard/Settings/EmailNotifications'
import Details from './pages/Dashboard/Settings/Details'
import Customizations from './pages/Dashboard/Settings/Customizations'
import Members from './pages/Dashboard/Settings/Members'
import Guests from './pages/Dashboard/Settings/Guests'
import Groups from './pages/Dashboard/Settings/Groups'
import JobTitles from './pages/Dashboard/Settings/JobTitles'
import Home2 from './pages/Dashboard/Home2'
import Tags from './pages/Dashboard/Settings/Tags'
import EmailSettings from './pages/Dashboard/Settings/EmailSettings'
import Branding from './pages/Dashboard/Settings/Branding'
import Snippets from './pages/Dashboard/Settings/Snippets'
import OrgPersonalization from './pages/Dashboard/Settings/OrgPersonalization'
import Domains from './pages/Dashboard/Settings/Domains'
import Billing from './pages/Dashboard/Settings/Billing'
import Integrations from './pages/Dashboard/Settings/Integrations'
import Compliance from './pages/Dashboard/Settings/Compliance'
import Templates from './pages/Dashboard/Templates'
import CreateProcedure from './pages/Dashboard/Templates/CreateProcedure'
import CreateDocument from './pages/Dashboard/Templates/CreateDocument'
import Contact from './pages/Contact'
import TrackerView from './pages/Dashboard/Tracker/TrackerView'
import UserManagement from './pages/Dashboard/Admin/UserManagement'

function App() {
  const location = useLocation()

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    })
  })

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]) // triggered on route change

  return (
    <>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/home" element={<Home2 />} />
        <Route path="/dashboard/tracker/:id" element={<TrackerView />} />
        <Route path="/dashboard/tracker" element={<Tracker />} />
        <Route path="/dashboard/tasks" element={<Tasks />} />
        <Route path="/dashboard/admin" element={<UserManagement />} />
        <Route path="/dashboard/settings" element={<Settings />} />
        <Route path="/dashboard/settings/profile" element={<Profile />} />
        <Route
          path="/dashboard/settings/personalization"
          element={<Personalization />}
        />
        <Route path="/dashboard/settings/details" element={<Details />} />
        <Route path="/dashboard/settings/members" element={<Members />} />
        <Route path="/dashboard/settings/guests" element={<Guests />} />
        <Route path="/dashboard/settings/groups" element={<Groups />} />
        <Route path="/dashboard/settings/jobTitles" element={<JobTitles />} />
        <Route path="/dashboard/settings/tags" element={<Tags />} />
        <Route
          path="/dashboard/settings/emailSettings"
          element={<EmailSettings />}
        />
        <Route path="/dashboard/settings/branding" element={<Branding />} />
        <Route path="/dashboard/settings/snippets" element={<Snippets />} />
        <Route
          path="/dashboard/settings/orgPersonalization"
          element={<OrgPersonalization />}
        />
        <Route path="/dashboard/settings/domains" element={<Domains />} />
        <Route path="/dashboard/settings/billing" element={<Billing />} />
        <Route
          path="/dashboard/settings/integrations"
          element={<Integrations />}
        />
        <Route path="/dashboard/settings/compliance" element={<Compliance />} />
        <Route path="/dashboard/templates" element={<Templates />} />
        <Route
          path="/dashboard/templates/create/procedure/:id"
          element={<CreateProcedure />}
        />
        <Route
          path="/dashboard/templates/create/document/:title"
          element={<CreateDocument />}
        />
        <Route
          path="/dashboard/settings/customizations"
          element={<Customizations />}
        />
        <Route
          path="/dashboard/settings/emailNotifications"
          element={<EmailNotifications />}
        />
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default App
