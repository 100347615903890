import Cookies from 'js-cookie'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchTemplates = createAsyncThunk(
  'templates/fetchTemplates',
  async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      },
    )

    if (!res.ok) {
      const error = await res.json()
      throw new Error(error.message || 'Failed to fetch templates')
    }

    return res.json()
  },
)

export const addTemplate = createAsyncThunk(
  'templates/addTemplate',
  async (templateData) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
        body: JSON.stringify(templateData),
      },
    )

    if (!res.ok) {
      const error = await res.json()
      throw new Error(error.message || 'Failed to add template')
    }

    return res.json()
  },
)

export const updateTemplate = createAsyncThunk(
  'templates/updateTemplate',
  async (templateData, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/${templateData._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
          body: JSON.stringify(templateData),
        }
      );

      if (!res.ok) {
        const error = await res.json();
        console.log("Error", error);
        return rejectWithValue({ status: res.status, message: error.message || 'Failed to update template' });
      }

      const data = await res.json();
      console.log("Data:", data);
      return data.message;
    } catch (error) {
      console.log("Catch Error:", error);
      return rejectWithValue({ status: 500, message: error.message || 'An error occurred' });
    }
  }
);

export const updateTemplateWithoutPull = createAsyncThunk(
  'templates/updateTemplateWithoutPull',
  async (templateData, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/${templateData._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
          body: JSON.stringify(templateData),
        }
      );

      if (!res.ok) {
        const error = await res.json();
        console.log("Error", error);
        return rejectWithValue({ status: res.status, message: error.message || 'Failed to update template' });
      }

      const data = await res.json();
      console.log("Data:", data);
      return data.message;
    } catch (error) {
      console.log("Catch Error:", error);
      return rejectWithValue({ status: 500, message: error.message || 'An error occurred' });
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  'templates/deleteTemplate',
  async (templateData) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/${templateData._id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      },
    )

    if (!res.ok) {
      const error = await res.json()
      throw new Error(error.message || 'Failed to fetch templates')
    }

    return res.json()
  },
)

export const addStep = createAsyncThunk(
  'templates/addStep',
  async (stepData, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/steps`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
          body: JSON.stringify(stepData),
        },
      )

      if (!res.ok) {
        const error = await res.json()
        return rejectWithValue(error.message || 'Failed to add step')
      }

      const data = await res.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to add step')
    }
  },
)

export const updateSteps = createAsyncThunk(
  'templates/updateSteps',
  async (steps, { rejectWithValue }) => {
    try {
      const updatedSteps = []
      console.log(steps)
      // Iterate over each step and send a PUT request
      for (const step of steps) {
        console.log(step)

        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/steps/${step._id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Cookies.get('token')}`,
            },
            body: JSON.stringify(step),
          },
        )

        if (!res.ok) {
          const error = await res.json()
          return rejectWithValue(error.message || 'Failed to update step')
        }

        const updatedStep = await res.json()
        updatedSteps.push(updatedStep)
      }

      return { steps: updatedSteps }
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update steps')
    }
  },
)

export const deleteStep = createAsyncThunk(
  'templates/deleteStep',
  async (stepData, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/steps/${stepData._id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        },
      );

      if (!res.ok) {
        const error = await res.json();
        return rejectWithValue(error.message || 'Failed to delete step');
      }

      return stepData; // Return stepData so we can use it to remove the step from the state
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to delete step');
    }
  }
);


export const addComment = createAsyncThunk('addComment', async (data) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/templates/procedures/steps/${data.stepId}/comments`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify({ text: data.text }),
    },
  )
  return res.json()
})

const templateSlice = createSlice({
  name: 'templates',
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    errorMessage: '',
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplates.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = action.payload
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(addTemplate.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(addTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.data.push(action.payload.procedure) // Add the new task to the state
      })
      .addCase(addTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(updateTemplate.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        const updatedTemplateIndex = state.data.findIndex(
          (template) => template._id === action.payload._id,
        )
        if (updatedTemplateIndex !== -1) {
          state.data[updatedTemplateIndex] = action.payload // Update the task in the state
        }
      })
      .addCase(updateTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(updateTemplateWithoutPull.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(updateTemplateWithoutPull.fulfilled, (state, action) => {
        state.isLoading = false
        const updatedTemplateIndex = state.data.findIndex(
          (template) => template._id === action.payload._id,
        )
        console.log(
          'updatedTemplateIndex',
          updatedTemplateIndex,
          action.payload,
        )
      })
      .addCase(updateTemplateWithoutPull.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(deleteTemplate.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        console.log(action.payload, 'action.payload deletTemplate')
        const deleteTemplateIndex = state.data.findIndex(
          (template) => template._id === action.payload._id,
        )
        console.log(deleteTemplateIndex, action.payload._id)

        if (deleteTemplateIndex != -1) {
          state.data.splice(deleteTemplateIndex, 1)
        }
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
      .addCase(addStep.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.errorMessage = ''
      })
      .addCase(addStep.fulfilled, (state, action) => {
        state.isLoading = false
        console.log(action.payload, 'action.payload')
        const updatedStepIndex = state.data.findIndex(
          (template) => template._id === action.payload.templateId,
        )
        if (updatedStepIndex !== -1) {
          console.log(action.payload.step)
          state.data[updatedStepIndex].steps.push(action.payload.step) // Insert comment at the beginning of the array
        }
      })
      .addCase(addStep.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.error.message
      })
    builder.addCase(addComment.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(addComment.fulfilled, (state, action) => {
      state.isLoading = false
      const templateIndex = state.data.findIndex(
        (template) => template._id === action.payload.step.templateId,
      )
      if (templateIndex !== -1) {
        const stepIndex = state.data[templateIndex].steps.findIndex(
          (step) => step._id === action.payload.step._id,
        )
        if (stepIndex !== -1) {
          state.data[templateIndex].steps[stepIndex] = action.payload.step
        }
      }
    })
    builder.addCase(addComment.rejected, (state) => {
      state.isError = true
    })
    builder.addCase(updateSteps.pending, (state) => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = ''
    })
    builder.addCase(updateSteps.fulfilled, (state, action) => {
      state.isLoading = false
      const { steps } = action.payload
      const templateIndex = state.data.findIndex(
        (template) => template._id === steps.templateId,
      )
      if (templateIndex !== -1) {
        state.data[templateIndex].steps = steps // Update the steps in the state
      }
    })
    builder.addCase(updateSteps.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
    .addCase(deleteStep.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = '';
    })
    .addCase(deleteStep.fulfilled, (state, action) => {
      state.isLoading = false;
      const { _id, templateId } = action.payload;
      const templateIndex = state.data.findIndex(
        (template) => template._id === templateId
      );
      if (templateIndex !== -1) {
        const stepIndex = state.data[templateIndex].steps.findIndex(
          (step) => step._id === _id
        );
        if (stepIndex !== -1) {
          state.data[templateIndex].steps.splice(stepIndex, 1); // Remove the step from the array
        }
      }
    })
    .addCase(deleteStep.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });
  },
})

export default templateSlice.reducer
