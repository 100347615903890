// modalSlice.js
import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'jobsModal',
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    openCreateJobsModal: (state) => {
      state.isModalOpen = true
    },
    closeCreateJobsModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const {
  openCreateJobsModal,
  closeCreateJobsModal,
} = modalSlice.actions
export default modalSlice.reducer
