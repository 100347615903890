import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import { Badge, Button, Input, Select, Spinner, useDisclosure } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { StarIcon } from '@heroicons/react/24/outline'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Box,
} from '@chakra-ui/react'
import {
  openCreateTemplateModal,
  closeCreateTemplateModal,
} from '../../redux/CreateTemplateModal'
import {
  openTemplateLaunchModal,
  closeTemplateLaunchModal,
} from '../../redux/TemplateLaunchModal'
import { useDispatch, useSelector } from 'react-redux'
import CreateTemplate from '../../components/CreateTemplate'
import TemplateLaunch from '../../components/TemplateLaunch'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import {
  ArrowsPointingOutIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  FolderIcon,
} from '@heroicons/react/24/solid'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import {
  openCreateTaskModal,
  closeCreateTaskModal,
} from '../../redux/CreateTaskModal'
import { useNavigate } from 'react-router-dom'
import {
  addTemplate,
  deleteTemplate,
  fetchTemplates,
  updateTemplate,
  updateTemplateWithoutPull,
} from '../../redux/TemplateSlice'
import Cookies from 'js-cookie'

const DraggableItem = ({
  index,
  data,
  setData,
  moveItem,
  showDetails,
  folderList,
  setFormData,
}) => {
  const el = data[index]

  return (
    <div className="pb-4">
      <ItemContent
        el={el}
        index={index}
        data={data}
        setData={setData}
        showDetails={showDetails}
        folderList={folderList}
        setFormData={setFormData}
      />
    </div>
  )
}

const ItemContent = ({
  el,
  index,
  data,
  setData,
  showDetails,
  folderList,
  setFormData,
}) => {
  // Extracted item content for better readability

  const router = useNavigate()
  const dispatch = useDispatch()

  const handleToggleFavorite = (e, data) => {
    e.preventDefault()
    dispatch(updateTemplate({ _id: data._id, isFavourite: !data.isFavourite }))
  }

  if (!el || !el.name) {
    return null
  } else {
    return (
      <>
        <div className="flex items-center justify-between px-4 py-2 border  flex-col md:flex-row">
          <div className="flex flex-row items-center space-x-4 pb-2">
            <div className="flex items-center space-x-4 flex-col md:flex-row">
              <h2 className="text-lg font-medium">{el.name}</h2>
              <Badge variant="secondary">{el.type}</Badge>
            </div>
          </div>
          <div className="flex space-x-2 items-center">
            <StarIcon
              fill={el.isFavourite ? '#FFC923' : 'white'}
              className="w-6 h-6"
              onClick={(e) => handleToggleFavorite(e, el)}
            />
            <div hidden={Cookies.get('role') == "member"} className="flex flex-row">
              <Button
                onClick={() =>
                  router(`/dashboard/templates/create/procedure/${el._id}`)
                }
                className="rounded-r-none"
                variant="outline"
              >
                Edit
              </Button>
              <Menu>
                <MenuButton
                  className="rounded-l-none border-l-none"
                  as={Button}
                >
                  <ChevronDownIcon className="w-4 h-4" />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() =>
                      dispatch(
                        updateTemplate({
                          _id: el._id,
                          isArchived: !el.isArchived,
                        }),
                      )
                    }
                  >
                    {el.isArchived ? 'Unarchive' : 'Archive'}
                  </MenuItem>
                  <Menu>
                    <MenuButton>
                      <MenuItem>Move to</MenuItem>
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={() =>
                          dispatch(updateTemplate({ _id: el._id, folder: '' }))
                        }
                        hidden={
                          el.folder == '' || el.folder == null || !el.folder
                        }
                      >
                        Uncategorized
                      </MenuItem>
                      {folderList.map((folder, i) => (
                        <MenuItem
                          hidden={folder == el.folder}
                          onClick={() =>
                            dispatch(
                              updateTemplate({ _id: el._id, folder: folder }),
                            )
                          }
                          key={i}
                        >
                          {folder}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                  <MenuItem
                    hidden={!el.isArchived}
                    onClick={() =>
                      dispatch(
                        deleteTemplate({
                          _id: el._id,
                        }),
                      )
                    }
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>

            <Button
              onClick={() =>
                router(`/dashboard/templates/create/procedure/${el._id}`)
              }
              variant="outline"
            >
              View
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault()
                setFormData(el)
                dispatch(openTemplateLaunchModal())
              }}
            >
              Launch
            </Button>
          </div>
        </div>
        <div hidden={!showDetails} className="p-4 bg-gray-200 rounded-lg">
          <DetailsContent el={el} />
        </div>
      </>
    )
  }
}

const DetailsContent = ({ el }) => {
  // Extracted details content for better readability
  return (
    <div className="bg-white p-6 shadow rounded-lg">
      <div className="flex flex-col lg:flex-row justify-between items-center">
        <div className="flex items-center space-x-4">
          <label htmlFor="status-toggle">Status</label>
          <Select
            id="status-toggle"
            placeholder="Status"
            className="w-full rounded-l-none"
            value={el.status}
          >
            <option value="started">Started</option>
            <option value="inProgress">In Progress</option>
            <option value="completed">Almost Complete</option>
            <option value="completed">Complete</option>
          </Select>
        </div>
        <div className="text-left lg:text-right">
          <div className="font-medium">{el.steps?.length} Steps</div>
          <div>Launched {el.launched} times</div>
        </div>
      </div>
      <div className="mt-4 border-t pt-4">
        <div className="flex justify-between">
          <div>
            <div className="text-sm text-gray-600">Created</div>
            <div className="font-medium">
              {el.createdAt?.split('T')[0]} by {el.createdBy}
            </div>
          </div>
          <div>
            <div className="text-sm text-gray-600">Last Updated</div>
            <div className="font-medium">{el.updatedAt?.split('T')[0]}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Templates() {
  const [data, setData] = useState([])
  const [formData, setFormData] = useState()
  const [filteredData, setFilteredData] = useState(data)
  const [filteredData2, setFilteredData2] = useState(data)
  const [filteredData3, setFilteredData3] = useState(data)

  const [showDetails, setShowDetails] = useState(false)
  const [showActivity, setShowActivity] = useState(false)
  const [showingMode, setShowingMode] = useState(null)

  const [searchFolders, setSearchFolders] = useState('')
  const [selectedFolder, setSelectedFolder] = useState('Uncategorized')
  const [tempFolderName, setTempFolderName] = useState('')
  const [folderList, setFolderList] = useState([])
  const [filteredFolderList, setFilteredFolderList] = useState(folderList)

  const [filterTemplates, setFilterTemplates] = useState('all')
  const [sortTemplates, setSortTemplates] = useState('recent')

  const [isLoading, setIsLoading] = useState(true)
  // isFavourite, archived, example, folder

  const moveItem = (fromIndex, toIndex) => {
    const newData = [...data]
    const [movedItem] = newData.splice(fromIndex, 1)
    newData.splice(toIndex, 0, movedItem)
    setData(newData)
  }

  const favoriteCount = data.filter((item) => item.isFavourite)?.length

  const dispatch = useDispatch()

  const state = useSelector((state) => state)
  useEffect(() => {
    dispatch(fetchTemplates())
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (state?.templates?.data?.length > 0) {
      console.log(state?.templates?.data)
      setData(state?.templates?.data)
    } else {
      setData([])
    }
  }, [state?.templates?.data])

  useEffect(() => {
    const temp = data?.reduce((acc, el) => {
      if (el?.folder?.length > 0) {
        acc.push(el.folder)
      }
      return acc
    }, [])
    console.log(temp)

    const uniqueFolders = [...new Set(temp)]
    setFolderList(uniqueFolders)

    setFilteredFolderList(uniqueFolders)
    console.log('uniqueFolders', uniqueFolders)
  }, [dispatch, data])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure()

  const toast = useToast()

  useEffect(() => {
    if (showingMode === 'isFavourite') {
      const favorite = data.filter((item) => item.isFavourite)
      setFilteredData(favorite)
    } else if (showingMode === 'archived') {
      const archived = data.filter((item) => item.isArchived)
      setFilteredData(archived)
    } else if (showingMode === 'example') {
      const example = data.filter((item) => item.isExample)
      setFilteredData(example)
    } else {
      if (
        selectedFolder == null ||
        selectedFolder == '' ||
        selectedFolder == 'Uncategorized'
      ) {
        const uncategorized = data.filter(
          (item) =>
            (item.folder == null || item.folder == '' || !item.folder) &&
            !item.isArchived,
        )
        setFilteredData(uncategorized)
      } else {
        const folderData = data.filter(
          (item) => item.folder == selectedFolder && !item.isArchived,
        )
        setFilteredData(folderData)
      }
    }

    console.log('filteredData', filteredData)
  }, [showingMode, selectedFolder, data, folderList])

  useEffect(() => {
    if (filterTemplates == 'all') {
      setFilteredData2(filteredData)
    } else if (filterTemplates == 'procedure') {
      setFilteredData2(
        filteredData.filter((item, i) => item.type == 'Procedure'),
      )
    } else if (filterTemplates == 'document') {
      setFilteredData2(
        filteredData.filter((item, i) => item.type == 'Document'),
      )
    } else {
      setFilteredData2(filteredData.filter((item, i) => item.type == 'Form'))
    }
  }, [filteredData, filterTemplates])

  useEffect(() => {
    const sortData = () => {
      let sortedData
      if (sortTemplates == 'recent') {
        sortedData = [...filteredData2].sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
        )
      } else if (sortTemplates == 'popular') {
        sortedData = [...filteredData2].sort((a, b) => b.launched - a.launched)
      } else if (sortTemplates == 'a-z') {
        sortedData = [...filteredData2].sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        )
      } else {
        sortedData = [...filteredData2].sort((a, b) =>
          b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
        )
      }
      setFilteredData3(sortedData)
    }

    sortData()
  }, [filteredData2, sortTemplates])

  useEffect(() => {
    if (searchFolders?.length > 0 && folderList?.length > 0) {
      const temp = folderList.filter((item) =>
        item.toLowerCase().includes(searchFolders.toLowerCase()),
      )
      setFilteredFolderList(temp)
    } else {
      setFilteredFolderList(folderList)
    }
  }, [searchFolders, folderList])

  return (
    <div className="overflow-x-hidden min-h-screen">
      <Modal isCentered isOpen={isLoading}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
          className="items-center flex justify-center"
        >
          <Spinner size="xl" />
        </ModalOverlay>
      </Modal>{' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 py-10 space-y-4 flex flex-row gap-x-4">
        <aside className="w-1/6 bg-white p-6 hidden lg:block">
          <Popover>
            <PopoverTrigger>
              <Button  hidden={Cookies.get('role') == "member"}className="mb-4">+ Create</Button>
            </PopoverTrigger>
            <Portal>
              <Box zIndex="popover" w="full" h="full" position={'relative'}>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody className="space-y-4">
                    <div
                      className="cursor-pointer"
                      onClick={() => dispatch(openCreateTemplateModal())}
                    >
                      <h1 className="font-bold">Create Template</h1>
                      <p>Like a recipe.</p>
                    </div>

                    {/* <div onClick={() => dispatch(openCreateTaskModal())}>
                      <h1 className="font-bold">Request a Template</h1>
                      <p>From someone else.</p>
                    </div> */}
                  </PopoverBody>
                </PopoverContent>
              </Box>
            </Portal>
          </Popover>

          <nav className="mb-6">
            <Link
              onClick={() => setShowActivity(!showActivity)}
              className="block mb-2"
              href="#"
            >
              Activity
            </Link>
            <Link
              onClick={() => {
                setShowActivity(false)
                setShowingMode('isFavourite')
              }}
              className="block mb-2"
              href="#"
            >
              Favorites ({favoriteCount})
            </Link>
            <Link
              onClick={() => {
                setShowActivity(false)
                setShowingMode('example')
              }}
              className="block mb-2"
              href="#"
            >
              Examples ({data?.filter((item) => item.isExample)?.length || 0})
            </Link>
            <Link
              onClick={() => {
                setShowActivity(false)
                setShowingMode('archived')
              }}
              className="block"
              href="#"
            >
              Archived ({data?.filter((item) => item.isArchived)?.length || 0})
            </Link>
          </nav>
          <h3 className="font-bold text-md underline py-2">Folders</h3>
          <Input
            placeholder="Search..."
            type="text"
            onChange={(e) => setSearchFolders(e.target.value)}
            value={searchFolders}
          />
          <Button
            onClick={() => {
              setShowActivity(false)
              setShowingMode('folder')
              setSelectedFolder('Uncategorized')
            }}
            className="mt-2 w-full"
            variant={selectedFolder == 'Uncategorized' ? 'solid' : 'outline'}
          >
            Uncategorized
          </Button>
          {filteredFolderList.map((folder, i) => (
            <Button
              onClick={() => {
                setShowActivity(false)
                setShowingMode('folder')
                setSelectedFolder(folder)
              }}
              className="mt-2 w-full"
              variant={selectedFolder == folder ? 'solid' : 'outline'}
              key={i}
            >
              {folder}
            </Button>
          ))}
        </aside>

        <main hidden={showActivity} className="w-4/5 flex-1 p-2 md:p-6">
          <Cog6ToothIcon
            onClick={onOpen2}
            className="w-6 h-6 block lg:hidden"
          />
          <div className="flex justify-between items-center mb-6 flex-col md:flex-row gap-2">
            <h1 className="text-xl font-semibold">Templates</h1>
            <div className="flex flex-col space-y-2 ">
              <div className="flex flex-row gap-2 grid grid-cols-2">
                <Select
                  value={filterTemplates}
                  onChange={(e) => setFilterTemplates(e.target.value)}
                  placeholder="Filter Templates"
                  className="w-full"
                >
                  <option value="all">Show All Templates</option>
                  <option value="procedure">Procedure</option>
                  <option value="form">Form</option>
                  <option value="document">Document</option>
                </Select>

                <Select
                  value={sortTemplates}
                  onChange={(e) => setSortTemplates(e.target.value)}
                  placeholder="Sort Templates"
                  className="w-full"
                >
                  <option value="recent">Recently Updated</option>
                  <option value="popular">Most Popular</option>
                  <option value="a-z">Sort A-Z</option>
                  <option value="z-a">Sort Z-A</option>
                </Select>
              </div>
              <div className="gap-2 grid grid-cols-3">
                <Button hidden={Cookies.get('role') == "member"} onClick={onOpen} variant="outline">
                  + New Folder
                </Button>
                <Button
                  onClick={() => setShowDetails(!showDetails)}
                  variant="outline"
                >
                  {showDetails ? 'Hide Details' : 'Show Details'}
                </Button>
                <Button
                  onClick={() =>
                    toast({
                      title: 'Link Copied!',
                      description: 'Share link copied to clipboard.',
                      status: 'success',
                      duration: 9000,
                      isClosable: true,
                    })
                  }
                  variant="outline"
                >
                  Share
                </Button>
              </div>
            </div>
          </div>
          <div className="bg-white p-2 rounded-md">
            {data && data.length > 0
              ? data.map((el, i) => (
                  <DraggableItem
                    key={i}
                    index={i}
                    data={filteredData3}
                    showDetails={showDetails}
                    moveItem={moveItem}
                    setData={setFilteredData}
                    folderList={folderList}
                    setFormData={setFormData}
                  />
                ))
              : null}
            <Popover>
              <PopoverTrigger>
                <Button  hidden={Cookies.get('role') == "member"} className="mt-4">+ Create</Button>
              </PopoverTrigger>
              <Portal>
                <Box zIndex="popover" w="full" h="full" position={'relative'}>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody className="space-y-4">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          dispatch(openCreateTemplateModal())
                          onClose2()
                        }}
                      >
                        <h1 className="font-bold">Create Template</h1>
                        <p>Like a recipe.</p>
                      </div>

                      {/* <div onClick={() => dispatch(openCreateTaskModal())}>
                        <h1 className="font-bold">Request a Template</h1>
                        <p>From someone else.</p>
                      </div> */}
                    </PopoverBody>
                  </PopoverContent>
                </Box>
              </Portal>
            </Popover>
          </div>
        </main>

        <main hidden={!showActivity} className="w-5/6 flex-1 p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-xl font-semibold">Templates</h1>
          </div>
          <div className="bg-white p-2 rounded-md">
            <div>
              {/* Heading */}
              <div className="ps-2 my-2 first:mt-0">
                <h3 className="text-xs font-medium uppercase text-gray-500">
                  1 Aug, 2023
                </h3>
              </div>
              {/* End Heading */}
              {/* Item */}
              <div className="flex gap-x-3">
                {/* Icon */}
                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-gray-400" />
                  </div>
                </div>
                {/* End Icon */}
                {/* Right Content */}
                <div className="grow pt-0.5 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                    <svg
                      className="flex-shrink-0 size-4 mt-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                      <polyline points="14 2 14 8 20 8" />
                      <line x1={16} x2={8} y1={13} y2={13} />
                      <line x1={16} x2={8} y1={17} y2={17} />
                      <line x1={10} x2={8} y1={9} y2={9} />
                    </svg>
                    Created "Preline in React" task
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Find more detailed insctructions here.
                  </p>
                  <button
                    type="button"
                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                  >
                    <img
                      className="flex-shrink-0 size-4 rounded-full"
                      src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                      alt="Image Description"
                    />
                    James Collins
                  </button>
                </div>
                {/* End Right Content */}
              </div>
              {/* End Item */}
              {/* Item */}
              <div className="flex gap-x-3">
                {/* Icon */}
                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-gray-400 " />
                  </div>
                </div>
                {/* End Icon */}
                {/* Right Content */}
                <div className="grow pt-0.5 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                    Release v5.2.0 quick bug fix 🐞
                  </h3>
                  <button
                    type="button"
                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full ">
                      A
                    </span>
                    Alex Gregarov
                  </button>
                </div>
                {/* End Right Content */}
              </div>
              {/* End Item */}
              {/* Item */}
              <div className="flex gap-x-3">
                {/* Icon */}
                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-gray-400 " />
                  </div>
                </div>
                {/* End Icon */}
                {/* Right Content */}
                <div className="grow pt-0.5 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                    Marked "Install Charts" completed
                  </h3>
                  <p className="mt-1 text-sm text-gray-600 ">
                    Finally! You can check it out here.
                  </p>
                  <button
                    type="button"
                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    <img
                      className="flex-shrink-0 size-4 rounded-full"
                      src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                      alt="Image Description"
                    />
                    James Collins
                  </button>
                </div>
                {/* End Right Content */}
              </div>
              {/* End Item */}
              {/* Heading */}
              <div className="ps-2 my-2">
                <h3 className="text-xs font-medium uppercase text-gray-500 ">
                  31 Jul, 2023
                </h3>
              </div>
              {/* End Heading */}
              {/* Item */}
              <div className="flex gap-x-3">
                {/* Icon */}
                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-gray-400 " />
                  </div>
                </div>
                {/* End Icon */}
                {/* Right Content */}
                <div className="grow pt-0.5 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                    Take a break ⛳️
                  </h3>
                  <p className="mt-1 text-sm text-gray-600 ">
                    Just chill for now... 😉
                  </p>
                </div>
                {/* End Right Content */}
              </div>
              {/* End Item */}
              {/* Collapse */}
              <div
                id="hs-timeline-collapse"
                className="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-timeline-collapse-content"
              >
                {/* Heading */}
                <div className="ps-2 my-2 first:mt-0">
                  <h3 className="text-xs font-medium uppercase text-gray-500 ">
                    30 Jul, 2023
                  </h3>
                </div>
                {/* End Heading */}
                {/* Item */}
                <div className="flex gap-x-3">
                  {/* Icon */}
                  <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                    <div className="relative z-10 size-7 flex justify-center items-center">
                      <div className="size-2 rounded-full bg-gray-400 " />
                    </div>
                  </div>
                  {/* End Icon */}
                  {/* Right Content */}
                  <div className="grow pt-0.5 pb-8">
                    <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                      Final touch ups
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 ">
                      Double check everything and make sure we're ready to go.
                    </p>
                  </div>
                  {/* End Right Content */}
                </div>
                {/* End Item */}
              </div>
            </div>
          </div>
        </main>
      </div>
      <CreateTemplate />
      <TemplateLaunch formData={formData} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={tempFolderName}
              onChange={(e) => setTempFolderName(e.target.value)}
              placeholder="Type a name for this Folder"
            />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              {' '}
              Close
            </Button>
            <Button
              onClick={() => {
                if (tempFolderName) {
                  setFolderList([...folderList, tempFolderName])
                  onClose()
                }
              }}
              colorScheme="blue"
              ml={3}
            >
              Create Folder
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer size="xs" isOpen={isOpen2} placement="left" onClose={onClose2}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <aside className="w-full bg-white py-4">
              <Popover>
                <PopoverTrigger>
                  <Button hidden={Cookies.get('role') == "member"} className="mb-4">+ Create</Button>
                </PopoverTrigger>
                <Portal>
                  <Box zIndex="popover" w="full" h="full" position={'relative'}>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody className="space-y-4">
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            dispatch(openCreateTemplateModal())
                            onClose2()
                          }}
                        >
                          <h1 className="font-bold">Create Template</h1>
                          <p>Like a recipe.</p>
                        </div>

                        {/* <div onClick={() => dispatch(openCreateTaskModal())}>
                          <h1 className="font-bold">Request a Template</h1>
                          <p>From someone else.</p>
                        </div> */}
                      </PopoverBody>
                    </PopoverContent>
                  </Box>
                </Portal>
              </Popover>

              <nav className="mb-6">
                <Link
                  onClick={() => {
                    setShowActivity(!showActivity)
                    onClose2()
                  }}
                  className="block mb-2"
                  href="#"
                >
                  Activity
                </Link>
                <Link
                  onClick={() => {
                    setShowActivity(false)
                    setShowingMode('isFavourite')
                    onClose2()
                  }}
                  className="block mb-2"
                  href="#"
                >
                  Favorites ({favoriteCount})
                </Link>
                <Link
                  onClick={() => {
                    setShowActivity(false)
                    setShowingMode('example')
                    onClose2()
                  }}
                  className="block mb-2"
                  href="#"
                >
                  Examples
                </Link>
                <Link
                  onClick={() => {
                    setShowActivity(false)
                    setShowingMode('archived')
                    onClose2()
                  }}
                  className="block"
                  href="#"
                >
                  Archived
                </Link>
              </nav>
              <h3 className="font-bold text-md underline py-2">Folders</h3>
              <Input
                placeholder="Search..."
                type="text"
                onChange={(e) => setSearchFolders(e.target.value)}
                value={searchFolders}
              />
              <Button
                onClick={() => {
                  setShowActivity(false)
                  setShowingMode('folder')
                  setSelectedFolder('Uncategorized')
                  onClose2()
                }}
                className="mt-2 w-full"
                variant={
                  selectedFolder == 'Uncategorized' ? 'solid' : 'outline'
                }
              >
                Uncategorized
              </Button>
              {filteredFolderList.map((folder, i) => (
                <Button
                  onClick={() => {
                    setShowActivity(false)
                    setShowingMode('folder')
                    setSelectedFolder(folder)
                    onClose2()
                  }}
                  className="mt-2 w-full"
                  variant={selectedFolder == folder ? 'solid' : 'outline'}
                  key={i}
                >
                  {folder}
                </Button>
              ))}
            </aside>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  )
}

export default Templates
