import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunk to get the current organization
export const getCurrentOrganization = createAsyncThunk('getCurrentOrganization', async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/organization`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
  });
  if (!res.ok) {
    throw new Error('Failed to fetch organization');
  }
  return res.json();
});

export const updateCurrentOrganization = createAsyncThunk('updateCurrentOrganization', async (formData) => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/organization`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Cookies.get('token')}`, // No need for 'Content-Type' in FormData requests
    },
    body: formData,
  });
  if (!res.ok) {
    const error = await res.json();
    throw new Error(error.error || 'Failed to update organization');
  }

  return res.json();
});


const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    isLoading: false,
    data: null, // Single organization object
    isError: false,
    errorMessage: '',
    successMessage: '', // Added for success messages
  },
  reducers: {
    clearSuccessMessage: (state) => {
      state.successMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentOrganization.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.successMessage = ''; // Reset success message
      })
      .addCase(getCurrentOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.company;
      })
      .addCase(getCurrentOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message; // Set error message
      })
      .addCase(updateCurrentOrganization.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.successMessage = ''; // Reset success message
      })
      .addCase(updateCurrentOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.company;
        console.log(action.payload.company.logo, "BRAND LOGO UPDATED")
        Cookies.set('brandLogo', encodeURIComponent(action.payload.company.logo))
        state.successMessage = 'Organization updated successfully'; // Set success message
      })
      .addCase(updateCurrentOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message; // Set error message
      });
  },
});


export default organizationSlice.reducer;
