import React, { useEffect } from 'react'
import Header from '../../../components/Header'
import SettingsSidebar from '../../../components/SettingsSidebar'
import { Button } from '@chakra-ui/react'
import { DataGrid2 } from '../../../components/utils/DataGrid.tsx'
import { useDispatch, useSelector } from 'react-redux'
import { openAddTagsModal } from '../../../redux/AddTagsModal'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import AddTags from '../../../components/AddTags'
import { getCurrentOrganization, updateCurrentOrganization } from '../../../redux/OrganizationSlice';

function Tags() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Get organization data from the store
  const organization = useSelector((state) => state.organization.data)
  const tags = organization?.tags || []

  useEffect(() => {
    if (Cookies.get('role') == 'admin' || Cookies.get('role') == 'member') {
      navigate('/signin')
    } else {
      // Fetch organization data on component mount
      dispatch(getCurrentOrganization())
    }
  }, [dispatch, navigate])

  const rows = tags.map((tag, index) => ({
    id: index + 1,
    Title: tag,
  }))

  const columns = [
    { field: 'Title', headerName: 'Title', width: 150 },
  ]

  return (
    <div className="overflow-hidden max-h-screen">
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row py-12 lg:py-0">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="flex flex-col space-y-2 items-start pb-4">
            <Button variant="ghost" onClick={() => dispatch(openAddTagsModal())}>
              + Create new tags
            </Button>
          </div>
          <div style={{ maxWidth: '100vw', display: 'flex', justifyItems: 'center' }}>
            <DataGrid2 rows={rows} columns={columns} />
          </div>
        </div>
      </div>
      <AddTags />
    </div>
  )
}

export default Tags
