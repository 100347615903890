import { useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react'
import { Cog6ToothIcon } from '@heroicons/react/24/solid'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import Cookies from 'js-cookie'

function SettingsSidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const router = useNavigate()

  const location = useLocation()
  const [defaultIndex, setDefaultIndex] = useState(null);

  useEffect(() => {
    console.log("Current Path:", location.pathname);
    switch (location.pathname) {
      case '/dashboard/settings/profile':
      case '/dashboard/settings/personalization':
      case '/dashboard/settings/emailNotifications':
        setDefaultIndex(0);
        break;
      case '/dashboard/settings/details':
      case '/dashboard/settings/customizations':
      case '/dashboard/settings/members':
      case '/dashboard/settings/guests':
      case '/dashboard/settings/groups':
      case '/dashboard/settings/jobTitles':
      case '/dashboard/settings/tags':
      case '/dashboard/settings/emailSettings':
      case '/dashboard/settings/branding':
      case '/dashboard/settings/snippets':
      case '/dashboard/settings/orgPersonalization':
      case '/dashboard/settings/domains':
        setDefaultIndex(1);
        break;
      default:
        setDefaultIndex(null);
    }
  }, [location.pathname]);
  
  useEffect(() => {
    console.log("Default Index:", defaultIndex);
  }, [defaultIndex]);
  

  const userRole = Cookies.get('role')
  return (
    <>
   <div className="hidden lg:block w-[12rem] rounded-lg bg-gray-50 pb-12 h-[90vh] left p-2 space-y-4 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
      <Accordion key={defaultIndex} defaultIndex={defaultIndex} allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                My Account
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Button onClick={() => router('/dashboard/settings/profile')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Profile
            </Button>
            <Button onClick={() => router('/dashboard/settings/personalization')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Personalization
            </Button>
            <Button onClick={() => router('/dashboard/settings/emailNotifications')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Email Notifications
            </Button>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem hidden={userRole == 'admin' || userRole == 'member'}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Organization
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel className="flex flex-col" pb={4}>
            <Button onClick={() => router('/dashboard/settings/details')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Details
            </Button>
            <Button onClick={() => router('/dashboard/settings/customizations')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Customizations
            </Button>
            {/* <Button onClick={() => router('/dashboard/settings/members')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Members
            </Button>
            <Button onClick={() => router('/dashboard/settings/guests')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Guests
            </Button>
            <Button onClick={() => router('/dashboard/settings/groups')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Groups
            </Button>
            <Button onClick={() => router('/dashboard/settings/jobTitles')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Job Titles
            </Button> */}
            <Button onClick={() => router('/dashboard/settings/tags')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Tags
            </Button>
            {/* <Button onClick={() => router('/dashboard/settings/emailSettings')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Email Settings
            </Button> */}
            <Button onClick={() => router('/dashboard/settings/branding')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Branding
            </Button>
            {/* <Button onClick={() => router('/dashboard/settings/snippets')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Snippets
            </Button> */}
            {/* <Button onClick={() => router('/dashboard/settings/orgPersonalization')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Personalization
            </Button> */}
            {/* <Button onClick={() => router('/dashboard/settings/domains')} size="sm" className="p-1 m-0 w-fit" variant={'ghost'}>
              Domains
            </Button> */}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <div hidden={userRole == 'admin' || userRole == 'member'} className="flex flex-col space-y-4">
        <Button size={'sm'}>
          Billing
        </Button>
        <Button onClick={() => router('/dashboard/settings/integrations')} size={'sm'}>
          Integration
        </Button>
        {/* <Button onClick={() => router('/dashboard/settings/compliance')} size={'sm'}>
          Compliance
        </Button> */}
      </div>
    </div>
      <Button className="block lg:hidden w-fit" onClick={onOpen}>
        <Cog6ToothIcon className="h-4 w-4" />
      </Button>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody className="my-10">
            <Accordion defaultIndex={defaultIndex}  className="" allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      My Account
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Button
                    onClick={() => router('/dashboard/settings/profile')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Profile
                  </Button>
                  <Button
                    onClick={() =>
                      router('/dashboard/settings/personalization')
                    }
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Personalization
                  </Button>
                  <Button
                    onClick={() =>
                      router('/dashboard/settings/emailNotifications')
                    }
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Email Notifications
                  </Button>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Organization
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="flex flex-col" pb={4}>
                  <Button
                    onClick={() => router('/dashboard/settings/details')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Details
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/customizations')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Customizations
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/members')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Members
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/guests')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Guests
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/groups')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Groups
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/jobTitles')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Job Titles
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/tags')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Tags
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/emailSettings')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Email Settings
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/branding')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Branding
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/snippets')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Snippets
                  </Button>
                  <Button
                    onClick={() =>
                      router('/dashboard/settings/orgPersonalization')
                    }
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Personalization
                  </Button>
                  <Button
                    onClick={() => router('/dashboard/settings/domains')}
                    size="sm"
                    className="p-1 m-0 w-fit"
                    variant={'ghost'}
                  >
                    Domains
                  </Button>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <div className="flex flex-col space-y-4">
              <Button
                onClick={() => router('/dashboard/settings/billing')}
                size={'sm'}
              >
                Billing
              </Button>
              <Button
                onClick={() => router('/dashboard/settings/integrations')}
                size={'sm'}
              >
                Integration
              </Button>
              <Button
                onClick={() => router('/dashboard/settings/compliance')}
                size={'sm'}
              >
                Compliance
              </Button>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SettingsSidebar
