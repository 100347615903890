import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Header';
import SettingsSidebar from '../../../components/SettingsSidebar';
import {
  Button,
  Switch,
  useToast,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { getCurrentOrganization, updateCurrentOrganization } from '../../../redux/OrganizationSlice';

function Customizations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  
  const organization = useSelector((state) => state.organization.data);
  const isLoading = useSelector((state) => state.organization.isLoading);
  const successMessage = useSelector((state) => state.organization.successMessage);
  const errorMessage = useSelector((state) => state.organization.errorMessage);

  const [autoArchive, setAutoArchive] = useState(false);

  useEffect(() => {
    if (!Cookies.get('role') || Cookies.get('role') === 'member' || Cookies.get('role') === 'admin') {
      navigate('/signin');
    } else {
      dispatch(getCurrentOrganization());
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (organization) {
      setAutoArchive(organization.autoArchiveCompletedTasks || false);
    }
  }, [organization]);

  useEffect(() => {
    if (successMessage) {
      toast({
        title: 'Success',
        description: successMessage,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }

    if (errorMessage) {
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successMessage, errorMessage, toast]);

  const handleSaveChanges = () => {
    const organizationData = {
      autoArchiveCompletedTasks: autoArchive,
    };

    dispatch(updateCurrentOrganization(organizationData));
  };

  return (
    <div className="overflow-hidden max-h-screen">
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row  py-12 lg:py-0">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="mx-auto my-10 space-y-6">
            <div className="flex items-center justify-between">
              <label className="font-medium">
                Auto-archive processes where all tasks are completed?
              </label>
              <Switch
                id="auto-archive"
                isChecked={autoArchive}
                onChange={(e) => setAutoArchive(e.target.checked)}
              />
            </div>

            <div className="flex justify-between mb-6">
              <Button
                className="bg-green-500 text-white"
                onClick={handleSaveChanges}
                isLoading={isLoading}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customizations;
