import React from 'react';
import { Box, Heading, Text, VStack, Code } from '@chakra-ui/react';

const ApiDocs = () => {
  return (
    <Box p={4} maxW="800px" mx="auto">
      <Heading mb={4}>Create User API Documentation</Heading>
      <VStack spacing={4} align="start">
        <Heading size="md">Endpoint</Heading>
        <Text>
          <Code>POST {process.env.REACT_APP_BACKEND_URL}/api/admin/users</Code>
        </Text>
        
        <Heading size="md">Description</Heading>
        <Text>
          This endpoint creates a new user in the system.
        </Text>

        <Heading size="md">Request Headers</Heading>
        <Text>
          <Code>Authorization: Bearer &lt;token&gt;</Code>
        </Text>

        <Heading size="md">Request Body</Heading>
        <Text>Fields:</Text>
        <VStack align="start" spacing={1}>
          <Text>
            <Code>fName</Code> (string) - Required. First name of the user.
          </Text>
          <Text>
            <Code>lName</Code> (string) - Required. Last name of the user.
          </Text>
          <Text>
            <Code>email</Code> (string) - Required. Email address of the user.
          </Text>
          <Text>
            <Code>password</Code> (string) - Required. Password for the user account.
          </Text>
          <Text>
            <Code>role</Code> (string) - Optional. Role of the user. Must be 'member' if created by owner.
          </Text>
          <Text>
            <Code>companyId</Code> (string) - Optional. Organization Id. Automatically set for admin users.
          </Text>
          <Text>
            <Code>avatar</Code> (string) - Optional. URL for the user's avatar image.
          </Text>
          <Text>
            <Code>isDeactivated</Code> (boolean) - Optional. Status of the user account. Default is false.
          </Text>
        </VStack>

        <Heading size="md">Response</Heading>
        <Text>
          On success:
        </Text>
        <VStack spacing={1} align="start">
          <Text>
            Status Code: <Code>201 Created</Code>
          </Text>
          <Text>
            Response Body: The created user's details.
          </Text>
        </VStack>
        <Text>
          On error:
        </Text>
        <VStack spacing={1} align="start">
          <Text>
            Status Code: <Code>400 Bad Request</Code>
          </Text>
          <Text>
            Response Body: Error message detailing what went wrong.
          </Text>
        </VStack>
        
        <Heading size="md">Example Request</Heading>
        <Text>
          <Code>
            curl -X POST {`<API_URL>/api/admin/users`} \\
            -H "Authorization: Bearer &lt;token&gt;" \\
            -H "Content-Type: application/json" \\
            -d '{`{ "fName": "John", "lName": "Doe", "email": "john.doe@example.com", "password": "password123", "role": "member" }`}
          </Code>
        </Text>

        <Heading size="md">Example Response</Heading>
        <Text>
          <Code>
            {`{
  "id": "12345",
  "fName": "John",
  "lName": "Doe",
  "email": "john.doe@example.com",
  "role": "member",
  "companyId": "123456789",
  "avatar": "http://example.com/avatar.jpg",
  "isDeactivated": false
}`}
          </Code>
        </Text>
      </VStack>
    </Box>
  );
};

export default ApiDocs;
