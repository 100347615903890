import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import { useToast } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

function ForgotPassword() {
  const [data, setData] = useState({
    email: '',
    password: '',
    otp: '',
  })

  const [disabled, setDisabled] = useState(false)
  const [step, setStep] = useState(1)

  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    if (Cookies.get('token') && Cookies.get('email')) {
      return navigate('/dashboard/home')
    }
  }, [])

  const handleSubmit1 = async (e) => {
    e.preventDefault()

    if (data.email.includes('.') && data.email.includes('@')) {
      setDisabled(true)
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/resetPassword1`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: data.email,
          }),
        },
      )

      const res2 = await res.json()

      if (res2.error) {
        toast({
          title: res2.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else if (res2.message) {
        setStep(2)
        toast({
          title: 'OTP Sent!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Something went wrong!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      setDisabled(false)
    } else {
      toast({
        title: 'Something went wrong!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const handleSubmit2 = async (e) => {
    e.preventDefault()

    if (
      data.email.includes('.') &&
      data.email.includes('@') &&
      data.otp.length == 6 &&
      data.password.length > 2
    ) {
      setDisabled(true)
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/resetPassword2`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: data.email,
            otp: data.otp,
            password: data.password,
          }),
        },
      )

      const res2 = await res.json()

      if (res2.error) {
        toast({
          title: res2.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else if (res2.message) {
        toast({
          title: 'Password Reset Successfull!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        navigate('/signin')
      } else {
        toast({
          title: 'Something went wrong!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    } else {
      toast({
        title: 'Something went wrong!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    setDisabled(false)
  }

  return (
    <div>
      <Navbar />
      <main className="w-full max-w-md mx-auto p-6">
        <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                Forgot password?
              </h1>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Remember your password?
                <a
                  className="text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  href="/signin"
                >
                  Sign in here
                </a>
              </p>
            </div>
            <div className="mt-5">
              {/* Form */}
              <div className="grid gap-y-4">
                {/* Form Group */}
                <div
                  hidden={
                    step == 2 &&
                    (data.email.includes('@') || data.email.includes('.'))
                  }
                >
                  <label
                    htmlFor="email"
                    className="block text-sm mb-2 dark:text-white"
                  >
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required=""
                      aria-describedby="email-error"
                      value={data.email}
                      onChange={(e) => {
                        setData({ ...data, email: e.target.value })
                      }}
                    />
                    <div className="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                      <svg
                        className="size-5 text-red-500"
                        width={16}
                        height={16}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                    </div>
                  </div>
                  <p
                    className="hidden text-xs text-red-600 mt-2"
                    id="email-error"
                  >
                    Please include a valid email address so we can get back to
                    you
                  </p>
                </div>

                <div hidden={step == 1}>
                  <label
                    htmlFor="otp"
                    className="block text-sm mb-2 dark:text-white"
                  >
                    OTP
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      id="otp"
                      name="otp"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required=""
                      aria-describedby="otp-error"
                      value={data.otp}
                      onChange={(e) => {
                        setData({ ...data, otp: e.target.value })
                      }}
                    />
                    <div className="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                      <svg
                        className="size-5 text-red-500"
                        width={16}
                        height={16}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                    </div>
                  </div>
                  <p
                    className="hidden text-xs text-red-600 mt-2"
                    id="email-error"
                  >
                    Please include the OTP sent over your email.
                  </p>
                </div>
                <div hidden={step == 1}>
                  <label
                    htmlFor="password"
                    className="block text-sm mb-2 dark:text-white"
                  >
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required=""
                      aria-describedby="password-error"
                      value={data.password}
                      onChange={(e) => {
                        setData({ ...data, password: e.target.value })
                      }}
                    />
                    <div className="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                      <svg
                        className="size-5 text-red-500"
                        width={16}
                        height={16}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                    </div>
                  </div>
                  <p
                    className="hidden text-xs text-red-600 mt-2"
                    id="email-error"
                  >
                    Please include a password you'll remember
                  </p>
                </div>

                {/* End Form Group */}
                <button
                  hidden={step == 1}
                  onClick={(e) => setStep(1)}
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  Back
                </button>
                <button
                  onClick={(e) =>
                    step == 1 ? handleSubmit1(e) : handleSubmit2(e)
                  }
                  disabled={disabled}
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  Reset password
                </button>
              </div>
              {/* End Form */}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ForgotPassword
