import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'

// Import jQuery
import $ from 'jquery'
import { Button } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { updateTemplateWithoutPull } from '../../redux/TemplateSlice'
import { useToast } from '@chakra-ui/react'
import { unwrapResult } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

window.jQuery = $
window.$ = $

require('jquery-ui-sortable')
require('formBuilder')

const FormBuilder = ({ formData, data, setData }) => {
  const fb = useRef()
  const toast = useToast()
  const dispatch = useDispatch()

  useEffect(() => {
    // Initialize the form builder
    $(fb.current).formBuilder({ formData, disabledAttrs: ["access"] })
  }, []) // Add formData to the dependency array to update on formData changes

  const handleSave = async () => {
    // Get updated forms from formBuilder
    const updatedForms = await $(fb.current).formBuilder('getData', 'json') || "[{}]";
  
    // Handle async logic before calling setData
    const isFormUnchanged = data && data.kickOffForm && data.kickOffForm.forms && formData === data.kickOffForm.forms; // Assuming you have access to current `data`
    
    if (isFormUnchanged) {
      try {
        // Dispatch the action outside setData
        const resultAction = await dispatch(
          updateTemplateWithoutPull({
            ...data,
            kickOffForm: {
              ...data.kickOffForm,
              forms: updatedForms,
            },
          })
        );
  
        // Unwrap the result from the action
        const message = unwrapResult(resultAction);
  
        // Show success toast
        toast({
          title: 'Form Updated!',
          description: message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
  
        // After successful dispatch, update the state
        setData((prevData) => ({
          ...prevData,
          kickOffForm: {
            ...prevData.kickOffForm,
            forms: updatedForms,
          },
        }));
      } catch (err) {
        // Handle dispatch errors
        console.error("Dispatch Error:", err);
        const errorStatus = err?.status || 'Error';
        const errorMessage = err?.message || 'An unknown error occurred';
  
        toast({
          title: errorStatus === 401 ? 'Unauthorized' : 'Error',
          description: errorMessage,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      console.log("UPDATING STEP DATA", updatedForms)
      // If forms have changed, directly update the state without dispatch
      await setData((prevData) => ({
        ...prevData,
        forms: updatedForms
      }));
    }
  };
  

  return (
    <>
      <div id="fb-editor" ref={fb} />
      <Button disabled={Cookies.get('role') == "member"} onClick={handleSave}>Save Form</Button>
    </>
  )
}

export default FormBuilder
