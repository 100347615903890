import React from 'react'
import Header from '../../components/Header'

function Dashboard() {
  return (
    <div className='w-full'>
      <Header />
      Dashboard
    </div>
  )
}

export default Dashboard
