import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Button,
  Input,
  useToast,
} from '@chakra-ui/react'
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import {
  addComment,
  addStep,
  fetchTemplates,
  updateSteps,
} from '../redux/TemplateSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'

const TemplateStep = ({
  _id,
  setTrigger,
  setStepData,
  steps,
  setSteps,
  milestones,
  setMilestones,
}) => {
  const [stepName, setStepName] = useState('')

  const toast = useToast()

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    // Dropped outside a droppable area
    if (!destination) {
      return
    }

    const sourceOrder = steps.find((step) => step._id === draggableId).order
    console.log(result)
    const destinationOrder =
      steps.find(
        (step) =>
          step.milestone === destination.droppableId &&
          step.order === destination.index + 1,
      )?.order || null
    const sourceMilestone = source.droppableId
    const destinationMilestone = destination.droppableId
    console.log(
      sourceOrder,
      destinationOrder,
      sourceMilestone,
      destinationMilestone,
    )

    const updatedSteps = steps.map((step) => ({ ...step }))
    console.log('updatedSteps', updatedSteps)
    const movedStep = updatedSteps.find((step) => step._id === draggableId)

    // Move step within the same milestone
    if (sourceMilestone === destinationMilestone) {
      updatedSteps.forEach((step) => {
        if (step.milestone === sourceMilestone) {
          if (source.index < destination.index) {
            // Move steps down in order between source and destination
            if (step.order > sourceOrder && step.order <= destinationOrder) {
              step.order -= 1
            }
          } else if (source.index > destination.index) {
            // Move steps up in order between source and destination
            if (step.order >= destinationOrder && step.order < sourceOrder) {
              step.order += 1
            }
          }
        }
      })

      // Update the order of the moved step
      const milestoneSteps = updatedSteps.filter(
        (step) => step.milestone === sourceMilestone,
      )
      const numStepsInMilestone = milestoneSteps.length
      console.log(movedStep, milestoneSteps)
      movedStep.order =
        (destination.index + 1) % numStepsInMilestone || numStepsInMilestone
    } else {
      // Move step to a different milestone
      movedStep.milestone = destinationMilestone

      // Update order for all steps in the destination milestone
      const stepsInDestinationMilestone = updatedSteps.filter(
        (step) => step.milestone === destinationMilestone,
      )
      stepsInDestinationMilestone.forEach((step, index) => {
        step.order = index + 1
      })

      // Adjust order of steps in the source milestone
      const stepsInSourceMilestone = updatedSteps.filter(
        (step) => step.milestone === sourceMilestone,
      )
      stepsInSourceMilestone.forEach((step, index) => {
        step.order = index + 1
      })
    }

    setSteps(updatedSteps)
    dispatch(updateSteps(updatedSteps))
  }

  const [data, setData] = useState([])
  const [folderList, setFolderList] = useState([])
  const [filteredFolderList, setFilteredFolderList] = useState(folderList)

  

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const state = useSelector((state) => state)

  useEffect(() => {
    dispatch(fetchTemplates())
    const temp = state?.templates?.data?.reduce((acc, el) => {
      if (el?.folder?.length > 0) {
        acc.push(el.folder)
      }
      return acc
    }, [])

    const uniqueFolders = [...new Set(temp)]
    setFolderList(uniqueFolders)

    setFilteredFolderList(uniqueFolders)
    console.log('uniqueFolders', uniqueFolders)
  }, [dispatch, addStep, addComment])

  useEffect(() => {
    if (state?.templates?.data?.length > 0) {
      console.log(state?.templates?.data)
      setData(state?.templates?.data?.filter((el, i) => el._id == _id)[0])
      setSteps(
        state?.templates?.data?.filter((el, i) => el._id == _id)[0]?.steps,
      )
    } else {
      setData([])
    }
  }, [state?.templates?.data])

  useEffect(() => {
    // Extract unique milestones from steps, excluding "noMilestone" and falsy values
    if (steps && steps.length > 0) {
      setMilestones([
        ...new Set(
          steps
            .map((step) => step.milestone)
            .filter((milestone) => milestone && milestone !== 'noMilestone'),
        ),
      ])
      console.log('steps', steps)
    }
  }, [steps])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Accordion allowMultiple>
        {milestones.map((milestone) => (
          <AccordionItem key={milestone}>
            <Droppable droppableId={milestone}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <h2>
                    <AccordionButton className="bg-gray-200 rounded-lg">
                      <Text className="flex text-lg py-2 px-2">
                        {milestone}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {/* Filter and sort steps by order */}
                    {steps
                      .filter((step) => step.milestone === milestone)
                      .sort((a, b) => a.order - b.order) // Sort by order
                      .map((step, index) => (
                        <Draggable
                          key={step._id}
                          draggableId={step._id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="cursor-pointer flex flex-col text-left lg:flex-row items-center p-4 border rounded-lg text-blue-500 mb-2"
                              onClick={() => {
                                setStepData(step)
                                setTrigger('stepView')
                              }}
                            >
                              <h1 className="font-bold flex-1">{step.name}</h1>
                              {/* <div>
                                <Button variant="ghost">
                                  {step?.days + ' days' || ''}
                                </Button>
                                <Button variant="ghost">+ Assign</Button>
                                <Button variant="ghost">Tasks</Button>
                                <Button variant="ghost">
                                  <ChatBubbleOvalLeftIcon className="w-6 h-6" />
                                </Button>
                              </div> */}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </AccordionPanel>
                </div>
              )}
            </Droppable>
          </AccordionItem>
        ))}
      </Accordion>
      <Droppable droppableId="noMilestone">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="mb-4"
          >
            {steps && steps.length > 0
              ? steps
                  .filter((step) => step.milestone == 'noMilestone')
                  .sort((a, b) => a.order - b.order) // Sort by order
                  .map((step, index) => (
                    <Draggable
                      key={step._id}
                      draggableId={step._id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="cursor-pointer flex flex-col text-left lg:flex-row items-center p-4 border rounded-lg text-blue-500 mb-2"
                          onClick={() => {
                            console.log(step, 'step')
                            setStepData(step)
                            setTrigger('stepView')
                          }}
                        >
                          <h1 className="font-bold flex-1">{step.name}</h1>
                          {/* <div>
                            <Button variant="ghost">
                              {step?.days + ' days' || ''}
                            </Button>
                            <Button variant="ghost">+ Assign</Button>
                            <Button variant="ghost">Tasks</Button>
                            <Button variant="ghost">
                              <ChatBubbleOvalLeftIcon className="w-6 h-6" />
                            </Button>
                          </div> */}
                        </div>
                      )}
                    </Draggable>
                  ))
              : null}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Input
        onChange={(e) => setStepName(e.target.value)}
        placeholder="Type in the step"
        value={stepName}
        hidden={Cookies.get('role') == "member"}
      />
      <Button
        hidden={Cookies.get('role') == "member"}
        onClick={() => {
          dispatch(addStep({ name: stepName, templateId: data?._id }))
          toast({
            title: 'Step added!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
          setStepName("")
        }}
        className="w-full mt-4 bg-blue-600 text-white"
      >
        CREATE STEP
      </Button>
    </DragDropContext>
  )
}

export default TemplateStep
