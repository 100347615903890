import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Textarea,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  FormLabel,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  PlayIcon,
  UserGroupIcon,
  PlusIcon,
  PencilIcon,
} from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  openTemplateLaunchModal,
  closeTemplateLaunchModal,
} from '../redux/TemplateLaunchModal'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../redux/InviteMemberModal'
import {
  Radio,
  RadioGroup,
  Stack,
  Tag,
  TagLabel,
  Avatar,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import FormRender from './utils/FormRender'
import { fetchTemplates } from '../redux/TemplateSlice'
import { launchTemplate } from '../redux/LaunchedTemplateSlice'
import FocusLock from 'react-focus-lock'
import { fetchMembers } from '../redux/MemberSlice'

function TemplateLaunch({ formData }) {
  const dispatch = useDispatch()
  const isModalOpen = useSelector((state) => state.templateLaunch.isModalOpen)

  const toast = useToast()

  const [step, setStep] = useState(1)
  const [value, setValue] = useState('1') // Initial value is '1'
  const [formResponses, setFormResponses] = useState({
    assignSomeoneElse: false,
    assignTo: formData?.membersAssigned || [],
    guests: formData?.guestAssigned || [],
    forms: {},
    desc: formData?.kickOffForm?.desc,
    autoNameProcess: formData?.kickOffForm?.autoNameProcess,
    name: formData?.kickOffForm?.name,
  })

  useEffect(() => {
    setFormResponses({
      assignSomeoneElse: false,
      assignTo: formData?.membersAssigned || [],
      guests: formData?.guestAssigned || [],
      forms: {},
      desc: formData?.kickOffForm?.desc,
      autoNameProcess: formData?.kickOffForm?.autoNameProcess,
      name: formData?.kickOffForm?.name,
    })
  }, [formData])

  const handleChange = (newValue) => {
    setValue(newValue)
  }


  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOpenModal = () => {
    dispatch(openTemplateLaunchModal())
    // Additional logic, if needed
    onOpen()
  }

  const handleCloseModal = () => {
    dispatch(closeTemplateLaunchModal())
    // Additional logic, if needed
    onClose()
  }

  const router = useNavigate()

  const state = useSelector((state) => state)

  useEffect(() => {
    dispatch(fetchMembers())
  }, [])

  function validateForm(forms) {
    for (let key in forms) {
      if (forms[key].required && forms[key].value.length == 0) {
        return false // Validation failed
      }
    }
    return true // Validation passed
  }

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Launch Template {formData?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
            <p>{formData?.kickOffForm?.desc}</p>
            <div hidden={step != 1}>
              <FormRender
                formData={formData?.kickOffForm?.forms}
                formResponses={formResponses}
                setFormResponses={setFormResponses}
              />
              <div
                onClick={(e) =>
                  setFormResponses({
                    ...formResponses,
                    assignSomeoneElse: e.target.checked,
                  })
                }
                className="flex justify-between"
              >
                <FormLabel htmlFor="2" mb="0">
                  Assign it to someone else?
                </FormLabel>
                <Switch isChecked={formResponses.assignSomeoneElse} id="2" />
              </div>
              <div hidden={!formResponses.assignSomeoneElse}>
                <div>
                  <Text className="font-bold">Add Members or Groups</Text>
                  <InputGroup>
                    <InputLeftAddon className="my-2 w-[4rem]">
                      <Tag size="sm" colorScheme="red" borderRadius="full">
                        <Avatar
                          src="https://bit.ly/sage-adebayo"
                          size="xs"
                          name="Segun Adebayo"
                          ml={-1}
                          mr={2}
                        />
                        <TagLabel>Segun</TagLabel>
                      </Tag>
                    </InputLeftAddon>
                    <Select
                      value={formResponses.assignTo?.toString()}
                      className="my-2"
                      placeholder="Search"
                      onChange={(e) => {
                        setFormResponses({
                          ...formResponses,
                          assignTo: [...formResponses.assignTo, e.target.value],
                        })
                      }}
                    >
                      {state?.members?.data?.members?.map((el, i) => (
                        <option
                          key={i}
                          value={el.email}
                          onClick={(e) =>
                            setFormResponses({
                              ...formResponses,
                              assignTo: [...formResponses.assignTo, el.email],
                            })
                          }
                        >
                          {el.fName + ' ' + el.lName}
                        </option>
                      ))}
                    </Select>
                  </InputGroup>
                  {/* <Button
                    variant="ghost"
                    leftIcon={<PlusIcon className="w-4 h-4" />}
                    onClick={() => dispatch(openInviteMemberModal())}
                  >
                    Invite New Members
                  </Button> */}
                </div>
                {/* <div>
                  <Text className="font-bold">Add Guests</Text>
                  <p className="text-sm">Add comma separated email addresses</p>
                  <InputGroup>
                    <Input
                      className="my-2"
                      placeholder="Search"
                      value={formResponses.guests}
                      onChange={(e) =>
                        setFormResponses({
                          ...formResponses,
                          guests: e.target.value?.split(','),
                        })
                      }
                    />
                  </InputGroup>
                </div> */}
              </div>
            </div>

            <div hidden={step != 2}>
              <Textarea
                type="text"
                placeholder="Type in a unique name for your template"
                required={!formData?.autoNameProcess}
                value={formResponses.name}
                onChange={(e) =>
                  setFormResponses({ ...formResponses, name: e.target.value })
                }
              />
            </div>
          </ModalBody>
          <ModalFooter className="mt-8">
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => {
                if (step > 1) {
                  setStep(step - 1)
                } else {
                  handleCloseModal()
                }
              }}
            >
              {step > 1 ? 'Back' : ' Cancel'}
            </Button>
            <Button
              rightIcon={<UserGroupIcon className="w-4 h-4 ml-2" />}
              colorScheme="blue"
              onClick={() => {
                console.log('COntinue click', formResponses)
                const {
                  name,
                  assignSomeoneElse,
                  assignTo,
                  autoNameProcess,
                  forms,
                } = formResponses

                const handleSuccess = () => {
                  dispatch(
                    launchTemplate({ ...formData, kickOffForm: formResponses }),
                  )
                  toast({
                    title: 'Template Instantiated!',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                  })
                  handleCloseModal()
                  router('/dashboard/tracker')
                }

                const handleError = () => {
                  toast({
                    title: 'Something went wrong!',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  })
                }

                if (step === 2 && name.length > 0) {
                  handleSuccess()
                } else if (step === 1) {
                  const isFormValid = validateForm(forms)
                  const canProceed =
                    (!assignSomeoneElse && isFormValid) ||
                    (assignSomeoneElse && assignTo?.length > 0 && isFormValid)

                  if (canProceed) {
                    if (autoNameProcess && name.length > 0) {
                      handleSuccess()
                    } else {
                      setStep(step + 1)
                    }
                  } else {
                    handleError()
                  }
                } else {
                  handleError()
                }
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TemplateLaunch
