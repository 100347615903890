import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header'
import SettingsSidebar from '../../../components/SettingsSidebar'
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { DataGrid2 } from '../../../components/utils/DataGrid.tsx'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

function EmailSettings() {
  const router = useNavigate()

  useEffect(() => {
    if (Cookies.get('role') == 'owner' || Cookies.get('role') == 'member') {
      return router('/signin')
    }
  }, [])

  
  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row  py-12 lg:py-0 ">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="flex flex-col space-y-2 items-start pb-4">
            <div className="flex justify-between">
              <FormLabel htmlFor="1" mb="0">
                Send all emails via your SMTP server
                <p className="font-normal">
                  Provision a single email like tallyfy@company.com. How to test
                  SMTP.
                </p>
              </FormLabel>
              <Switch id="1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailSettings
