import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunk to get the current user
export const getCurrentUser = createAsyncThunk('getCurrentUser', async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/getCurrentUser`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
  });
  if (!res.ok) {
    throw new Error('Failed to fetch current user');
  }
  return res.json();
});

// Thunk to update the current user
export const updateCurrentUser = createAsyncThunk('updateCurrentUser', async (formData) => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/getCurrentUser`, { // Ensure the endpoint is correct
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${Cookies.get('token')}`,
    },
    body: formData,
  });

  if (!res.ok) {
    const error = await res.json();
    throw new Error(error.error || 'Failed to update current user');
  }

  return res.json();
});

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    errorMessage: '',
    successMessage: '', // Added for success messages
  },
  reducers: {
    clearSuccessMessage: (state) => {
      state.successMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.successMessage = ''; // Reset success message
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = [action.payload.user];
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message; // Set error message
      })
      .addCase(updateCurrentUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.successMessage = ''; // Reset success message
      })
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = [action.payload.user];
        state.successMessage = 'Profile updated successfully'; // Set success message
      })
      .addCase(updateCurrentUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message; // Set error message
      });
  },
});

export default currentUserSlice.reducer;
