import React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";


export function DataGrid2({rows, columns}) {
  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}
