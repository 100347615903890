import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Header';
import SettingsSidebar from '../../../components/SettingsSidebar';
import {
  Button,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { getCurrentOrganization, updateCurrentOrganization } from '../../../redux/OrganizationSlice'; 
import moment from 'moment-timezone'; 
import { countries } from 'countries-list'; 

function Details() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const toast = useToast();
  const organization = useSelector((state) => state.organization.data);
  const isLoading = useSelector((state) => state.organization.isLoading);
  const successMessage = useSelector((state) => state.organization.successMessage);
  const errorMessage = useSelector((state) => state.organization.errorMessage);

  const [orgName, setOrgName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('United States');
  const [timezone, setTimezone] = useState('utc');
  const [dateFormat, setDateFormat] = useState('dd/MM/yyyy');

  useEffect(() => {
    if (Cookies.get('role') === 'owner' || Cookies.get('role') === 'member' || Cookies.get('role') === 'admin') {
      router('/signin');
    } else {
      dispatch(getCurrentOrganization());
    }
  }, [dispatch, router]);

  useEffect(() => {
    if (organization) {
      setOrgName(organization.name || '');
      setAddressLine1(organization.address?.line1 || '');
      setAddressLine2(organization.address?.line2 || '');
      setCity(organization.address?.city || '');
      setState(organization.address?.state || '');
      setZip(organization.address?.zipCode || '');
      setCountry(organization.address?.country || 'United States');
      setTimezone(organization.timeZone || 'utc');
      setDateFormat(organization.dateFormat || 'dd/MM/yyyy');
    }
  }, [organization]);

  useEffect(() => {
    if (successMessage) {
      toast({
        title: 'Success',
        description: successMessage,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }

    if (errorMessage) {
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successMessage, errorMessage, toast]);

  const handleSaveChanges = () => {
    const organizationData = {
      name: orgName,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      country,
      timezone,
      dateFormat
    };

    dispatch(updateCurrentOrganization(organizationData));
  };

  const dateFormats = [
    'dd/MM/yyyy',
    'MM/dd/yyyy',
    'yyyy-MM-dd',
    'dd-MM-yyyy',
    'MM-dd-yyyy',
    'dd MMM yyyy',
    'MMM dd, yyyy',
    'EEEE, MMMM do, yyyy',
    'dd.MM.yyyy',
    'yyyy.MM.dd',
  ];

  const timeZones = moment.tz.names();

  const countryOptions = Object.values(countries).map(country => ({
    value: country.code,
    label: country.name
  }));

  return (
    <div className="overflow-hidden max-h-screen">
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row py-12 lg:py-0">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="mx-auto p-6 bg-white rounded-lg shadow-md">
            <form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="flex flex-col">
                  <label className="font-semibold mb-2" htmlFor="org-name">
                    Organization Name *
                  </label>
                  <Input
                    id="org-name"
                    placeholder="Infospray Media"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                  />
                </div>
              </div>
              <div className="font-semibold mb-4">ADDRESS</div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="flex flex-col">
                  <label className="mb-2" htmlFor="address-line1">
                    Address Line 1
                  </label>
                  <Input
                    id="address-line1"
                    placeholder="Address Line 1"
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2" htmlFor="address-line2">
                    Address Line 2 (Optional)
                  </label>
                  <Input
                    id="address-line2"
                    placeholder="Apt, suite"
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2" htmlFor="city">
                    Town/City
                  </label>
                  <Input
                    id="city"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2" htmlFor="state">
                    State/County
                  </label>
                  <Input
                    id="state"
                    placeholder="State/County"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2" htmlFor="zip">
                    Zip/Post Code
                  </label>
                  <Input
                    id="zip"
                    placeholder="Zip Code"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2" htmlFor="country">
                    Country
                  </label>
                  <Select
                    id="country"
                    placeholder="Select country"
                    value={country}
                    onChange={(e) => {setCountry(e.target.value)}}
                  >
                    {countryOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="font-semibold mb-4">Default Date Format</div>
              <div className="flex flex-col mb-6">
                <Select
                  id="date-format"
                  value={dateFormat}
                  onChange={(e) => setDateFormat(e.target.value)}
                >
                  {dateFormats.map((format) => (
                    <option key={format} value={format}>
                      {format}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="font-semibold mb-4">Default Time Zone</div>
              <div className="flex flex-col mb-6">
                <Select
                  id="timezone"
                  value={timezone}
                  onChange={(e) => setTimezone(e.target.value)}
                >
                  {timeZones.map((zone) => (
                    <option key={zone} value={zone}>
                      {zone}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="flex justify-end">
                <Button
                  colorScheme="blue"
                  onClick={handleSaveChanges}
                  isLoading={isLoading}
                >
                  Save Changes
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
