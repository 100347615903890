import React, { useState, useEffect } from 'react'

import Navbar from '../components/Navbar'
import Cookies from 'js-cookie'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'

export default function Signin() {
  const [data, setData] = useState({
    email: '',
    password: '',
  })

  const [disabled, setDisabled] = useState(false)

  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    if (Cookies.get('token') && Cookies.get('email')) {
      return navigate('/dashboard/home')
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      data.email.includes('.') &&
      data.email.includes('@') &&
      data.password.length > 2
    ) {
      setDisabled(true)
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/signin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: data.email,
            password: data.password,
          }),
        },
      )

      const res2 = await res.json()

      if (res2.error) {
        toast({
          position: 'top-right',
          title: res2.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        setDisabled(false)
      } else if (res2.token) {
        Cookies.set('token', res2.token, { expires: 7 })
        Cookies.set('email', res2.email, { expires: 7 })
        Cookies.set('fName', res2.fName, { expires: 7 })
        Cookies.set('lName', res2.lName, { expires: 7 })
        Cookies.set('role', res2.role, { expires: 7 })
        Cookies.set('dateFormat', res2.dateFormat, { expires: 7 })
        Cookies.set('timeZone', res2.timeZone, { expires: 7 })
        Cookies.set('brandLogo', encodeURIComponent(res2.brandLogo), { expires: 7 })
        Cookies.set('buttonColor', res2.buttonColor, { expires: 7 })
        
        setTimeout(() => {
          navigate('/dashboard/home')
        }, 2000)
        toast({
          position: 'top-right',
          title: 'Login Success!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        setDisabled(false)
      } else {
        toast({
          position: 'top-right',
          title: 'Something went wrong!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      setDisabled(false)
    } else {
      toast({
        position: 'top-right',
        title: 'Something went wrong!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      setDisabled(false)
    }
  }

  const handleSubmitGoogle = async (googleAccessToken) => {
    setDisabled(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/signin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ googleAccessToken }),
        },
      )

      const data = await response.json()

      if (data.error) {
        toast({
          position: 'top-right',
          title: data.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        setDisabled(false)
      } else if (data.token) {
        Cookies.set('token', data.token, { expires: 7 })
        Cookies.set('email', data.email, { expires: 7 })
        Cookies.set('fName', data.fName, { expires: 7 })
        Cookies.set('lName', data.lName, { expires: 7 })
        Cookies.set('role', data.role, { expires: 7 })
        Cookies.set('dateFormat', data.dateFormat, { expires: 7 })
        Cookies.set('timeZone', data.timeZone, { expires: 7 })
        Cookies.set('brandLogo', encodeURIComponent(data.brandLogo), { expires: 7 })
        Cookies.set('buttonColor', data.buttonColor, { expires: 7 })

        setTimeout(() => {
          navigate('/dashboard/home')
        }, 500)
        setDisabled(false)
      } else {
        toast({
          position: 'top-right',
          title: 'Something went wrong!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        setDisabled(false)
      }
    } catch (error) {
      toast({
        position: 'top-right',
        title: 'An error occured during signin',
        status: 'error',
        duration: 9000,
        isClosable: true,

      })
      setDisabled(false)
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      handleSubmitGoogle(tokenResponse.access_token)
    },
  })

  return (
    <div>
      <Navbar />
      <main className="w-full max-w-md mx-auto p-6">
        <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                Sign in
              </h1>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Don't have an account yet?
                <a
                  className="text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  href="/signup"
                >
                  Sign up here
                </a>
              </p>
            </div>
            <div className="mt-5">
              <button
                type="button"
                onClick={googleLogin}
                className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                <svg
                  className="w-4 h-auto"
                  width={46}
                  height={47}
                  viewBox="0 0 46 47"
                  fill="none"
                >
                  <path
                    d="M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z"
                    fill="#34A853"
                  />
                  <path
                    d="M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z"
                    fill="#EB4335"
                  />
                </svg>
                Sign in with Google
              </button>
              <div className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 dark:text-gray-500 dark:before:border-gray-600 dark:after:border-gray-600">
                Or
              </div>
              {/* Form */}

              <div className="grid gap-y-4">
                {/* Form Group */}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm mb-2 dark:text-white"
                  >
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required=""
                      aria-describedby="email-error"
                      value={data.email}
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                    />
                    <div className="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                      <svg
                        className="size-5 text-red-500"
                        width={16}
                        height={16}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                    </div>
                  </div>
                  <p
                    className="hidden text-xs text-red-600 mt-2"
                    id="email-error"
                  >
                    Please include a valid email address so we can get back to
                    you
                  </p>
                </div>
                {/* End Form Group */}
                {/* Form Group */}
                <div>
                  <div className="flex justify-between items-center">
                    <label
                      htmlFor="password"
                      className="block text-sm mb-2 dark:text-white"
                    >
                      Password
                    </label>
                    <a
                      className="text-sm text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      href="/forgotPassword"
                    >
                      Forgot password?
                    </a>
                  </div>
                  <div className="relative">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required=""
                      aria-describedby="password-error"
                      value={data.password}
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                    />
                    <div className="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                      <svg
                        className="size-5 text-red-500"
                        width={16}
                        height={16}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                    </div>
                  </div>
                  <p
                    className="hidden text-xs text-red-600 mt-2"
                    id="password-error"
                  >
                    8+ characters required
                  </p>
                </div>
                {/* End Form Group */}
                {/* Checkbox */}
                <div className="flex items-center">
                  <div className="flex">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    />
                  </div>
                  <div className="ms-3">
                    <label
                      htmlFor="remember-me"
                      className="text-sm dark:text-white"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                {/* End Checkbox */}
                <button
                  onClick={(e) => handleSubmit(e)}
                  disabled={disabled}
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  Sign in
                </button>
              </div>
              {/* End Form */}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
