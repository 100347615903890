import React, { useState, useEffect } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Avatar,
  FormLabel,
  Switch,
  Tag,
  TagCloseButton,
  useToast,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Editor from 'react-simple-wysiwyg'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import {
  getLaunchedTemplate,
  launchTemplate,
  updateLaunchTemplate,
} from '../../../redux/LaunchedTemplateSlice'
import Cookies from 'js-cookie'
import moment from 'moment-timezone';
import { format } from 'date-fns';
import RichTextEditor from '../../../components/utils/RichTextEditor'

function getFormattedDate (utcDate) {
  if(!utcDate) return 
  const date = moment.tz(utcDate, Cookies.get('timeZone'));
  return format(new Date(date.format()), Cookies.get('dateFormat'));
};

function DrawerExample({ isOpen, onOpen, onClose, _id }) {
  const [html, setHtml] = useState('my <b>HTML</b>')


  const [tags, setTags] = useState(['foo', 'bar'])
  const [tagSearch, setTagSearch] = useState('')

  const [permissions, setPermissions] = useState({
    editTemplate: 'everyone',
    readTemplate: 'everyone',
    duplicateTemplate: 'everyone',
    launchProcess: 'everyone',
    processAccess: 'everyone',
  })

  const [value, setValue] = useState([])

  const [data, setData] = useState([])
  const [folderList, setFolderList] = useState([])
  const [filteredFolderList, setFilteredFolderList] = useState(folderList)

  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // Add more options as needed
  ]

  const toast = useToast()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const state = useSelector((state) => state)

  useEffect(() => {
    dispatch(getLaunchedTemplate())
    const temp = state?.launchedTemplates?.data?.reduce((acc, el) => {
      if (el?.folder?.length > 0) {
        acc.push(el.folder)
      }
      return acc
    }, [])

    const uniqueFolders = [...new Set(temp)]
    setFolderList(uniqueFolders)

    setFilteredFolderList(uniqueFolders)
    console.log('uniqueFolders', uniqueFolders)
  }, [dispatch, launchTemplate, updateLaunchTemplate])

  useEffect(() => {
    if (state?.launchedTemplates?.data?.length > 0) {
      console.log(state?.launchedTemplates?.data)
      setData(
        state?.launchedTemplates?.data?.filter((el, i) => el._id == _id)[0],
      )
    } else {
      setData([])
    }
  }, [navigate, state?.launchedTemplates?.data])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent form submission
      if (tagSearch.trim() !== '') {
        setData({ ...data, tags: [...data.tags, tagSearch.trim()] })
        setTagSearch('') // Clear input field after adding tag
      }
    }
  }

  const removeTag = (index) => {
    const newTags = [...data.tags]
    newTags.splice(index, 1)
    setData({ ...data, tags: newTags })
  }

  return (
    <>
      <Drawer size="lg" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Config</DrawerHeader>

          <DrawerBody>
            <Input
              value={data?.kickOffForm?.name}
              isReadOnly={Cookies.get('role') == "member"}
              onChange={(e) =>
                setData({
                  ...data,
                  kickOffForm: { ...data.kickOffForm, name: e.target.value },
                })
              }
            />
            <div className="space-x-2 my-2">
              <Button
              disabled={Cookies.get('role') == "member"}
                onClick={() => {
                  const { _id, name, ...dataWithoutId } = data
                  const newName = name + ' Copy'

                  dispatch(
                    launchTemplate({
                      ...dataWithoutId,
                      name: newName,
                    }),
                  )
                    .then(() => {
                      // Show success toast
                      toast({
                        title: 'Duplicate Created!',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                      })
                      onClose()
                      // Navigate to the new procedure page
                    })
                    .catch((error) => {
                      // Show error toast
                      toast({
                        title: 'An error occurred!',
                        description:
                          error.message ||
                          'Failed to create duplicate template.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                      })
                    })
                }}
                size="sm"
              >
                Duplicate
              </Button>
              <Button
              disabled={Cookies.get('role') == "member"}
                onClick={() =>
                  dispatch(
                    updateLaunchTemplate({
                      _id: data._id,
                      isArchived: !data.isArchived,
                    }),
                  )
                }
                size="sm"
              >
                {data.isArchived ? 'Unarchive' : 'Archive'}
              </Button>

              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon className="w-4 h-4" />}
                  size="sm"
                >
                  Folders
                </MenuButton>
                <MenuList>
                  <MenuItem
                  disabled={Cookies.get('role') == "member"}
                    onClick={() =>
                      dispatch(
                        updateLaunchTemplate({ _id: data._id, folder: '' }),
                      )
                    }
                    className={`${
                      data.folder == '' || data.folder == null || !data.folder
                        ? 'bg-green-200'
                        : ''
                    }`}
                    isDisabled={
                      data.folder == '' || data.folder == null || !data.folder
                    }
                  >
                    Uncategorized
                  </MenuItem>
                  {folderList?.map((folder, i) => (
                    <MenuItem
                      className={`${
                        folder == data.folder ? 'bg-green-200' : ''
                      }`}
                      isDisabled={folder == data.folder}
                      disabled={Cookies.get('role') == "member"}
                      onClick={() =>
                        dispatch(
                          updateLaunchTemplate({
                            _id: data._id,
                            folder: folder,
                          }),
                        )
                      }
                      key={i}
                    >
                      {folder}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>

            <div>
              <Tabs>
                <TabList>
                  <Tab>Summary</Tab>
                  {/* <Tab>Activity</Tab>
                  <Tab>Permissions</Tab>
                  <Tab>Tools</Tab> */}
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <div className="py-8">
                      {' '}
                      <div className="space-y-4 py-4">
                        <h1 className="font-bold text-md">Description</h1>
                        {Cookies.get('role') == "member" ? <div dangerouslySetInnerHTML={{ __html: data?.desc }} />: <RichTextEditor
                          value={data.desc}
                          onChange={(html) =>
                            setData({ ...data, desc: html })
                          }
                        />}

                        <InputGroup>
                          <InputLeftAddon className="w-[15rem] mx-auto justify-center flex center text-center">
                            Status
                          </InputLeftAddon>
                          <Select
                            id="status-toggle"
                            placeholder="Status"
                            className="w-full rounded-l-none"
                            value={data.status}
                            isReadOnly={Cookies.get('role') == "member"}
                            onChange={(e) =>
                              setData({ ...data, status: e.target.value })
                            }
                          >
                            <option value="started">Started</option>
                            <option value="inProgress">In Progress</option>
                            <option value="almost">Almost Complete</option>
                            <option value="completed">Complete</option>
                          </Select>
                        </InputGroup>

                        <InputGroup>
                          <InputLeftAddon className="w-[15rem] mx-auto justify-center flex center text-center">
                            Internal explanation video URL
                          </InputLeftAddon>
                          <Input
                            value={data.explanationVideoURL}
                            isReadOnly={Cookies.get('role') == "member"}
                            onChange={(e) =>
                              setData({
                                ...data,
                                explanationVideoURL: e.target.value,
                              })
                            }
                            placeholder="Internal explanation video URL"
                          />
                        </InputGroup>

                        <InputGroup>
                          <InputLeftAddon className="w-[15rem]">
                            Created By
                          </InputLeftAddon>
                          <div className="flex items-center w-full rounded-r-lg px-4 border">
                            <Avatar
                              src="https://bit.ly/sage-adebayo"
                              size="sm"
                              name="Segun Adebayo"
                              mr="4"
                            />
                            <p>{data.createdBy}</p>
                          </div>
                        </InputGroup>

                        <InputGroup>
                          <InputLeftAddon className="w-[15rem]">
                            Date Created
                          </InputLeftAddon>
                          <div className="flex items-center w-full rounded-r-lg px-4 border">
                            <p>
                              {getFormattedDate(data?.createdAt)}
                            </p>
                          </div>
                        </InputGroup>

                        <InputGroup>
                          <InputLeftAddon className="w-[15rem]">
                            Last Updated
                          </InputLeftAddon>
                          <div className="flex items-center w-full rounded-r-lg px-4 border">
                            <p>
                              {getFormattedDate(data?.updatedAt)}
                            </p>
                          </div>
                        </InputGroup>

                                                <InputGroup>
                          <InputLeftAddon className="w-[15rem]">
                            Members Assigned
                          </InputLeftAddon>
                            <Select
                              placeholder="Search"
                              isReadOnly={Cookies.get('role') == "member"}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  membersAssigned: [...new Set([
                                    ...data.membersAssigned,
                                    e.target.value,
                                  ])],
                                  
                                })
                              }}
                            >
                              {state?.members?.data?.members?.map((el, i) => (
                                <option
                                  key={i}
                                  value={el.email}
                                  disabled={Cookies.get('role') == "member"}
                                  onChange={(e) => {
                                    setData({
                                      ...data,
                                      membersAssigned: [...new Set([
                                        ...data.membersAssigned,
                                        el.email,
                                      ])],
                                    })
                                  }}
                                >
                                  {el.fName + ' ' + el.lName}
                                </option>
                              ))}
                            </Select>
                        </InputGroup>
                        {data?.membersAssigned?.map((el, i) => (
                          <Tag
                            size={'md'}
                            key={i}
                            variant="solid"
                            colorScheme="teal"
                            className="w-fit"
                          >
                            {el}
                            <button
                            isReadOnly={Cookies.get('role') == "member"}
                              onClick={() => {
                                const stepDataUpdated = data?.membersAssigned
                                stepDataUpdated.splice(i, 1)
                                setData({
                                  ...data,
                                  membersAssigned: [...new Set([stepDataUpdated])],
                                })
                              }}
                            >
                              <TagCloseButton />
                            </button>
                          </Tag>
                        ))}

                        {/* <InputGroup>
                          <InputLeftAddon className="w-[15rem]">
                            Guests Assigned
                          </InputLeftAddon>
                          <div className="flex items-center w-full rounded-r-lg px-4 border">
                            <Input
                              value={data.guestAssigned}
                              isReadOnly={Cookies.get('role') == "member"}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  guestAssigned: e.target.value?.split(','),
                                })
                              }
                              placeholder="Comma separated emails"
                            />
                          </div>
                        </InputGroup> */}

                        {/* <div
                        isReadOnly={Cookies.get('role') == "member"}
                          onClick={(e) =>
                            setData({
                              ...data,
                              forceLauncherToFolderizeProcess: e.target.checked,
                            })
                          }
                          className="flex justify-between"
                        >
                          <FormLabel htmlFor="1" mb="0">
                            Force launcher to folderize process?
                          </FormLabel>
                          <Switch
                            isChecked={data.forceLauncherToFolderizeProcess}
                            id="1"
                          />
                        </div>

                        <div
                        isReadOnly={Cookies.get('role') == "member"}
                          onClick={(e) =>
                            setData({
                              ...data,
                              forceLauncherToTagProcess: e.target.checked,
                            })
                          }
                          className="flex justify-between"
                        >
                          <FormLabel htmlFor="1" mb="0">
                            Force launcher to tag process?
                          </FormLabel>
                          <Switch
                            isChecked={data.forceLauncherToTagProcess}
                            id="1"
                          />
                        </div> */}
                      </div>
                      <hr />
                      <div
                      isReadOnly={Cookies.get('role') == "member"}
                        onClick={(e) =>
                          setData({
                            ...data,
                            autoNameProcess: e.target.checked,
                          })
                        }
                        className="space-y-4 py-4"
                      >
                        <div className="flex justify-between">
                          <FormLabel htmlFor="1" mb="0">
                            Auto-name Process?
                          </FormLabel>
                          <Switch isChecked={data.autoNameProcess} id="1" />
                        </div>
                      </div>
                      <hr />
                      {/* <div className="space-y-4 py-4">
                        <div>
                          <h1 className="font-bold">
                            Upload your original process document
                          </h1>
                          <p className="text-gray-800">
                            Common formats are a flowchart image, Word, Excel or
                            PDF document
                          </p>
                        </div>
                        <input type="file" />
                      </div> */}
                      <hr />
                      <div className="space-y-4 py-4">
                        <Input
                          type="text"
                          placeholder="Tags"
                          value={tagSearch}
                          onChange={(e) => setTagSearch(e.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                        <div className="flex flex-row space-x-2 mt-2">
                          {data?.tags?.map((title, i) => (
                            <Tag
                              size="md"
                              key={i}
                              variant="solid"
                              colorScheme="teal"
                              className="w-fit"
                            >
                              {title}
                              <TagCloseButton onClick={() => removeTag(i)} />
                            </Tag>
                          ))}
                        </div>

                        <h1 className="font-bold text-md">
                          Special Instructions
                        </h1>
                        {Cookies.get('role') == "member" ? <div dangerouslySetInnerHTML={{ __html: data?.specialInstructions }} />: <RichTextEditor
                          value={data.specialInstructions}
                          onChange={(html) =>
                            setData({
                              ...data,
                              specialInstructions: html,
                            })
                          }
                        />}
                      </div>
                      <hr />
                      {/* <div className="space-y-4 py-4">
                        <h1>ADVANCED</h1>
                        <div
                        isReadOnly={Cookies.get('role') == "member"}
                          onChange={(e) =>
                            setData({
                              ...data,
                              emitNoisyWebhookForEveryTask: e.target.checked,
                            })
                          }
                          className="flex justify-between"
                        >
                          <FormLabel htmlFor="1" mb="0">
                            Emit a (noisy) webhook for every task
                          </FormLabel>
                          <Switch
                            isChecked={data.emitNoisyWebhookForEveryTask}
                            id="1"
                          />
                        </div>
                      </div> */}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      {/* Heading */}
                      <div className="ps-2 my-2 first:mt-0">
                        <h3 className="text-xs font-medium uppercase text-gray-500">
                          1 Aug, 2023
                        </h3>
                      </div>
                      {/* End Heading */}
                      {/* Item */}
                      <div className="flex gap-x-3">
                        {/* Icon */}
                        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                          <div className="relative z-10 size-7 flex justify-center items-center">
                            <div className="size-2 rounded-full bg-gray-400" />
                          </div>
                        </div>
                        {/* End Icon */}
                        {/* Right Content */}
                        <div className="grow pt-0.5 pb-8">
                          <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                            <svg
                              className="flex-shrink-0 size-4 mt-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                              <polyline points="14 2 14 8 20 8" />
                              <line x1={16} x2={8} y1={13} y2={13} />
                              <line x1={16} x2={8} y1={17} y2={17} />
                              <line x1={10} x2={8} y1={9} y2={9} />
                            </svg>
                            Created "Preline in React" task
                          </h3>
                          <p className="mt-1 text-sm text-gray-600">
                            Find more detailed insctructions here.
                          </p>
                          <button
                            type="button"
                            className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                          >
                            <img
                              className="flex-shrink-0 size-4 rounded-full"
                              src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                              alt="Image Description"
                            />
                            James Collins
                          </button>
                        </div>
                        {/* End Right Content */}
                      </div>
                      {/* End Item */}
                      {/* Item */}
                      <div className="flex gap-x-3">
                        {/* Icon */}
                        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                          <div className="relative z-10 size-7 flex justify-center items-center">
                            <div className="size-2 rounded-full bg-gray-400 " />
                          </div>
                        </div>
                        {/* End Icon */}
                        {/* Right Content */}
                        <div className="grow pt-0.5 pb-8">
                          <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                            Release v5.2.0 quick bug fix 🐞
                          </h3>
                          <button
                            type="button"
                            className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                          >
                            <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full ">
                              A
                            </span>
                            Alex Gregarov
                          </button>
                        </div>
                        {/* End Right Content */}
                      </div>
                      {/* End Item */}
                      {/* Item */}
                      <div className="flex gap-x-3">
                        {/* Icon */}
                        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                          <div className="relative z-10 size-7 flex justify-center items-center">
                            <div className="size-2 rounded-full bg-gray-400 " />
                          </div>
                        </div>
                        {/* End Icon */}
                        {/* Right Content */}
                        <div className="grow pt-0.5 pb-8">
                          <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                            Marked "Install Charts" completed
                          </h3>
                          <p className="mt-1 text-sm text-gray-600 ">
                            Finally! You can check it out here.
                          </p>
                          <button
                            type="button"
                            className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                          >
                            <img
                              className="flex-shrink-0 size-4 rounded-full"
                              src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                              alt="Image Description"
                            />
                            James Collins
                          </button>
                        </div>
                        {/* End Right Content */}
                      </div>
                      {/* End Item */}
                      {/* Heading */}
                      <div className="ps-2 my-2">
                        <h3 className="text-xs font-medium uppercase text-gray-500 ">
                          31 Jul, 2023
                        </h3>
                      </div>
                      {/* End Heading */}
                      {/* Item */}
                      <div className="flex gap-x-3">
                        {/* Icon */}
                        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                          <div className="relative z-10 size-7 flex justify-center items-center">
                            <div className="size-2 rounded-full bg-gray-400 " />
                          </div>
                        </div>
                        {/* End Icon */}
                        {/* Right Content */}
                        <div className="grow pt-0.5 pb-8">
                          <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                            Take a break ⛳️
                          </h3>
                          <p className="mt-1 text-sm text-gray-600 ">
                            Just chill for now... 😉
                          </p>
                        </div>
                        {/* End Right Content */}
                      </div>
                      {/* End Item */}
                      {/* Collapse */}
                      <div
                        id="hs-timeline-collapse"
                        className="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
                        aria-labelledby="hs-timeline-collapse-content"
                      >
                        {/* Heading */}
                        <div className="ps-2 my-2 first:mt-0">
                          <h3 className="text-xs font-medium uppercase text-gray-500 ">
                            30 Jul, 2023
                          </h3>
                        </div>
                        {/* End Heading */}
                        {/* Item */}
                        <div className="flex gap-x-3">
                          {/* Icon */}
                          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                            <div className="relative z-10 size-7 flex justify-center items-center">
                              <div className="size-2 rounded-full bg-gray-400 " />
                            </div>
                          </div>
                          {/* End Icon */}
                          {/* Right Content */}
                          <div className="grow pt-0.5 pb-8">
                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800 ">
                              Final touch ups
                            </h3>
                            <p className="mt-1 text-sm text-gray-600 ">
                              Double check everything and make sure we're ready
                              to go.
                            </p>
                          </div>
                          {/* End Right Content */}
                        </div>
                        {/* End Item */}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              PUBLIC TO THE WORLD?
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <div className="flex justify-between items-center">
                            <FormLabel htmlFor="1" mb="0">
                              Your template is not public
                              <p className="font-normal">
                                WARNING - a public template is visible to the
                                whole world.
                              </p>
                            </FormLabel>
                            <Switch id="1" />
                          </div>
                        </AccordionPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              PERMISSIONS
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel className="space-y-4" pb={4}>
                          <p>
                            Anyone can edit and read this template, launch
                            processes, and view launched processes.
                          </p>
                          <div className="space-y-4">
                            <h1 className="font-bold underline">
                              For This Template
                            </h1>
                            <div className="flex items-center">
                              <h1 className="flex-1 ">Edit Template?</h1>
                              <div className="flex ml-auto">
                                <Button
                                  colorScheme={
                                    permissions.editTemplate == 'everyone'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-r-none border-r"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      editTemplate: 'everyone',
                                    })
                                  }
                                >
                                  Everyone
                                </Button>
                                <Button
                                  colorScheme={
                                    permissions.editTemplate == 'custom'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-l-none"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      editTemplate: 'custom',
                                    })
                                  }
                                >
                                  Custom
                                </Button>
                              </div>
                            </div>
                            <div
                              hidden={permissions.editTemplate == 'everyone'}
                              className="bg-gray-50 border rounded-lg p-2"
                            >
                              <p>
                                Give specific members or groups permission to
                                read this template (The template owner) and all
                                admins will still be able to read.
                              </p>
                              <MultiSelect
                                options={options}
                                value={value}
                                label="All Administrator Privileges"
                                onChange={setValue}
                              />
                            </div>
                            <hr />

                            <div className="flex items-center">
                              <h1 className="flex-1 ">Read Template?</h1>
                              <div className="flex ml-auto">
                                <Button
                                  colorScheme={
                                    permissions.readTemplate == 'everyone'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-r-none border-r"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      readTemplate: 'everyone',
                                    })
                                  }
                                >
                                  Everyone
                                </Button>
                                <Button
                                  colorScheme={
                                    permissions.readTemplate == 'custom'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-l-none"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      readTemplate: 'custom',
                                    })
                                  }
                                >
                                  Custom
                                </Button>
                              </div>
                            </div>
                            <div
                              hidden={permissions.readTemplate == 'everyone'}
                              className="bg-gray-50 border rounded-lg p-2"
                            >
                              <p>
                                Give specific members or groups permission to
                                read this template (The template owner) and all
                                admins will still be able to read.
                              </p>
                              <MultiSelect
                                options={options}
                                value={value}
                                label="All Administrator Privileges"
                                onChange={setValue}
                              />
                            </div>
                            <hr />

                            <div className="flex items-center">
                              <h1 className="flex-1 ">Duplicate Template?</h1>
                              <div className="flex ml-auto">
                                <Button
                                  colorScheme={
                                    permissions.duplicateTemplate == 'everyone'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-r-none border-r"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      duplicateTemplate: 'everyone',
                                    })
                                  }
                                >
                                  Everyone
                                </Button>
                                <Button
                                  colorScheme={
                                    permissions.duplicateTemplate == 'custom'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-l-none"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      duplicateTemplate: 'custom',
                                    })
                                  }
                                >
                                  Custom
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div
                            hidden={permissions.duplicateTemplate == 'everyone'}
                            className="bg-gray-50 border rounded-lg p-2"
                          >
                            <p>
                              Give specific members or groups permission to read
                              this template (The template owner) and all admins
                              will still be able to read.
                            </p>
                            <MultiSelect
                              options={options}
                              value={value}
                              label="All Administrator Privileges"
                              onChange={setValue}
                            />
                          </div>
                          <hr />

                          <div className="space-y-4">
                            <h1 className="font-bold underline">
                              FOR LAUNCHING PROCESSES WITH THIS TEMPLATE
                            </h1>
                            <p>
                              Assignees to a task can always see and edit a
                              process. Administrator privileges can see and edit
                              everything.
                            </p>
                            <div className="flex items-center">
                              <h1 className="flex-1 ">Launch Process?</h1>
                              <div className="flex ml-auto">
                                <Button
                                  colorScheme={
                                    permissions.launchProcess == 'everyone'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-r-none border-r"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      launchProcess: 'everyone',
                                    })
                                  }
                                >
                                  Everyone
                                </Button>
                                <Button
                                  colorScheme={
                                    permissions.launchProcess == 'custom'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-l-none"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      launchProcess: 'custom',
                                    })
                                  }
                                >
                                  Custom
                                </Button>
                              </div>
                            </div>
                            <div
                              hidden={permissions.launchProcess == 'everyone'}
                              className="bg-gray-50 border rounded-lg p-2"
                            >
                              <p>
                                Give specific members or groups permission to
                                read this template (The template owner) and all
                                admins will still be able to read.
                              </p>
                              <MultiSelect
                                options={options}
                                value={value}
                                label="All Administrator Privileges"
                                onChange={setValue}
                              />
                            </div>
                            <hr />

                            <div className="flex items-center">
                              <h1 className="flex-1 ">Process Access</h1>
                              <div className="flex ml-auto">
                                <Button
                                  colorScheme={
                                    permissions.processAccess == 'everyone'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-r-none border-r"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      processAccess: 'everyone',
                                    })
                                  }
                                >
                                  Everyone
                                </Button>
                                <Button
                                  colorScheme={
                                    permissions.processAccess == 'custom'
                                      ? 'blue'
                                      : 'gray'
                                  }
                                  className="rounded-l-none"
                                  onClick={() =>
                                    setPermissions({
                                      ...permissions,
                                      processAccess: 'custom',
                                    })
                                  }
                                >
                                  Custom
                                </Button>
                              </div>
                            </div>
                            <div
                              hidden={permissions.processAccess == 'everyone'}
                              className="bg-gray-50 border rounded-lg p-2"
                            >
                              <p>
                                Give specific members or groups permission to
                                read this template (The template owner) and all
                                admins will still be able to read.
                              </p>
                              <MultiSelect
                                options={options}
                                value={value}
                                label="All Administrator Privileges"
                                onChange={setValue}
                              />
                            </div>
                            <hr />
                          </div>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </TabPanel>
                  <TabPanel>
                    <div className="space-y-4">
                      <h1 className="font-bold">
                        SHARE TEMPLATE WITH OTHER ORGANIZATIONS
                      </h1>
                      <Input value="Test" />
                      <InputGroup>
                        <InputLeftAddon className="w-[10rem] mx-auto justify-center flex center text-center">
                          Organization ID
                        </InputLeftAddon>
                        <Input value="1c6882578e022862f2bae804d95bb931" />
                        <InputRightAddon className="mx-auto justify-center flex center text-center">
                          Copy
                        </InputRightAddon>
                      </InputGroup>

                      <InputGroup>
                        <InputLeftAddon className="w-[10rem] mx-auto justify-center flex center text-center">
                          Template ID
                        </InputLeftAddon>
                        <Input value="1c6882578e022862f2bae804d95bb931" />
                        <InputRightAddon className="mx-auto justify-center flex center text-center">
                          Copy
                        </InputRightAddon>
                      </InputGroup>
                    </div>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                dispatch(updateLaunchTemplate(data))
                toast({
                  title: 'Template Updated!',
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                })
              }}
              colorScheme="blue"
              hidden={Cookies.get('role') == "member"}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default DrawerExample
