import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import SettingsSidebar from '../../../components/SettingsSidebar';
import {
  FormControl,
  FormLabel,
  Heading,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  Button,
  useToast
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, updateCurrentUser } from '../../../redux/UserSlice';
import moment from 'moment-timezone';
import { format } from 'date-fns';

function Personalization() {
  const dispatch = useDispatch();
  const toast = useToast();
  const { data, isError, errorMessage, successMessage } = useSelector((state) => state.currentUser);

  const [dateFormat, setDateFormat] = useState('dd/MM/yyyy');
  const [timeZone, setTimeZone] = useState('UTC');
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    // Check if the current user data is already present
    if (data.length === 0) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, data.length]);


  useEffect(() => {
    if (data.length > 0) {
      setDateFormat(data[0].dateFormat || 'dd/MM/yyyy');
      setTimeZone(data[0].timeZone || 'UTC');
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (successMessage) {
      toast({
        title: "Success",
        description: successMessage,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Clear successMessage from Redux state after showing it
      dispatch({ type: 'currentUser/clearSuccessMessage' });
    }
  }, [isError, errorMessage, successMessage, toast]);

  // List of common date formats
  const dateFormats = [
    'dd/MM/yyyy',
    'MM/dd/yyyy',
    'yyyy-MM-dd',
    'dd-MM-yyyy',
    'MM-dd-yyyy',
    'dd MMM yyyy',
    'MMM dd, yyyy',
    'EEEE, MMMM do, yyyy',
    'dd.MM.yyyy',
    'yyyy.MM.dd',
  ];

  // List of time zones from moment-timezone
  const timeZones = moment.tz.names();

  // Function to get formatted date and time
  const getFormattedDate = () => {
    if(!currentDate) return 
    const date = moment.tz(currentDate, timeZone);
    return format(new Date(date.format()), dateFormat);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCurrentUser({ dateFormat, timeZone }));
  };

  return (
    <div className="overflow-hidden max-h-screen">
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row py-12 lg:py-0">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="my-8 space-y-4">
            <Heading size={'sm'}>Time & Date</Heading>

            <form onSubmit={handleSubmit}>
              {/* Date Format Selection */}
              <InputGroup className="flex">
                <InputLeftAddon className="w-[8rem]">
                  <Text>Date Format</Text>
                </InputLeftAddon>
                <div className="w-[15rem] mx-2">
                  <Select
                    onChange={(e) => setDateFormat(e.target.value)}
                    value={dateFormat}
                    className="w-[full]"
                  >
                    {dateFormats.map((format) => (
                      <option key={format} value={format}>
                        {format}
                      </option>
                    ))}
                  </Select>
                </div>
              </InputGroup>

              {/* Time Zone Selection */}
              <InputGroup className="flex">
                <InputLeftAddon className="w-[8rem]">
                  <Text>Time Zone</Text>
                </InputLeftAddon>
                <div className="w-[15rem] mx-2">
                  <Select
                    onChange={(e) => setTimeZone(e.target.value)}
                    value={timeZone}
                    className="w-[full]"
                  >
                    {timeZones.map((zone) => (
                      <option key={zone} value={zone}>
                        {zone}
                      </option>
                    ))}
                  </Select>
                </div>
              </InputGroup>

              {/* Displaying formatted date */}
              <div className="mt-4">
                <Text>
                  Current Date in Selected Format and Time Zone:{' '}
                  <strong>{getFormattedDate()}</strong>
                </Text>
              </div>

              {/* Save Button */}
              <Button type="submit" colorScheme="blue" mt={4}>
                Save Changes
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Personalization;
