import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header'
import SettingsSidebar from '../../../components/SettingsSidebar'
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { DataGrid2 } from '../../../components/utils/DataGrid.tsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  openCreateGuestModal,
  closeCreateGuestModal,
} from '../../../redux/CreateGuestsModal'
import CreateGuests from '../../../components/CreateGuests'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'


function Guests() {
  const dispatch = useDispatch()
  const rows = [
    {
      id: 1,
      Email: 'john.doe@example.com',
      Enabled: true,
      'Associated Members': 3,
      Tasks: 5,
      'Email Logs': 10,
      'Guest URL': 'https://example.com/guest/john.doe',
      'Convert to Member': false,
      'First Created': '2022-01-01',
      'Last Accessed': '2022-03-08',
      'Last City': 'New York',
      'Last Country': 'USA',
      Delete: 'Delete Action', // Placeholder for delete action
    },
    // Add more rows as needed
  ]

  // Columns definition
  const columns = [
    { field: 'Email', headerName: 'Email', width: 200 },
    { field: 'Enabled', headerName: 'Enabled', width: 100 },
    {
      field: 'Associated Members',
      headerName: 'Associated Members',
      width: 150,
    },
    { field: 'Tasks', headerName: 'Tasks', width: 100 },
    { field: 'Email Logs', headerName: 'Email Logs', width: 120 },
    { field: 'Guest URL', headerName: 'Guest URL', width: 200 },
    { field: 'Convert to Member', headerName: 'Convert to Member', width: 150 },
    { field: 'First Created', headerName: 'First Created', width: 150 },
    { field: 'Last Accessed', headerName: 'Last Accessed', width: 150 },
    { field: 'Last City', headerName: 'Last City', width: 120 },
    { field: 'Last Country', headerName: 'Last Country', width: 120 },
    { field: 'Delete', headerName: 'Delete', width: 120 },
  ]

  const rows2 = [
    {
      id: 1,
      Email: 'john.doe@example.com',
      Cadence: ['mon', 'tue', 'thur'],
    },
    // Add more rows as needed
  ]

  const columns2 = [
    { field: 'Email', headerName: 'Email', width: 150 },
    {
      field: 'Cadence',
      headerName: 'Email Cadence',
      width: 400,
      renderCell: (params) => (
        <div className="flex flex-row space-x-1">
          {daysOfWeek.map((day) => (
            <Button
              key={day}
              colorScheme={params.value.includes(day) ? 'red' : 'gray'}
              p={1}
              m={1}
              size="sm"
            >
              {day}
            </Button>
          ))}
        </div>
      ),
    },
  ]

  const daysOfWeek = ['mon', 'tue', 'wed', 'thur', 'fri', 'sat', 'sun']

  const router = useNavigate()

  useEffect(() => {
    if (Cookies.get('role') == 'owner' || Cookies.get('role') == 'member') {
      return router('/signin')
    }
  }, [])


  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row  py-12 lg:py-0 ">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <Tabs>
            <TabList>
              <Tab>Table</Tab>
              <Tab>Cadences</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <div className="flex flex-col space-y-2 items-start">
                  <Button
                    variant="ghost"
                    onClick={() => dispatch(openCreateGuestModal())}
                  >
                    + Create a new guest
                  </Button>
                </div>

                <div
                  style={{
                    maxWidth: '100vw',
                    display: 'flex',
                    justifyItems: 'center',
                  }}
                >
                  <DataGrid2 rows={rows} columns={columns} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="flex flex-col space-y-2 items-start">
                  <Button
                    variant="ghost"
                    onClick={() => dispatch(openCreateGuestModal())}
                  >
                    + Create a new guest
                  </Button>
                </div>
                <div
                  style={{
                    maxWidth: '100vw',
                    display: 'flex',
                    justifyItems: 'center',
                  }}
                >
                  <DataGrid2 rows={rows2} columns={columns2} />
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
      <CreateGuests />
    </div>
  )
}

export default Guests
