import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header'
import SettingsSidebar from '../../../components/SettingsSidebar'
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

function OrgPersonalization() {
  const [datePattern, setDatePattern] = useState('hard')

  const router = useNavigate()

  useEffect(() => {
    if (Cookies.get('role') == 'owner' || Cookies.get('role') == 'member') {
      return router('/signin')
    }
  }, [])


  return (
    <div className="overflow-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row  py-12 lg:py-0 ">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="my-8 space-y-4">
            <Heading size={'sm'}>UNIVERSAL SNIPPETS</Heading>
            <div>
              {' '}
              <p className="font-bold">GUEST</p>
              <p>
                Choose which snippet all guests will see (permanently) in their
                "Tasks" view
              </p>
              <Select
                onChange={(e) => console.log(e.target.value)}
                placeholder="Select"
                className="w-[full]"
              >
                <option value="all">None</option>
              </Select>{' '}
            </div>
            <div>
              {' '}
              <p className="font-bold">MEMBERS - HOMEPAGE</p>
              <p>
                Choose which snippet all members will see (permanently) on their
                homepage
              </p>
              <Select
                onChange={(e) => console.log(e.target.value)}
                placeholder="Select"
                className="w-[full]"
              >
                <option value="all">None</option>
              </Select>{' '}
            </div>
            <div>
              {' '}
              <p className="font-bold">MEMBERS - JUST JOINED</p>
              <p>
                Choose which snippet new members will see (temporarily) when
                they first join Infospray BPM
              </p>
              <Select
                onChange={(e) => console.log(e.target.value)}
                placeholder="Select"
                className="w-[full]"
              >
                <option value="all">None</option>
              </Select>{' '}
            </div>
          </div>
          <div className="my-8 space-y-4">
            <div className="mx-2 flex flex-row">
              <FormControl className="flex flex-col" alignItems="left">
                <div className="flex justify-between">
                  <FormLabel htmlFor="1" mb="0">
                    <p>
                      WANT CUSTOM ERROR PAGES WITH NONE OF OUR GIFS? IF YES -
                      ENTER CUSTOM TEXT
                    </p>
                  </FormLabel>
                  <Switch id="1" />
                </div>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrgPersonalization
