import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormLabel, Heading, Switch, useToast } from '@chakra-ui/react';
import { getCurrentUser, updateCurrentUser } from '../../../redux/UserSlice';
import Header from '../../../components/Header';
import SettingsSidebar from '../../../components/SettingsSidebar';

function EmailNotifications() {
  const dispatch = useDispatch();
  const toast = useToast();
  const { data, isLoading, isError, errorMessage, successMessage } = useSelector((state) => state.currentUser);

  const user = data[0] || {}; // Assuming data is an array with the current user object

  useEffect(() => {
    if (!user.email) { // Fetch user settings if not already loaded
      dispatch(getCurrentUser());
    }
  }, [dispatch, user.email]);

  useEffect(() => {
    if (successMessage) {
      toast({
        title: 'Success',
        description: successMessage,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Clear successMessage from Redux state after showing it 
      dispatch({ type: 'currentUser/clearSuccessMessage' });
    }
  }, [successMessage, toast, dispatch]);

  useEffect(() => {
    if (isError && errorMessage) {
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isError, errorMessage, toast]);

  const handleSwitchChange = async (settingKey) => {
    const updatedNotifications = {
      ...user.notifications,
      [settingKey]: !user.notifications[settingKey],
    };

    try {
      await dispatch(updateCurrentUser({ notifications: updatedNotifications }));
    } catch (error) {
      console.error('Error updating user settings:', error);
    }
  };

  return (
    <div className="overflow-hidden max-h-screen">
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 flex flex-col lg:flex-row py-12 lg:py-0">
        <SettingsSidebar />
        <div className="w-full h-[90vh] pb-20 overflow-y-scroll mx-auto lg:px-8 py-0">
          <div className="py-4">
            <Heading size={'sm'}>NOTIFICATIONS EMAILS</Heading>
            <FormControl className="flex flex-col my-2 p-2 space-y-4" alignItems="left">
              <div className="flex justify-between">
                <FormLabel htmlFor="assignedTasks" mb="0">
                  <p className="font-normal">Receive emails when I’m assigned</p>
                </FormLabel>
                <Switch
                  id="assignedTasks"
                  isChecked={user.notifications?.receiveAssignmentEmails || false}
                  onChange={() => handleSwitchChange('receiveAssignmentEmails')}
                />
              </div>
              <div className="flex justify-between">
                <FormLabel htmlFor="taskComments" mb="0">
                  <p className="font-normal">Email me about comments on tasks where I am assigned</p>
                </FormLabel>
                <Switch
                  id="taskComments"
                  isChecked={user.notifications?.receiveCommentEmails || false}
                  onChange={() => handleSwitchChange('receiveCommentEmails')}
                />
              </div>
              {/* <div className="flex justify-between">
                <FormLabel htmlFor="mentions" mb="0">
                  <p className="font-normal">Email me when I am @mentioned on a task</p>
                </FormLabel>
                <Switch
                  id="mentions"
                  isChecked={user.notifications?.receiveMentionEmails || false}
                  onChange={() => handleSwitchChange('receiveMentionEmails')}
                />
              </div> */}
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailNotifications;
