import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Textarea,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'
import { PlayIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  openCreateTemplateModal,
  closeCreateTemplateModal,
} from '../redux/CreateTemplateModal'
import { Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { addTemplate, fetchTemplates } from '../redux/TemplateSlice'
import { useToast } from '@chakra-ui/react'

function CreateTemplate() {
  const dispatch = useDispatch()
  const isModalOpen = useSelector((state) => state.createTemplate.isModalOpen)

  const [step, setStep] = useState(1)
  const [value, setValue] = useState('1') // Initial value is '1'

  const handleChange = (newValue) => {
    setValue(newValue)
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOpenModal = () => {
    dispatch(openCreateTemplateModal())
    // Additional logic, if needed
    onOpen()
  }

  const handleCloseModal = () => {
    dispatch(closeCreateTemplateModal())
    // Additional logic, if needed
    onClose()
  }

  const [disabled, setDisabled] = useState(false)
  const toast = useToast()
  const [data, setData] = useState({
    name: '',
  })
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (data.name.length > 1) {
      setDisabled(true)
      dispatch(addTemplate(data)) // Dispatch the addTask action with the new task data
        .then(() => {
          toast({
            title: 'Template Created!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
          dispatch(fetchTemplates())
        })
        .catch((error) => {
          toast({
            title: error.message || 'Something went wrong!',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
        .finally(() => {
          setDisabled(false)
        })
    } else {
      toast({
        title: 'Invalid template data!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const router = useNavigate()
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
            {step == 1 ? (
              <>
                <h3 className="underline font-bold text-left">From Scratch</h3>
                <Textarea
                  type="text"
                  placeholder="Type in a unique name for your template"
                  required
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  value={data.name}
                />
              </>
            ) : null}

            {step == 2 ? (
              <>
                <h3 className="text-left">
                  What type of template is it? Choose Procedure if not sure.
                </h3>
                <RadioGroup value={value} onChange={handleChange}>
                  <Stack className="space-y-4">
                    <Radio value="1">
                      <span className="flex flex-col">
                        <h1 className="font-bold text-lg">Procedure</h1>
                        <p className="font-bold text-sm text-gray-400">
                          A Recipe for a process with multiple tasks and
                          approvals.
                        </p>
                      </span>
                    </Radio>
                    {/* <Radio value="2">
                      <span className="flex flex-col">
                        <h1 className="font-bold text-lg">Document</h1>
                        <p className="font-bold text-sm text-gray-400">
                          A template for a document where you can 'fill in the
                          blanks'.
                        </p>
                      </span>
                    </Radio> */}
                  </Stack>
                </RadioGroup>
              </>
            ) : null}
          </ModalBody>
          <ModalFooter className="mt-8">
            <Button
              rightIcon={<PlayIcon className="w-4 h-4 ml-2" />}
              colorScheme="fray"
              mr={3}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              rightIcon={<UserGroupIcon className="w-4 h-4 ml-2" />}
              colorScheme="blue"
              onClick={(e) => {
                if (step == 2) {
                  handleCloseModal()
                  if (value == 1) {
                    handleSubmit(e)
                  } else {
                    router(`/dashboard/templates/create/document/${data.name}`)
                  }
                } else {
                  setStep(step + 1)
                }
              }} // You can dispatch further actions here
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateTemplate
