import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  Heading,
  VStack
} from '@chakra-ui/react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Cookies from 'js-cookie';
import Header from '../../../components/Header';
import ApiDocs from './ApiDocs'; // Import the ApiDocs component

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/admin`;

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState({
    fName: '',
    lName: '',
    email: '',
    role: 'member',
    companyId: '',
    isDeactivated: false,
  });
  const [currentUser, setCurrentUser] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isApiDocsOpen, setIsApiDocsOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/users`, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      });
  
      const currentUserEmail = Cookies.get('email');
      const temp = response.data.find(user => user.email === currentUserEmail);
      setCurrentUser(temp);

      const transformedUsers = response.data.map(user => ({
        ...user,
        id: user._id,
        _id: undefined,
      }));
      setUsers(transformedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const resetUserData = () => {
    setUserData({
      fName: '',
      lName: '',
      email: '',
      role: 'member',
      companyId: '',
      isDeactivated: false,
    });
  };

  const handleAddUser = async () => {
    if (!userData.fName || !userData.lName || !userData.email) {
      toast({
        title: 'All required fields must be filled',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/users`, userData, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      });
      console.log(response.data)
      setUsers([...users, {...response.data, id: response.data._id}])
      onClose();
    } catch (error) {
      toast({
        title: 'Error adding user',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    resetUserData();
  };

  const handleUpdateUser = async () => {
    try {
      const response = await axios.put(`${API_URL}/users/${currentUserId}`, userData, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      });
      setUsers([...users, {...response.data, id: response.data._id}])
      setIsEditing(false);
      setCurrentUserId(null);
      onClose();
    } catch (error) {
      toast({
        title: 'Error updating user',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    resetUserData();
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_URL}/users/${userToDelete}`, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      });
      setUsers(users.filter(user => user.id !== userToDelete)); 
      setIsDeleteConfirmOpen(false);
      setUserToDelete(null);
    } catch (error) {
      toast({
        title: 'Error deleting user',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleEditUser = (user) => {
    setIsEditing(true);
    setUserData(user);
    setCurrentUserId(user.id);
    onOpen();
  };

  const handleDeleteUser = (id) => {
    setUserToDelete(id);
    setIsDeleteConfirmOpen(true);
  };

  const handleOpenAddModal = () => {
    setIsEditing(false);
    setCurrentUserId(null);
    resetUserData();
    onOpen();
  };

  const columns = [
    { field: 'fName', headerName: 'First Name', width: 150 },
    { field: 'lName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'role', headerName: 'Role', width: 120 },
    { field: 'companyId', headerName: 'Organization', width: 150, hide: currentUser?.role !== 'superAdmin' },
    { field: 'isDeactivated', headerName: 'Deactivated', width: 180 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      renderCell: (params) => (
        <Box className="justify-between">
            <>
              <Button
                size="sm"
                onClick={() => handleEditUser(params.row)}
                disabled={
                  currentUser?.role === 'member' && currentUser?._id != params.row.id ||
                  (params.row.role === 'superAdmin' && currentUser.role !== 'superAdmin') ||
                  (params.row.role === 'owner' && currentUser.role === 'member') ||
                  (params.row.role === 'owner' && currentUser.role === 'admin') ||
                  (params.row.role === 'admin' && currentUser.role === 'member')
                }
              >
                Edit
              </Button>
              <Button
                size="sm"
                colorScheme="red"
                onClick={() => handleDeleteUser(params.row.id)}
                disabled={
                  currentUser?.role === 'member' && currentUser?._id != params.row.id ||
                  (params.row.role === 'superAdmin' && currentUser.role !== 'superAdmin') ||
                  (params.row.role === 'owner' && currentUser.role === 'member') ||
                  (params.row.role === 'owner' && currentUser.role === 'admin') ||
                  (params.row.role === 'admin' && currentUser.role === 'member')
                }
              >
                Delete
              </Button>
            </>
        </Box>
      ),
    },
  ];

  return (
    <div className='w-full'>
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 space-y-4 py-10">
        <Heading>User Management</Heading>
        <Box height="600px" className="space-x-4">
          {currentUser?.role !== 'member' && (
            <Button onClick={handleOpenAddModal} mb={4}>
              Add User
            </Button>
          )}
          {currentUser?.role === 'superAdmin' && (
            <Button onClick={() => setIsApiDocsOpen(true)} mb={4}>
              API Documentation
            </Button>
          )}
          <Box height="100%" width="100%">
            <DataGrid
              rows={users}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>

          {/* Add/Edit User Modal */}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{isEditing ? 'Edit User' : 'Add User'}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={4} align="stretch">
                  <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      placeholder="First Name"
                      name="fName"
                      value={userData.fName}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      placeholder="Last Name"
                      name="lName"
                      value={userData.lName}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      placeholder="Email"
                      name="email"
                      type="email"
                      value={userData.email}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Role</FormLabel>
                    <Select
                      name="role"
                      value={userData.role}
                      onChange={handleChange}
                      disabled={currentUser?.role === 'member'}
                    >
                      {(currentUser?.role === 'superAdmin' ? ['superAdmin', 'owner', 'admin', 'member'] :
                        currentUser?.role === 'owner' ? ['owner', 'admin', 'member'] :
                        currentUser?.role === 'admin' ? ['admin', 'member'] :
                          ['member']).map(role => (
                          <option key={role} value={role}>
                            {role.charAt(0).toUpperCase() + role.slice(1)}
                          </option>
                        ))}
                    </Select>
                  </FormControl>

                    <FormControl>
                      <FormLabel>Organization</FormLabel>
                      <Input
                        placeholder="Organization"
                        name="companyId"
                        value={currentUser?.role == 'superAdmin' ? userData.companyId : currentUser?.companyId}
                        onChange={handleChange}
                        disabled={currentUser?.role !== 'superAdmin'}
                      />
                    </FormControl>
                  {isEditing && <FormControl>
                    <FormLabel>Deactivated</FormLabel>
                    <Select
                      name="isDeactivated"
                      value={userData.isDeactivated ? true : false}
                      onChange={(e) => setUserData({...userData, isDeactivated: e.target.value})}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Select>
                  </FormControl> }
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={isEditing ? handleUpdateUser : handleAddUser}>
                  {isEditing ? 'Update' : 'Add'}
                </Button>
                <Button variant="ghost" onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Confirm Delete Modal */}
          <Modal isOpen={isDeleteConfirmOpen} onClose={() => setIsDeleteConfirmOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirm Delete</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Are you sure you want to delete this user?
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={confirmDelete}>Delete</Button>
                <Button variant="ghost" onClick={() => setIsDeleteConfirmOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* API Docs Modal */}
          <Modal isOpen={isApiDocsOpen} onClose={() => setIsApiDocsOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>API Documentation</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ApiDocs />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </div>
    </div>
  );
};

export default UserManagement;
