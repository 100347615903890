// modalSlice.js
import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'templateLaunch',
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    openTemplateLaunchModal: (state) => {
      state.isModalOpen = true
    },
    closeTemplateLaunchModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const {
  openTemplateLaunchModal,
  closeTemplateLaunchModal,
} = modalSlice.actions
export default modalSlice.reducer
